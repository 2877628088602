import React from 'react';
import { Route } from 'react-router-dom';
import EbikeLegalAspects from './EbikeLegalAspectsPage';
import EbikeRight from './EbikeRightPage';
import EbikeSafeTravel from './EbikeSafeTravelPage';
import EbikeTrailer from './EbikeTrailerPage';
import { IEbikePageProps } from './EbikePage.types';

const EbikeRoutes: React.FC<IEbikePageProps> = ({ match }: IEbikePageProps) => (
  <>
    <Route path={`${match.url}`} component={EbikeRight} exact />
    <Route path={`${match.url}/right`} component={EbikeRight} />
    <Route path={`${match.url}/legal-aspects`} component={EbikeLegalAspects} />
    <Route path={`${match.url}/safe-travel`} component={EbikeSafeTravel} />
    <Route path={`${match.url}/trailer`} component={EbikeTrailer} />
  </>
);
export default EbikeRoutes;
