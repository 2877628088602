import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_SCHOOL_LIST_REQUEST,
  GET_SCHOOL_LIST_FAILURE,
  GET_SCHOOL_LIST_SUCCESS,
  GET_SCHOOL_BY_ID_REQUEST,
  GET_SCHOOL_BY_ID_FAILURE,
  GET_SCHOOL_BY_ID_SUCCESS,
  GET_SCHOOL_COORDINATES_LIST_FAILURE,
  GET_SCHOOL_COORDINATES_LIST_REQUEST,
  GET_SCHOOL_COORDINATES_LIST_SUCCESS,
  SET_SCHOOL_REQUEST
} from '../actions/school.action';
import { GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_FAILURE, GET_USER_PROFILE_SUCCESS } from '../actions/user.action';
import { getSchools, getSchool, getSchoolCoordinates, setUserSchoolApi } from './school.api';
import { SchoolCoordinatesSuccessPayloadSchema } from './school.schema';

function* getSchoolList({ payload }) {
  try {
    const response = yield getSchools(payload);
    yield put(GET_SCHOOL_LIST_SUCCESS({ response: response.payload, searchName: payload.search }));
  } catch (e) {
    yield put(GET_SCHOOL_LIST_FAILURE(e.message));
  }
}

function* getSchoolCoordinatesList() {
  try {
    const response = yield getSchoolCoordinates();
    if (yield SchoolCoordinatesSuccessPayloadSchema.isValid(response.payload)) {
      yield put(GET_SCHOOL_COORDINATES_LIST_SUCCESS(response.payload.schools));
    }
  } catch (e) {
    yield put(GET_SCHOOL_COORDINATES_LIST_FAILURE(e.message));
  }
}

function* getSchoolById({ payload }) {
  try {
    const response = yield getSchool(payload);
    yield put(GET_SCHOOL_BY_ID_SUCCESS(response.payload.school));
  } catch (e) {
    yield put(GET_SCHOOL_BY_ID_FAILURE(e.message));
  }
}

function* setSchoolById({ payload }) {
  yield put(GET_USER_PROFILE_REQUEST());
  try {
    const response = yield setUserSchoolApi(payload);
    if (response.status !== 200) {
      yield put(GET_USER_PROFILE_FAILURE(response.payload.message));
    } else {
      yield put(GET_USER_PROFILE_SUCCESS(response.payload.customer));
    }
  } catch (e) {
    yield put(GET_USER_PROFILE_FAILURE(e.message));
  }
}

function* schoolSaga() {
  yield takeLatest(GET_SCHOOL_LIST_REQUEST, getSchoolList);
  yield takeLatest(GET_SCHOOL_BY_ID_REQUEST, getSchoolById);
  yield takeLatest(GET_SCHOOL_COORDINATES_LIST_REQUEST, getSchoolCoordinatesList);
  yield takeLatest(SET_SCHOOL_REQUEST, setSchoolById);
}

export default schoolSaga;
