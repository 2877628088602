import { handleActions } from 'redux-actions';
import { ISoundState, IPlayMusicAction, PLAYING, PAUSED, IUpdateMusicAction } from './sound.types';
import {
  PLAY_MUSIC,
  SOUND_NOT_AVAILABLE_FOR_THIS_DEVICE,
  STOP_MUSIC,
  UPDATE_MUSIC_DATA
} from '../actions/sound.action';

const defaultState: ISoundState = {
  url: '',
  playStatus: '',
  volume: 25,
  uniqId: null,
  isSoundAvailableForThisDevice: true
};

const reduces = handleActions(
  {
    [PLAY_MUSIC]: (state, action: IPlayMusicAction) => ({
      ...state,
      playStatus: PLAYING,
      url: action.payload.url || state.url,
      uniqId: Date.now().toString()
    }),
    [STOP_MUSIC]: state => ({
      ...state,
      playStatus: PAUSED
    }),
    [UPDATE_MUSIC_DATA]: (state, action: IUpdateMusicAction) => ({
      ...state,
      ...action.payload
    }),
    [SOUND_NOT_AVAILABLE_FOR_THIS_DEVICE]: state => ({
      ...state,
      isSoundAvailableForThisDevice: false
    })
  },
  defaultState
);

export default reduces;
