import React, { useContext, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { RouteConfigContext, LanguageContext } from '../../context';
import { selectLanguage } from '../../reducer/user.selectors';
import { IAdsProps } from './Ads.types';
import styles from './Ads.module.css';

const Ads: React.FC<IAdsProps> = (props: IAdsProps) => {
  const { placement = 'all', idx = 0 } = props;
  const userLanguage = useSelector(selectLanguage);
  const route = useContext(RouteConfigContext);
  const language = useContext(LanguageContext) || userLanguage;
  const userPlacement = isMobileOnly ? 'mobile' : 'desktop';
  const { ads } = route;
  const adsIds = ads && ads[userPlacement];
  const adsId: string | undefined = adsIds ? adsIds[language] && adsIds[language][idx] : undefined;
  const abkw = '';
  const isAcceptPlacement = placement === 'all' || userPlacement === placement;

  useEffect(() => {
    const { AdButler } = window as any;

    if (ads && adsId && isAcceptPlacement && AdButler) {
      AdButler.ads.push({
        handler(opt) {
          AdButler.register(
            ads.account,
            adsId,
            (ads.size && ads.size[userPlacement]) || [1440, 180],
            `placement_${adsId}`,
            opt
          );
        },
        opt: {
          language,
          keywords: abkw,
          domain: 'servedbyadbutler.com',
          click: 'CLICK_MACRO_PLACEHOLDER'
        }
      });
    }
    // eslint-disable-next-line
  }, [adsId]);

  if (!isAcceptPlacement) {
    return null;
  }

  return (
    <div className={styles.ads}>
      <div id={`placement_${adsId}`} />
    </div>
  );
};

export default Ads;
