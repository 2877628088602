import { handleActions } from 'redux-actions';
import { IPaymentMethodState } from './paymentMethod.types';
import {
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS
} from '../actions/paymentMethod.action';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IPaymentMethodState = {
  paymentMethodsList: [],
  paymentMethodsListRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_PAYMENT_METHODS_REQUEST]: () => ({
      ...defaultState,
      paymentMethodsListRequest: { status: 'pending', error: null }
    }),
    [GET_PAYMENT_METHODS_FAILURE]: (state, action) => ({
      ...defaultState,
      paymentMethodsListRequest: { status: 'failure', error: action.payload }
    }),
    [GET_PAYMENT_METHODS_SUCCESS]: (state, action) => ({
      ...state,
      paymentMethodsListRequest: { status: 'success', error: null },
      paymentMethodsList: action.payload
    })
  },
  defaultState
);

export default reduces;
