import React from 'react';
import classNames from 'classnames';
import { IRulesGroupContentProps } from './RulesGroupContent.types';
import styles from './RulesGroupContent.module.css';

export const RulesGroupContent: React.FC<IRulesGroupContentProps> = ({
  children,
  className
}: IRulesGroupContentProps) => {
  return <div className={classNames(styles.rulesGroupContent, className)}>{children}</div>;
};
