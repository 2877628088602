import { db } from '../config/firebase.config';

export const getUserData = async userId => {
  const userDataSnapshot = await db
    .collection('user')
    .doc(userId)
    .get();
  return userDataSnapshot.data();
};

export const setDeviceId = async (userId, deviceUid) => {
  const oldUser = await getUserData(userId);

  const devices = oldUser && oldUser.devices ? oldUser.devices : {};

  devices[deviceUid] = new Date();

  await db
    .collection('user')
    .doc(userId)
    .update({
      deviceUid,
      devices
    });
};
