import React from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './ErrorBoundary.types';

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    fallback: <div />
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '', errorInfo: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({ errorMessage: error.message as string, errorInfo: errorInfo?.componentStack });
    // You can also log the error to an error reporting service
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return (
        <>
          {React.cloneElement(props.fallback as any, { errorMessage: state.errorMessage, errorInfo: state.errorInfo })}
        </>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
