export enum ViewTypeButtonType {
  List = 'list',
  Grid = 'grid'
}

export interface IViewTypeButtonProps {
  type?: ViewTypeButtonType;
  setNewTypeCb: (ViewTypeButtonType) => void;
  containerClass?: string;
}
