import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../utils/utils';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import CategoryIcon from '../../components/CategoryIcon/CategoryIcon';
import ImageIcon from '../../ui-components/ImageIcon/ImageIcon';
import styles from './AnleitungPage.module.css';

const uk = imageAbsolutePath('lang/UK.svg');
const spain = imageAbsolutePath('lang/Spain.svg');
const turkey = imageAbsolutePath('lang/Turkey.svg');
const portugal = imageAbsolutePath('lang/Portugal.svg');
const croatia = imageAbsolutePath('lang/Croatia.svg');
const albania = imageAbsolutePath('lang/Albania.svg');

const AnleitungPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.manualPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle className={styles.manualPageTitle}>{t('anleitung.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <p className={styles.pageText}>
              {t('anleitung.content1.text1.subtext1')}
              <span className={styles.pageTextBold}>{t('anleitung.content1.text1.bold1')}</span>
              {t('anleitung.content1.text1.subtext2')}
              <span className={styles.pageTextBold}>{t('anleitung.content1.text1.bold2')}</span>
              <span className={styles.pageTextBold}>{t('anleitung.content1.text1.bold3')}</span>
              {t('anleitung.content1.text1.subtext3')}
            </p>
            <p className={styles.pageText}>
              {t('anleitung.content1.text2.text1')}
              {t('anleitung.content1.text2.text2')}
              {t('anleitung.content1.text2.text3')}
              {t('anleitung.content1.text2.text4')}
            </p>
            <p className={styles.pageText}>{t('anleitung.content1.text3.text1')}</p>
            <p className={styles.pageText}>
              <span className={styles.pageTextBold}>{t('anleitung.content1.text4.bold')}</span>
              {t('anleitung.content1.text4.text')}
            </p>
            <p className={styles.pageText}>
              <span className={styles.pageSubtitle}>{t('anleitung.content1.text5.subtitle')}</span>
              {t('anleitung.content1.text5.text1')}
              {t('anleitung.content1.text5.text2')}
            </p>
            <p className={styles.pageText}>
              <span className={styles.pageSubtitle}>{t('anleitung.content1.text6.subtitle')}</span>
              {t('anleitung.content1.text6.text')}
            </p>
          </Col>
          <Col lg={4}>
            <div className={styles.pageText}>
              <span className={styles.pageSubtitle}>{t('anleitung.content2.text1.subtitle')}</span>
              <div>{t('anleitung.content2.text1.text1')}</div>
              <div>{t('anleitung.content2.text1.text2')}</div>
              <div>{t('anleitung.content2.text1.text3')}</div>
              <div>{t('anleitung.content2.text1.text4')}</div>
            </div>
            <p className={styles.pageText}>
              {t('anleitung.content2.text2.text1')}
              {t('anleitung.content2.text2.text2')}
              {t('anleitung.content2.text2.text3')}
            </p>
            <p className={styles.pageText}>{t('anleitung.content2.text3')}</p>
            <p className={styles.pageText}>
              <span className={styles.pageTextBold}>{t('anleitung.content2.text4.bold')}</span>
              {t('anleitung.content2.text4.text')}
            </p>
            <p className={styles.pageText}>{t('anleitung.content2.text5')}</p>
            <p className={styles.pageText}>
              {t('anleitung.content3.text1.text1')}
              {t('anleitung.content3.text1.text2')}
              {t('anleitung.content3.text1.text3')}
            </p>
          </Col>
          <Col lg={4}>
            <p className={styles.pageText}>
              <span className={styles.pageSubtitle}>{t('anleitung.content3.text1.subtitle')}</span>
            </p>
            <div className={styles.manualPageContent}>
              <div className={styles.rulesList}>
                <CategoryIcon icon="icon-car" level="B" badge={false} />
                <CategoryIcon icon="icon-motorcycle" level="A, A1" badge={false} />
              </div>
              <p className={styles.pageText}>{t('anleitung.content3.text2')}</p>
            </div>
            <div className={styles.manualPageContent}>
              <CategoryIcon icon="icon-e-bike" level="M" badge={false} />
              <p className={styles.pageText}>{t('anleitung.content3.text5')}</p>
            </div>
            <div className={styles.manualPageContent}>
              <CategoryIcon icon="icon-tractor" level="F, G" badge={false} />
              <p className={styles.pageText}>{t('anleitung.content3.text6')}</p>
            </div>
            <div className={styles.manualPageContentIcon}>
              <ImageIcon src={spain} width="40" height="40" alt="lang" />
              <ImageIcon src={uk} width="40" height="40" alt="lang" />
              <ImageIcon src={turkey} width="40" height="40" alt="lang" />
              <ImageIcon src={portugal} width="40" height="40" alt="lang" />
              <ImageIcon src={croatia} width="40" height="40" alt="lang" />
              <ImageIcon src={albania} width="40" height="40" alt="lang" />
            </div>
            <p className={styles.pageText}>
              {t('anleitung.content3.text3.text1')}
              {t('anleitung.content3.text3.text2')}
            </p>
            <p className={styles.pageText}>{t('anleitung.content3.text4.text1')}</p>
            <p className={styles.pageText}>{t('anleitung.content3.text4.text2')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AnleitungPage;
