import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IPaymentConfirmationProps } from './PaymentConfirmation.types';
import styles from './PaymentConfirmation.module.css';

const PaymentConfirmation: React.FC<IPaymentConfirmationProps> = ({
  show,
  onHide,
  onConfirm,
  countdownInitSecond = 10
}: IPaymentConfirmationProps) => {
  const [currentSecond, setCurrentSecond]: [number, (value: number) => void] = useState(countdownInitSecond);
  const timerId = useRef<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (!show && timerId.current) {
      window.clearTimeout(timerId.current);
      setCurrentSecond(countdownInitSecond);
    }
  }, [show, countdownInitSecond]);

  useEffect(() => {
    if (!currentSecond) {
      onConfirm();
    }
  }, [currentSecond, onConfirm]);

  useEffect(() => {
    if (!show || !currentSecond) {
      return;
    }
    timerId.current = window.setTimeout(() => {
      setCurrentSecond(currentSecond - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => window.clearTimeout(timerId.current);
  }, [currentSecond, show]);

  return (
    <Modal className={styles.paymentModal} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('paymentConfirmation.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.modalText}>{t('paymentConfirmation.body.redirect', { seconds: currentSecond })}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onConfirm}>
          {t('paymentConfirmation.button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentConfirmation;
