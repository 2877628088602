import React, { useCallback } from 'react';
import { Jumbotron, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ITheoryCardProps } from './TheoryCard.types';
import styles from './TheoryCard.module.css';

export const TheoryCard: React.FC<ITheoryCardProps> = ({ handleChangePathname }: ITheoryCardProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(() => {
    handleChangePathname('/theory/glossary/55');
  }, [handleChangePathname]);

  return (
    <Jumbotron className={styles.theoryCard}>
      <div className={styles.theoryCardTitle}>{t('theoryPage.card.title')}</div>
      <div className={styles.theoryCardText}>{t('theoryPage.card.text')}</div>
      <Button className={styles.linkBtn} variant="link" onClick={handleChange}>
        {t('theoryPage.card.link')}
        <i className="icon-arrow" />
      </Button>
    </Jumbotron>
  );
};
