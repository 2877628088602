import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IQuestionTypeSelectorProps } from './QuestionTypeSelector.types';
import { SET_USER_SELECTED_QUESTION_GROUP } from '../../actions/user.action';
import { QuestionGroupOptions, QuestionGroupType } from '../../reducer/question.types';
import { IRootState } from '../../reducer';
import SelectedQuestionGroupIcon from '../SelectedQuestionGroupIcon/SelectedQuestionGroupIcon';
import styles from './QuestionTypeSelector.module.css';

const categoryConfig: any[] = [
  {
    name: 'ASA'
  },
  {
    name: 'ALL'
  }
];

export const QuestionTypeSelector: React.FC<IQuestionTypeSelectorProps> = ({
  className,
  isDemo
}: IQuestionTypeSelectorProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const selectedQuestionGroup: QuestionGroupType = useSelector(
    ({ user }: IRootState) => user.profile.selectedQuestionGroup
  );

  const handleSelectQuestionGroup = useCallback(
    (selectedGroup: string) => () => {
      dispatch(SET_USER_SELECTED_QUESTION_GROUP({ selectedQuestionGroup: selectedGroup }));
    },
    [dispatch]
  );

  if (isDemo) {
    return null;
  }

  const selectedQuestionGroupText =
    selectedQuestionGroup === QuestionGroupOptions.ASA
      ? t('myCockpitCategories.subtitle.asaQuestion')
      : t('myCockpitCategories.subtitle.allQuestion');

  return (
    <div className={styles.questionTypeSelectorContainer}>
      <div className={classNames(styles.rulesList, className)}>
        <span className={styles.buttonTitle}>{t('myCockpitCategories.subtitle.chooseQuestions')}</span>
        <div className={styles.iconsContainer}>
          {categoryConfig.map(category => {
            const selectionClassName = category.name !== selectedQuestionGroup ? styles.notActive : '';
            return (
              <SelectedQuestionGroupIcon
                classes={selectionClassName}
                onClick={handleSelectQuestionGroup(category.name)}
                selectedQuestionGroupDefault={category.name}
              />
            );
          })}
        </div>
        <p className={styles.questionTypeText}>{selectedQuestionGroupText}</p>
      </div>
    </div>
  );
};
