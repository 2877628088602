import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import locale from './locales/locale';

i18n.use(LanguageDetector).init({
  debug: process.env.NODE_ENV === 'development',
  lng: 'de',
  fallbackLng: 'de',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  resources: locale,
  ns: ['translation'],
  defaultNS: 'translation'
});

export default i18n;
