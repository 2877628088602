import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IGameCongratulationProps } from './GameCongratulation.types';
import styles from './GameCongratulation.module.css';

const GameCongratulations: React.FC<IGameCongratulationProps> = ({ isSuccess }: IGameCongratulationProps) => {
  const { t } = useTranslation();

  const gameText = useMemo(() => t(`gameResultPage.${isSuccess ? 'congratulation' : 'gameOver'}`), [isSuccess, t]);

  const textClassName = useMemo(() => classNames({ retroTextChangeClass: isSuccess }), [isSuccess]);

  return (
    <div className={styles.resultPageItem}>
      <h1 className={textClassName}>{gameText}</h1>
    </div>
  );
};

export default GameCongratulations;
