import React, { useState, useEffect, useCallback, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GET_SCHOOL_LIST_REQUEST } from '../actions/school.action';
import { SchoolState } from '../reducer/school.types';
import { selectSchool } from '../reducer/scool.selectors';
import { UNCALLED, PENDING } from '../constants/store.constants';
import { Loader } from '../ui-components/Loader/Loader';
import styles from './SchoolSearchContainer.module.css';

const SchoolSearchContainer = () => {
  const [inputValue, setValue] = useState('');
  const { schoolListRequest }: SchoolState = useSelector(selectSchool);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (schoolListRequest.status === UNCALLED) {
      dispatch(GET_SCHOOL_LIST_REQUEST({ limit: 10, page: 1, search: null }));
    }
  }, [dispatch, schoolListRequest]);
  const handleChange = useCallback(
    e => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      dispatch(GET_SCHOOL_LIST_REQUEST({ limit: 10, page: 1, search: inputValue }));
    },
    [dispatch, inputValue]
  );

  return (
    <>
      <Loader show={schoolListRequest.status === PENDING} />
      <div className={styles.searchContainer}>
        <Form onSubmit={handleSubmit}>
          <Form.Control placeholder={t('drivingSchoolPage.inputPlaceholder')} onChange={handleChange} />
          <Button variant="secondary" type="submit" disabled={schoolListRequest.status === PENDING}>
            {t('drivingSchoolPage.submitButton')}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default SchoolSearchContainer;
