import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RainContainerProps } from './RainContainer.types';
import { UPDATE_MUSIC_DATA } from '../../actions/sound.action';
import { STOPPED } from '../../reducer/sound.types';
import './RainContainer.module.css';

const RainContainer: React.FC<RainContainerProps> = ({ showRainByDefault = false }) => {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(showRainByDefault);
  const [dropsState, setDropsState] = useState([]);
  const [backDropsState, setBackDropState] = useState([]);

  const makeItRain = useCallback(() => {
    let increment = 0;
    let drops = '';
    let backDrops = '';

    while (increment < 100) {
      const randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
      const randoFiver = Math.floor(Math.random() * (5 - 2 + 1) + 2);
      increment += randoFiver;
      drops += `<div class="drop" style="left: ${increment}%; bottom: ${randoFiver +
        randoFiver -
        1 +
        100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"><div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div><div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div></div>`;
      backDrops += `<div class="drop" style="right: ${increment}%; bottom: ${randoFiver +
        randoFiver -
        1 +
        100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"><div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div><div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div></div>`;
    }

    // @ts-ignore
    setDropsState(drops);
    // @ts-ignore
    setBackDropState(backDrops);
  }, []);

  useEffect(() => {
    if (visibility) {
      const timer = setTimeout(() => {
        setVisibility(false);
      }, 18000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [setVisibility, visibility]);

  useEffect(() => {
    return () => {
      dispatch(UPDATE_MUSIC_DATA({ playStatus: STOPPED }));
    };
  }, [dispatch]);

  useEffect(() => {
    makeItRain();
  }, [makeItRain]);

  if (visibility) {
    return (
      <>
        <div className="rain front-row" dangerouslySetInnerHTML={{ __html: dropsState as any }} />
        <div className="rain back-row" dangerouslySetInnerHTML={{ __html: backDropsState as any }} />
      </>
    );
  }

  return null;
};

export default React.memo(RainContainer);
