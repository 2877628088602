import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { examHistoryObj, IExamHistoryTable } from './ExamHistoryTable.types';
import { CommonTable } from '../../ui-components/CommonTable/CommonTable';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { LoaderWrapper } from '../../ui-components/LoaderWrapper/LoaderWrapper';
import ExamProgressBar from '../../ui-components/ExamProgressBar/ExamProgressBar';
import styles from './ExamHistoryTable.module.css';

const ExamHistoryTable: React.FC<IExamHistoryTable> = ({ isDataLoading, examHistory }: IExamHistoryTable) => {
  const { t } = useTranslation();
  const getHeaderList = useMemo(
    () => [
      t('examHistoryPage.tableHeader.date'),
      t('examHistoryPage.tableHeader.time'),
      t('examHistoryPage.tableHeader.score'),
      '',
      t('examHistoryPage.tableHeader.open')
    ],
    [t]
  );
  const tableRow = ({ data }: examHistoryObj) => {
    const duration = moment(data.endTime).diff(moment(data.startTime), 'seconds');
    const formattedDuration = moment.utc(duration * 1000).format('mm:ss');
    return (
      <tr key={data.examId}>
        <td>{moment(data.endTime).format('DD.MM.YY HH:mm')}</td>
        <td>{formattedDuration}</td>
        <td>{`${data.truePointCount} / ${data.pointCount}`}</td>
        <td className={styles.progressBarContainer}>
          <ExamProgressBar examResult={data} withContainer />
        </td>
        <td>
          <LinkWrapper to={`/exam/${data.examId}`}>
            <div>{t('examHistoryPage.show')}</div>
          </LinkWrapper>
        </td>
      </tr>
    );
  };

  return (
    <LoaderWrapper show={isDataLoading}>
      <CommonTable
        renderEmptyText={!examHistory!.length}
        emptyText={t('examHistoryPage.tableHeader.noData')}
        tableProps={{ responsive: true, striped: true, borderless: true, hover: true }}
      >
        <CommonTable.Header tableHeader={getHeaderList} />
        <CommonTable.Body tableRow={tableRow} tableData={examHistory} />
      </CommonTable>
    </LoaderWrapper>
  );
};

export default React.memo(ExamHistoryTable);
