import React from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ITheoryModalNotLoggedProps } from './TheoryModalNotLogged.types';
import image from '../../img/theoryNotLogged.jpg';
import styles from './TheoryModalNotLogged.module.css';

export const TheoryModalNotLogged: React.FC<ITheoryModalNotLoggedProps> = ({
  show,
  onHideModal,
  currentLanguage
}: ITheoryModalNotLoggedProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      dialogClassName={styles.modalLg}
      aria-labelledby="contained-modal-title-hcenter"
      centered
      animation
      show={show}
      onHide={onHideModal}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className={styles.modalContent}>
          <div className={styles.modalContentText}>
            <Modal.Title>{t('theoryPage.modal.title')}</Modal.Title>
            <div className={styles.modalText}>{t('theoryPage.modal.text')}</div>
            <Button className={styles.modalBtn} as={Link} to={`/${currentLanguage}/offers`} variant="primary">
              {t('theoryPage.modal.button')}
            </Button>
          </div>
          <Image className={styles.modalImg} src={image} alt="image not found" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
