import { handleActions } from 'redux-actions';
import { SchoolState } from './school.types';
import { PENDING, FAILURE, SUCCESS } from '../constants/store.constants';
import {
  GET_SCHOOL_LIST_REQUEST,
  GET_SCHOOL_LIST_FAILURE,
  GET_SCHOOL_LIST_SUCCESS,
  GET_SCHOOL_BY_ID_REQUEST,
  GET_SCHOOL_BY_ID_FAILURE,
  GET_SCHOOL_BY_ID_SUCCESS,
  GET_SCHOOL_COORDINATES_LIST_REQUEST,
  GET_SCHOOL_COORDINATES_LIST_SUCCESS,
  GET_SCHOOL_COORDINATES_LIST_FAILURE
} from '../actions/school.action';
import { defaultRequestStatus } from './fetch.types';

const defaultState: SchoolState = {
  itemsCount: 0,
  currentPage: 1,
  searchField: null,
  schoolList: [],
  schoolCoordinatesList: [],
  schoolListRequest: defaultRequestStatus,
  currentSchoolRequest: defaultRequestStatus,
  schoolCoordinatesRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_SCHOOL_LIST_REQUEST]: state => ({
      ...state,
      schoolListRequest: { status: PENDING, error: null }
    }),
    [GET_SCHOOL_LIST_FAILURE]: (state, action) => ({
      ...state,
      schoolListRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_SCHOOL_LIST_SUCCESS]: (state, action) => ({
      ...state,
      schoolListRequest: { status: SUCCESS, error: null },
      schoolList: action.payload.response.schools,
      itemsCount: action.payload.response.meta.total,
      currentPage: action.payload.response.meta.page,
      searchField: action.payload.searchName
    }),
    [GET_SCHOOL_BY_ID_REQUEST]: state => ({
      ...state,
      currentSchoolRequest: { status: PENDING, error: null }
    }),
    [GET_SCHOOL_BY_ID_FAILURE]: (state, action) => ({
      ...state,
      currentSchoolRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_SCHOOL_BY_ID_SUCCESS]: (state, action) => ({
      ...state,
      currentSchoolRequest: { status: SUCCESS, error: null },
      currentSchool: action.payload
    }),
    [GET_SCHOOL_COORDINATES_LIST_REQUEST]: state => ({
      ...state,
      schoolCoordinatesRequest: { status: PENDING, error: null }
    }),
    [GET_SCHOOL_COORDINATES_LIST_SUCCESS]: (state, action) => ({
      ...state,
      schoolCoordinatesRequest: { status: SUCCESS, error: null },
      schoolCoordinatesList: action.payload
    }),
    [GET_SCHOOL_COORDINATES_LIST_FAILURE]: (state, action) => ({
      ...state,
      schoolCoordinatesRequest: { status: FAILURE, error: action.payload }
    })
  },
  defaultState
);

export default reduces;
