import React, { memo } from 'react';
import { Pagination } from 'react-bootstrap';
import { IPagination } from './Pagination.types';
import styles from './Pagination.module.css';

function getFirstPaginationIndex(pageCount: number, active: number): number {
  let startPage = 1;

  if (active >= 4 && active + 2 < pageCount) {
    startPage = active - 2;
  } else if (active + 2 >= pageCount) {
    startPage = pageCount - 4;
  }

  if (startPage < 1) {
    startPage = 1;
  }
  return startPage;
}

const CustomPagination: React.FC<IPagination> = ({ total, active, onChange, perPage, range = 5 }) => {
  const pageCount = Math.ceil(total / perPage);
  const paginationItemCount = pageCount <= range ? pageCount : range;
  const firstItem = getFirstPaginationIndex(pageCount, active);
  const handleOnClick = page => () => onChange(page);

  return (
    <>
      <Pagination>
        <Pagination.First className={styles.firstBtn} onClick={handleOnClick(1)} />
        <Pagination.Prev className={styles.prevBtn} onClick={handleOnClick(active - 1 <= 1 ? 1 : active - 1)} />
        {Array.from(Array(paginationItemCount).keys())
          .map(index => firstItem + index)
          .filter(pageNumber => pageNumber <= pageCount)
          .map(pageNumber => (
            <Pagination.Item
              className={styles.paginationItem}
              key={pageNumber}
              active={pageNumber === active}
              onClick={handleOnClick(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
          ))}
        {active + 5 < pageCount && (
          <>
            <Pagination.Ellipsis className={styles.paginationItem} />
            <Pagination.Item className={styles.paginationItem} onClick={handleOnClick(pageCount)}>
              {pageCount}
            </Pagination.Item>
          </>
        )}
        <Pagination.Next
          className={styles.nextBtn}
          onClick={handleOnClick(active + 1 >= pageCount ? pageCount : active + 1)}
        />
        <Pagination.Last className={styles.lastBtn} onClick={handleOnClick(pageCount)} />
      </Pagination>
    </>
  );
};

export default memo(CustomPagination);
