import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TheoryBookContainer from '../../containers/TheoryBookContainer';
import TheoryTopicsListContainer from '../../containers/TheoryTopicsListContainer';
import Ads from '../../components/Ads/Ads';
import { ITheoryPageProps } from './TheoryBookByTopicsPage.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import styles from './TheoryBookByTopicsPage.module.css';

const TheoryBookByTopicsPage: React.FC<ITheoryPageProps> = ({ match }: ITheoryPageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.theoryTopicPage} id={THEORY_PAGE_ANCHOR}>
        <Container>
          <Row>
            <Col>
              <LinkWrapper className={styles.linkBtn} to="/theory">
                <i className="icon-arrow" />
                {t('theoryPage.linkBack')}
              </LinkWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.theoryTopicContent}>
                <TheoryTopicsListContainer />
                <TheoryBookContainer pathName="topics" page={match.params.page} />
              </div>
            </Col>
          </Row>
        </Container>
        <div className={styles.mobileView}>
          <Container>
            <Row>
              <Col>
                <InstructorContainer />
              </Col>
            </Row>
          </Container>
        </div>
        <Ads />
      </div>
    </>
  );
};

export default TheoryBookByTopicsPage;
