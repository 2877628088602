import React, { FC, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { ITitleH3Props } from './TitleH3.types';
import styles from './TitleH3.module.css';

const TitleH3: FC<ITitleH3Props> = forwardRef(
  ({ text, center = false, className = '' }: ITitleH3Props, ref?: React.Ref<HTMLHeadingElement>) => (
    <h3 ref={ref} className={`${styles.text} ${className} ${center && styles.center}`}>
      {text}
    </h3>
  )
);

export const MTitleH3 = motion(TitleH3);

export default TitleH3;
