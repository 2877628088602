import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../utils/utils';
import { IS_WEBVIEW_IOS } from '../../constants';
import styles from './VipCardMobile.module.css';

const logo = imageAbsolutePath('new-vip-card.png');

const VipCardMobile: React.FC = () => {
  const { t } = useTranslation();

  if (IS_WEBVIEW_IOS) return null;

  return (
    <LinkWrapper className={styles.vipCardMobile} to="/vipcode">
      <img className={styles.cardLogo} src={logo} alt="logo" />
      <LinkWrapper className="linkBtn" to="/vipcode">
        {t('homePage.btn.redeemCode')}
        <i className="icon-arrow" />
      </LinkWrapper>
    </LinkWrapper>
  );
};

export default VipCardMobile;
