import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TheoryByTopicContainer from '../../containers/TheoryByTopicContainer';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import TheoryContainer from '../../containers/TheoryContainer';
import image from '../../img/theoryLoggedandNotLogged.jpg';
import { ITheoryPageProps } from './TheoryPage.types';
import styles from './TheoryPage.module.css';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';

const TheoryPage: React.FC<ITheoryPageProps> = ({ match }: ITheoryPageProps) => {
  return (
    <div className={styles.theoryPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.theoryPageTopContent}>
              <RulesGroupContent>
                <div className={styles.imageBox}>
                  <InstructorContainer src={image} hideForMobile />
                </div>
                <TheoryContainer />
              </RulesGroupContent>
            </div>
          </Col>
        </Row>
      </Container>
      <TheoryByTopicContainer page={match.params.page} />
      <Container className={styles.mobileView}>
        <Row>
          <Col>
            <InstructorContainer />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TheoryPage;
