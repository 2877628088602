import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { imageAbsolutePath } from '../../../../utils/utils';
import { selectLanguage } from '../../../../reducer/user.selectors';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import styles from './ShopSection.module.css';

const shopDe = imageAbsolutePath('shop/shop_de_en.png');
const shopFr = imageAbsolutePath('shop/shop_fr_it.png');
const shopIt = imageAbsolutePath('shop/shop_fr_it.png');
const shopEn = imageAbsolutePath('shop/shop_de_en.png');

const placeHolderImage = imageAbsolutePath('vip-code-man.png');

const IMAGES = {
  de: { img: shopDe },
  fr: { img: shopFr },
  it: { img: shopIt },
  en: { img: shopEn }
};

const ShopSection: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);

  const imgSrc = IMAGES[currentLanguage]?.img;

  return (
    <section className={styles.container}>
      <div>
        <TitleH3 center text={t('homePageNew.shopSection.title')} />
        <img className={styles.shopImage} src={imgSrc} alt={t('homePage.shopSection.imageAlt')} />

        <div className={styles.buttonWrapper}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary"
            href={t('homePageNew.shopSection.link1')}
          >
            {t('homePageNew.btn.zumShop')}
          </a>
        </div>
      </div>
      <div>
        <TitleH3 center text={t('homePageNew.shopSection.title2')} />
        <img className={styles.vipImage} src={placeHolderImage} alt={t('homePageNew.shopSection.imageAlt2')} />

        <div className={styles.buttonWrapper}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
            href={t('homePageNew.shopSection.link2')}
          >
            {t('homePageNew.btn.more')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default ShopSection;
