import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { IRootState } from '../reducer';
import { IRegisters } from '../reducer/theory.types';
import { TheoryGlossary } from '../components/TheoryGlossary/TheoryGlossary';
import { selectLanguage } from '../reducer/user.selectors';
import { SS_THEORY_GLOSSARY_SCROLL_POSITION_KEY } from '../constants/storageKeys';
import styles from './TheoryGlossaryContainer.module.css';

const checkLetterForGlossary = (() => {
  let currentLetter = '';

  return (newLetter: string) => {
    if (newLetter && newLetter !== currentLetter) {
      currentLetter = newLetter;

      return true;
    }

    return false;
  };
})();

const TheoryGlossaryContainer: React.FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { history } = useReactRouter();
  const registers = useSelector(({ theory }: IRootState) => theory.theory.registers);
  const currentLanguage = useSelector(selectLanguage);

  useEffect(() => {
    const { current: scrollableElement } = rootRef;
    if (scrollableElement) {
      scrollableElement.scrollTop = Number(window.sessionStorage.getItem(SS_THEORY_GLOSSARY_SCROLL_POSITION_KEY)) || 0;
    }
    return () => {
      if (scrollableElement) {
        window.sessionStorage.setItem(SS_THEORY_GLOSSARY_SCROLL_POSITION_KEY, String(scrollableElement.scrollTop));
      }
    };
  }, [rootRef]);

  const handleChangePathname = useCallback(
    (pathname: string) => {
      history.push(`/${currentLanguage}${pathname}`);
    },
    [history, currentLanguage]
  );

  return (
    <div className={styles.glossaryList} ref={rootRef}>
      {registers.map((register: IRegisters) => (
        <React.Fragment key={register.name + register.page}>
          {checkLetterForGlossary(register.letter) ? <h2 className={styles.letterItem}>{register.letter}</h2> : null}
          <TheoryGlossary
            onChangePathname={handleChangePathname}
            pathname={`/theory/glossary/${register.page}`}
            content={`${register.name}`}
            key={register.name}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default TheoryGlossaryContainer;
