import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../ui-components/Loader/Loader';
import { selectLogin } from '../../reducer/login.selectors';
import { LoginState } from '../../reducer/login.types';
import { PENDING } from '../../constants/store.constants';
import RecoveryPasswordModal from '../../components/RecoveryPasswordModal/RecoveryPasswordModal';
import { LOGIN_MODAL_OPEN, RECOVERY_PASSWORD_MODAL_HIDE, RECOVERY_PASSWORD_REQUEST } from '../../actions/login.action';

const RecoveryPasswordModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    passwordRecoveryRequest: { status },
    isRecoveryPasswordModalOpen
  }: LoginState = useSelector(selectLogin);

  const handleHide = useCallback(() => {
    dispatch(RECOVERY_PASSWORD_MODAL_HIDE());
  }, [dispatch]);

  const handleRequest = useCallback(
    (email: string) => {
      if (email) {
        dispatch(RECOVERY_PASSWORD_REQUEST({ email }));
      }
    },
    [dispatch]
  );

  const handleLogin = useCallback(() => {
    dispatch(RECOVERY_PASSWORD_MODAL_HIDE());
    dispatch(LOGIN_MODAL_OPEN());
  }, [dispatch]);

  return (
    <>
      <Loader show={status === PENDING} />
      <RecoveryPasswordModal
        show={isRecoveryPasswordModalOpen}
        requestStatus={status}
        onHide={handleHide}
        onLogin={handleLogin}
        onRequest={handleRequest}
      />
    </>
  );
};

export default RecoveryPasswordModalContainer;
