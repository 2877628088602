import React, { useCallback, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_REQUEST, LOGIN_MODAL_HIDE, RECOVERY_PASSWORD_MODAL_OPEN } from '../actions/login.action';
import LoginModal from '../components/LoginModal/LoginModal';
import { PENDING, SUCCESS } from '../constants/store.constants';
import { Loader } from '../ui-components/Loader/Loader';
import { selectLogin } from '../reducer/login.selectors';
import { LoginState } from '../reducer/login.types';
import { getTextMessageFromRequestError } from '../utils/utils';
import { usePrevious } from '../hooks/usePrevious';
import { selectLanguage } from '../reducer/user.selectors';

const LoginModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);
  const { isLogged }: LoginState = useSelector(selectLogin);
  const { request, isLoginModalOpen }: LoginState = useSelector(selectLogin);
  const prevLoginRequestState = usePrevious(request.status);
  const isRequesting = request.status === PENDING;

  const login = useCallback(
    (email: string, password: string, saveCookies: boolean) => {
      dispatch(LOGIN_REQUEST({ email, password, saveCookies }));
    },
    [dispatch]
  );
  const handleHideModal = useCallback(() => {
    dispatch(LOGIN_MODAL_HIDE());
  }, [dispatch]);

  const handleRecoveryPassword = useCallback(() => {
    dispatch(LOGIN_MODAL_HIDE());
    dispatch(RECOVERY_PASSWORD_MODAL_OPEN());
  }, [dispatch]);

  useEffect(() => {
    if (prevLoginRequestState === PENDING && request.status === SUCCESS) {
      history.push(`/${currentLanguage}/mycockpit`);
    }
  }, [prevLoginRequestState, request, currentLanguage, history]);

  return (
    <>
      <Loader show={isRequesting} />
      <LoginModal
        onHide={handleHideModal}
        onRecovery={handleRecoveryPassword}
        show={isLoginModalOpen}
        login={login}
        error={getTextMessageFromRequestError(request.error)}
        isLoading={isRequesting}
        isUserLogin={isLogged}
      />
    </>
  );
};

export default LoginModalContainer;
