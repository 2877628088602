import React, { FormEvent, useCallback, useMemo, useState, ChangeEvent, ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import { IRecoveryPasswordModalProps } from './RecoveryPasswordModal.types';
import { FAILURE, PENDING, SUCCESS } from '../../constants/store.constants';
import styles from './RecoveryPasswordModal.module.css';
import { P3 } from '../../ui-components/P3/P3';

const RecoveryPasswordModal: React.FC<IRecoveryPasswordModalProps> = ({
  show,
  onHide,
  onRequest,
  onLogin,
  requestStatus
}: IRecoveryPasswordModalProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const onEmailChange = useCallback<ChangeEventHandler<any>>(
    (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
    [setEmail]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onRequest(email);
    },
    [onRequest, email]
  );

  const title = useMemo(() => {
    switch (requestStatus) {
      case SUCCESS:
        return t('recoveryPasswordModal.titleSuccess');
      case FAILURE:
        return t('recoveryPasswordModal.titleFailure');
      default:
        return t('recoveryPasswordModal.title');
    }
  }, [requestStatus, t]);

  const infoText = useMemo(() => {
    switch (requestStatus) {
      case SUCCESS:
        return t('recoveryPasswordModal.infoTextSuccess');
      case FAILURE:
        return t('recoveryPasswordModal.infoTextFailure');
      default:
        return t('recoveryPasswordModal.infoText');
    }
  }, [requestStatus, t]);

  return (
    <>
      <Modal className={styles.recoveryModal} show={show} onHide={onHide} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <P3>{infoText}</P3>
            {requestStatus !== SUCCESS && (
              <Form.Group controlId="emailRecovery">
                <Form.Control
                  type="email"
                  placeholder={t('recoveryPasswordModal.emailPlaceholder')}
                  value={email}
                  onChange={onEmailChange}
                  required
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            {requestStatus !== SUCCESS && (
              <>
                <a className={styles.linkBtn} href={t('info.emailhref')} rel="noopener noreferrer" target="_blank">
                  {t('recoveryPasswordModal.supportLink')}
                </a>
                <Button variant="secondary" type="submit" disabled={requestStatus === PENDING || !email}>
                  {t('recoveryPasswordModal.recoverySubmitBtn')}
                </Button>
              </>
            )}
            {requestStatus === SUCCESS && (
              <Button variant="secondary" onClick={onLogin}>
                {t('recoveryPasswordModal.loginBtn')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RecoveryPasswordModal;
