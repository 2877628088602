import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { PageText } from '../../ui-components/PageText/PageText';
import VipCard from '../../components/VipCard/VipCard';
import { PageContentTitle } from '../../ui-components/PageContentTitle/PageContentTitle';
import { VERIFY_VOUCHER_CODE_REQUEST } from '../../actions/voucher.actions';
import { selectVoucher } from '../../reducer/voucher.selectors';
import { selectLanguage } from '../../reducer/user.selectors';
import { FAILURE, PENDING, SUCCESS } from '../../constants/store.constants';
import { IVoucherState } from '../../reducer/voucher.types';
import { Loader } from '../../ui-components/Loader/Loader';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';
import { IS_WEBVIEW_IOS } from '../../constants';
import { P3 } from '../../ui-components/P3/P3';
import styles from './VipCodeContainer.module.css';

const VipCodeContainer: React.FC<RouteComponentProps> = ({ history }) => {
  const { isLogged }: LoginState = useSelector(selectLogin);
  const prevRequestState = useRef<any>(null);
  const dispatch = useDispatch();
  const { verifyCodeRequest, voucher }: IVoucherState = useSelector(selectVoucher);
  const [isValidityChecked, setIsValidityChecked] = useState<boolean>(false);
  const [vipCodeValue, setVipCodeValue] = useState<string>('');
  const { t } = useTranslation();
  const handleChange = useCallback(({ target: { value } }) => setVipCodeValue(value.toUpperCase()), [setVipCodeValue]);
  const handleBlur = useCallback(({ target: { value } }) => setVipCodeValue(value.trim()), [setVipCodeValue]);
  const currentLanguage = useSelector(selectLanguage);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      if (event.currentTarget.checkValidity() && vipCodeValue) {
        dispatch(VERIFY_VOUCHER_CODE_REQUEST(vipCodeValue));
      }

      setIsValidityChecked(true);
    },
    [vipCodeValue, dispatch]
  );

  useEffect(() => {
    if (prevRequestState.current === PENDING && verifyCodeRequest.status === SUCCESS) {
      history.push(`/${currentLanguage}/register?offer=${voucher.offerId}`);
    }
  }, [history, verifyCodeRequest, currentLanguage, voucher]);

  useEffect(() => {
    prevRequestState.current = verifyCodeRequest.status;
  }, [verifyCodeRequest]);

  const isHasValidationMessage = () =>
    verifyCodeRequest.status === FAILURE && verifyCodeRequest.error && typeof verifyCodeRequest.error === 'string';

  return (
    <div className={styles.vipPage}>
      <Loader show={verifyCodeRequest.status === PENDING} />
      <Container>
        <Row>
          <Col>
            <div className={styles.vipPageContent}>
              <div className={styles.vipItem}>
                <div className={styles.vipPageSubtitle}>{t('voucher.vipCode.title')}</div>
                <div className={styles.vipCodeField}>
                  {isHasValidationMessage() && <Alert variant="danger">{verifyCodeRequest.error}</Alert>}
                  <Form onSubmit={handleSubmit} noValidate validated={isValidityChecked}>
                    <div className={styles.formContent}>
                      <Form.Group controlId="vipCode">
                        <Form.Control
                          className={styles.vipCodeInput}
                          type="text"
                          placeholder={`${t('voucher.vipCode.form.placeholder')}`}
                          value={vipCodeValue}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Button variant="secondary" type="submit" disabled={verifyCodeRequest.status === PENDING}>
                        {t('voucher.vipCode.form.controlLabel')}
                      </Button>
                    </div>
                    {!isLogged && (
                      <PageText className={styles.vipCodePageText}>
                        <Trans i18nKey="voucher.vipCode.hint">
                          desc
                          <LinkWrapper to="/offers" />
                          desc
                        </Trans>
                      </PageText>
                    )}
                  </Form>
                </div>
              </div>
              <VipCard withLink={false} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.vipCodeBottomText}>
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <PageContentTitle>{t('voucher.vipCode.questionBlock.title1')}</PageContentTitle>
              <P3 className={styles.vipPageText}>{t('voucher.vipCode.questionBlock.text1')}</P3>
            </Col>
            <Col xs={12} lg={4}>
              <PageContentTitle>{t('voucher.vipCode.questionBlock.title2')}</PageContentTitle>
              {!IS_WEBVIEW_IOS && <P3 className={styles.vipPageText}>{t('voucher.vipCode.questionBlock.text2')}</P3>}
              <P3 className={styles.vipPageText}>{t('voucher.vipCode.questionBlock.text3')}</P3>
            </Col>
            <Col xs={12} lg={4}>
              <PageContentTitle>{t('voucher.vipCode.questionBlock.title3')}</PageContentTitle>
              <P3 className={styles.vipPageText}>
                <Trans i18nKey="voucher.vipCode.questionBlock.text4">
                  desc
                  <b />
                  desc
                </Trans>
              </P3>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default VipCodeContainer;
