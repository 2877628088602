import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import asaLogo from '../../img/asa-logo.png';
import { QuestionGroupOptions, QuestionGroupType } from '../../reducer/question.types';
import { IRootState } from '../../reducer';
import { ISelectedQuestionGroupIconProps } from './SelectedQuestionGroupIcon.types';
import styles from './SelectedQuestionGroupIcon.module.css';

const SelectedQuestionGroupIcon: React.FC<ISelectedQuestionGroupIconProps> = ({
  classes = '',
  onClick,
  selectedQuestionGroupDefault
}: ISelectedQuestionGroupIconProps) => {
  const selectedQuestionGroup: QuestionGroupType = useSelector(
    ({ user }: IRootState) => user.profile.selectedQuestionGroup
  );
  const { t } = useTranslation();
  const questionGroup = selectedQuestionGroupDefault || selectedQuestionGroup;

  const content =
    questionGroup === QuestionGroupOptions.ASA ? (
      <img src={asaLogo} alt="ASA" />
    ) : (
      <span>{t('myCockpitCategories.subtitle.all').toLowerCase()}</span>
    );

  return (
    <Button onClick={onClick} className={classNames(styles.buttonContainer, { [classes]: classes })} variant="primary">
      {content}
    </Button>
  );
};

export default SelectedQuestionGroupIcon;
