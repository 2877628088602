import './polyfills';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/fr';
import ReactGA from 'react-ga';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { TrackJS } from 'trackjs';
import alasql from 'alasql';
import DevTools from './store-config/devtools';
import initStore, { sagaMiddleware } from './store-config/store';
import history from './router/history';
import topicSaga from './saga/topic.saga';
import theorySaga from './saga/theory.saga';
import loginSaga from './saga/login.saga';
import offerSaga from './saga/offer.saga';
import invoiceSaga from './saga/invoice.saga';
import paymentMethodSaga from './saga/paymentMethod.saga';
import register from './saga/register.saga';
import voucher from './saga/voucher.saga';
import user from './saga/user.saga';
import schoolSaga from './saga/school.saga';
import './i18n-local';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { firebaseApp } from './config/firebase.config';
import questionSaga from './saga/question.saga';
import answerSaga from './saga/answer.saga';
import examSaga from './saga/exam.saga';
import authSaga from './saga/auth.saga';
import categorySaga from './saga/category.saga';
import gameSaga from './saga/game.saga';
import gameScoreSaga from './saga/gameScore.saga';
import { isSSR } from './utils/utils';

const store = initStore();
const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

if (process.env.REACT_APP_GOOGLE_GA_KEY) {
  // set key
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_GA_KEY);
  ReactGA.plugin.require('ecommerce');

  // initialize google analytics page view tracking
  history.listen(location => {
    const { currentUser } = firebaseApp.auth();
    ReactGA.set({
      userId: currentUser ? currentUser.uid : '',
      page: location.pathname
    });
    ReactGA.pageview(location.pathname);
  });
}

if (process.env.REACT_APP_VERSION) {
  TrackJS.install({
    token: 'ec8ffef12fff4330a56d6d7de40ffac8',
    application: 'theorie24',
    version: process.env.REACT_APP_VERSION
  });
}

alasql('CREATE localStorage DATABASE IF NOT EXISTS T24v');
alasql('ATTACH localStorage DATABASE T24v AS T24v');
alasql('USE T24v');
alasql(
  'CREATE TABLE IF NOT EXISTS answer(id INT IDENTITY, qTopic number, qNr number, qCat string, uid string, answer json, qStored boolean)'
);
alasql(
  'CREATE TABLE IF NOT EXISTS exam(uid INT IDENTITY, examId string, category string, duration INT, startTime INT, endTime INT, finish boolean, pointCount INT, questionCount INT, truePointCount INT, trueQuestionCount INT)'
);
alasql(
  'CREATE TABLE IF NOT EXISTS examanswer(uid INT IDENTITY, examId string, qNr string, correct boolean, favorite boolean, sortnr INT, selected json)'
);

sagaMiddleware.run(topicSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(questionSaga);
sagaMiddleware.run(answerSaga);
sagaMiddleware.run(offerSaga);
sagaMiddleware.run(invoiceSaga);
sagaMiddleware.run(examSaga);
sagaMiddleware.run(paymentMethodSaga);
sagaMiddleware.run(register);
sagaMiddleware.run(voucher);
sagaMiddleware.run(user);
sagaMiddleware.run(theorySaga);
sagaMiddleware.run(schoolSaga);
sagaMiddleware.run(categorySaga);
sagaMiddleware.run(gameSaga);
sagaMiddleware.run(gameScoreSaga);

cssVars();

const renderFunction: ReactDOM.Renderer = isSSR() ? ReactDOM.hydrate : ReactDOM.render;

renderFunction(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
        {devTools}
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
