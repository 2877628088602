import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import { IBookmarkProps } from './Bookmark.types';

export const Bookmark: React.FC<IBookmarkProps> = ({ isBookmarked, onClick, className }: IBookmarkProps) => {
  return (
    <FontAwesomeIcon
      className={className}
      style={{ color: '#0080c9', cursor: 'pointer' }}
      onClick={onClick}
      icon={isBookmarked ? faBookmark : farBookmark}
      size="lg"
    />
  );
};
