import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { IQuestionChooseProps } from './QuestionGameChoose.types';
import QuestionChooseModalGameContainer from '../QuestionChooseModalGameContainer/QuestionChooseModalGameContainer';
import styles from './QuestionGameChoose.module.css';

const QuestionGameChoose: React.FC<IQuestionChooseProps> = ({
  iconCls,
  answerList,
  onQuestionChange,
  defaultRoute
}: IQuestionChooseProps) => {
  const [showWin, setShowWin] = useState(false);

  const changeModalView = useCallback(() => {
    setShowWin(!showWin);
  }, [showWin, setShowWin]);

  return (
    <button type="button" className={classNames(styles.container, iconCls)} onClick={changeModalView}>
      {Array.from(Array(9).keys()).map(index => (
        <div key={index} />
      ))}
      <QuestionChooseModalGameContainer
        defaultRoute={defaultRoute}
        onQuestionChange={onQuestionChange}
        answerList={answerList}
        show={showWin}
        onHide={changeModalView}
      />
    </button>
  );
};

export default QuestionGameChoose;
