import { put, takeEvery } from 'redux-saga/effects';
import {
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE
} from '../actions/paymentMethod.action';
import { getPaymentProviderListByPlanId } from './paymentMethod.api';

function* getPaymentMethodsList({ payload: planId }) {
  try {
    const response = yield getPaymentProviderListByPlanId(planId);
    yield put(GET_PAYMENT_METHODS_SUCCESS(response.payload.payments.filter(item => item.selectable)));
  } catch (e) {
    yield put(GET_PAYMENT_METHODS_FAILURE(e.message));
  }
}

function* paymentMethodSaga() {
  yield takeEvery(GET_PAYMENT_METHODS_REQUEST, getPaymentMethodsList);
}

export default paymentMethodSaga;
