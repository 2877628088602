import React from 'react';
import { IPaymentRedirectPageProps } from './PaymentRedirectPage.types';
import LoginWithTokenContainer from '../../containers/LoginWithTokenContainer';
import PlanExtensionFinishContainer from '../../containers/PlanExtensionFinishContainer';
import { Loader } from '../../ui-components/Loader/Loader';

const PaymentRedirectPage: React.FC<IPaymentRedirectPageProps> = props => {
  return (
    <>
      <LoginWithTokenContainer {...props} />
      <PlanExtensionFinishContainer {...props} />
      <Loader show />
    </>
  );
};

export default PaymentRedirectPage;
