import React, { useMemo } from 'react';
import { Jumbotron } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IBestResultCardProps } from './BestResultCard.types';
import { P3 } from '../../ui-components/P3/P3';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './BestResultCard.module.css';
import { IRootState } from '../../reducer';
import useDeviceSizes from '../../hooks/useDeviceSizes';

export const BestResultCard: React.FC<IBestResultCardProps> = ({ containerClassName, isVisible = true }) => {
  const { t } = useTranslation();
  const { width } = useDeviceSizes();

  const isMobileMode = useMemo(() => width <= 991, [width]);

  const examsResults = useSelector(({ user }: IRootState) => user.examResults);
  const { bestResult } = examsResults;
  const isAnyExamStarted = Boolean(bestResult && bestResult.questionCount);

  if (!isVisible) {
    return null;
  }

  if (isMobileMode) {
    return (
      <Jumbotron className={`${styles.jumbotron} ${containerClassName} `}>
        <div className={styles.jumbotronHeader}>
          <div className={styles.jumbotronHeaderItem}>
            {isAnyExamStarted && (
              <div className={styles.panelDateList}>
                <div className={styles.panelDate}>{moment(bestResult.startTime).format('DD.MM.YYYY')}</div>
                <div className={styles.panelTime}>{moment(bestResult.startTime).format('HH:mm')}</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.pointsContainer}>
          <LinkWrapper to={isAnyExamStarted ? '/exam-history' : '/exam'} className="linkBtn">
            <div className={styles.panelTitle}>{t('cockpitTopPanel.results.title')}</div>
          </LinkWrapper>
          {isAnyExamStarted && (
            <div className={styles.panelCount}>
              {isAnyExamStarted
                ? `${bestResult.truePointCount}/${bestResult.pointCount}`
                : 'cockpitTopPanel.results.startExamLink'}
            </div>
          )}
          {!isAnyExamStarted && <P3>{t('cockpitTopPanel.results.placeholder')}</P3>}
        </div>
      </Jumbotron>
    );
  }

  return (
    <Jumbotron className={`${styles.jumbotron} ${containerClassName} `}>
      <div className={styles.jumbotronHeader}>
        <div className={styles.jumbotronHeaderItem}>
          <div className={styles.panelTitle}>{t('cockpitTopPanel.results.title')}</div>
        </div>
        {isAnyExamStarted && (
          <div className={styles.panelDateList}>
            <div className={styles.panelDate}>{moment(bestResult.startTime).format('DD.MM.YYYY')}</div>
            <div className={styles.panelTime}>{moment(bestResult.startTime).format('HH:mm')}</div>
          </div>
        )}
      </div>
      {isAnyExamStarted && (
        <div className={styles.panelCount}>{`${bestResult.truePointCount}/${bestResult.pointCount}`}</div>
      )}
      {!isAnyExamStarted && <P3>{t('cockpitTopPanel.results.placeholder')}</P3>}
      <LinkWrapper to={isAnyExamStarted ? '/exam-history' : '/exam'} className="linkBtn">
        {isAnyExamStarted ? t('cockpitTopPanel.results.link') : t('cockpitTopPanel.results.startExamLink')}
        <i className="icon-arrow" />
      </LinkWrapper>
    </Jumbotron>
  );
};
