import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavMenu from '../../components/EbikeNavMenu/EbikeNavMenu';
import EbikeDisplay from '../../components/EbikeDisplay/EbikeDisplay';
import EbikeRoutes from './Ebike.routes';
import { IEbikePageProps } from './EbikePage.types';
import styles from './EbikePage.module.css';

const EbikePage: React.FC<IEbikePageProps> = ({ match, currentLanguage }: IEbikePageProps) => {
  return (
    <>
      <div className={styles.ebikeContent}>
        <EbikeDisplay />
      </div>
      <div className={styles.ebikeContentText}>
        <Container>
          <Row>
            <Col lg={4}>
              <NavMenu currentLanguage={currentLanguage} />
            </Col>
            <Col lg={{ span: 7, offset: 1 }}>
              <EbikeRoutes match={match} currentLanguage={currentLanguage} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EbikePage;
