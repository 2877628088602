import React, { useState, useCallback } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../reducer/user.selectors';
import TermListItem from '../../components/TermListItem/TermListItem';
import CollapseItem from '../../components/CollapseItem/CollapseItem';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import styles from './TermPage.module.css';
import { newTermsTranslation } from './newTermsTranslation';

const TermPage: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);
  const [eventKey, setEventKey] = useState('1');

  const handleSetEventKey = useCallback(
    (newEventKey: string) => {
      setEventKey(newEventKey);
    },
    [setEventKey]
  );

  const TeamPageTextItem = ({ eventKeyItem }: { eventKeyItem: string }) => {
    if (eventKeyItem === '7') {
      return (
        <div
          className={styles.glossaryScrollBox}
          dangerouslySetInnerHTML={{ __html: newTermsTranslation[currentLanguage] }}
        />
      );
    }

    return (
      <div className={styles.termPageItem}>
        <div className={styles.termPageText}>{t(`term.content${eventKeyItem}.text`)}</div>
      </div>
    );
  };

  return (
    <div className={styles.termPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('term.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.termPageContent}>
              <ListGroup>
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="1" name="term.content1.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="2" name="term.content2.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="3" name="term.content3.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="4" name="term.content4.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="5" name="term.content5.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="6" name="term.content6.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="7" name="term.content7.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="8" name="term.content8.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="9" name="term.content9.title" />
                <TermListItem onSetEventKey={handleSetEventKey} eventKey="10" name="term.content10.title" />
              </ListGroup>

              <TeamPageTextItem eventKeyItem={eventKey} />
            </div>
            <div className={styles.termPageContentMobile}>
              <CollapseItem
                question="term.content1.title"
                answer="term.content1.text"
                eventKey="1"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '1'}
              />
              <CollapseItem
                question="term.content2.title"
                answer="term.content2.text"
                eventKey="2"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '2'}
              />
              <CollapseItem
                question="term.content3.title"
                answer="term.content3.text"
                eventKey="3"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '3'}
              />
              <CollapseItem
                question="term.content4.title"
                answer="term.content4.text"
                eventKey="4"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '4'}
              />
              <CollapseItem
                question="term.content5.title"
                answer="term.content5.text"
                eventKey="5"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '5'}
              />
              <CollapseItem
                question="term.content6.title"
                answer="term.content6.text"
                eventKey="6"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '6'}
              />
              <CollapseItem
                question="term.content7.title"
                content={<TeamPageTextItem eventKeyItem="7" />}
                eventKey="7"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '7'}
              />
              <CollapseItem
                question="term.content8.title"
                answer="term.content8.text"
                eventKey="8"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '8'}
              />
              <CollapseItem
                question="term.content9.title"
                answer="term.content9.text"
                eventKey="9"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '9'}
              />
              <CollapseItem
                question="term.content10.title"
                answer="term.content10.text"
                eventKey="10"
                onSetEventKey={handleSetEventKey}
                show={eventKey === '10'}
              />
            </div>
            <div className={styles.mobileText}>{t('term.mobile.text')}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermPage;
