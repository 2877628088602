import React, { FC } from 'react';
import { ICardWrapperProps } from './CardWrapper.types';
import styles from './CardWrapper.module.css';

const CardWrapper: FC<ICardWrapperProps> = ({ onClick = () => {}, children, className = '' }: ICardWrapperProps) => {
  return (
    <div onClick={onClick} className={`${styles.cardItem} ${className}`}>
      {children}
    </div>
  );
};

export default CardWrapper;
