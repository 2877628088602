import moment from 'moment';
import shuffle from 'lodash.shuffle';
import get from 'lodash.get';
import { API_DATE_FORMAT } from '../constants/api';
import { School } from '../reducer/school.types';
import { IQuestion } from '../reducer/question.types';
import { IUserAnswer } from '../reducer/user.types';
import i18n from '../i18n-local';
import { defaultLanguage } from '../locales/locale';
import { answerStatusType, gameDuration } from '../reducer/game.types';
import { CORRECT_ANSWER, GAME_DURATION_OBJ, THEME_SCHEMA, WRONG_ANSWER } from '../constants';
import questionList from '../db/question.json';
import { ThemeColors } from '../reducer/theme.types';

const ExceptionSEOPatch = [
  'theoriepruefung',
  'autopruefung-theorie',
  'theoriepruefung-lernen',
  'mofa-pruefung',
  'rollerpruefung',
  'auto-theorie-lernen',
  'auto-theoriepruefung-lernen',
  'theoretische-autopruefung',
  'theoretische-fahrpruefung',
  'auto-theoriepruefung',
  'theoriepruefung-fuehrerschein'
];

export const getRandomListFromArr = (arr, count: number) => {
  arr.sort(() => Math.random() - 0.5);
  return arr.length > count ? arr.splice(0, count) : arr;
};

export const randomInteger = (min: number, max: number): number => {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1));
};

export const isSSR = () => typeof window === 'undefined';

export const getFBCategoryName = (category: string): string => {
  switch (category) {
    case 'B':
    case 'A,A1':
      return 'BAA1';
    case 'F,G':
      return 'FG';
    default:
      return category;
  }
};

export const getMarkedCategory = (category: string): string => {
  switch (category) {
    case 'B':
    case 'A,A1':
      return 'BAA1';
    default:
      return category;
  }
};

export const checkIfThisQuestionExistInQuestionJSON = id => {
  return questionList.some(question => question.id === id);
};

export const getTheoryImage = (id: string): string => `/content/theory/${id}.jpg`;

const separateLinePattern = /\n{1}/;

export const splitTextByLines = (text: string): string[] => text.split(separateLinePattern);

export const getTheoryContentImgAnchorIndex = (text: string): string | null => {
  if (text.includes('[IMAGE') && text.includes(']')) {
    return text
      .replace('[IMAGE', '')
      .replace(']', '')
      .trim();
  }
  return null;
};
const separator = '<|+|>';
const imgPlaceholderPattern = /\[IMAGE[0-9]+]/g;

export const splitTextContentWithImgToChunks = (text: string): string[] =>
  text
    .replace(imgPlaceholderPattern, `${separator}$&${separator}`)
    .split(separator)
    .map(item => item.trim())
    .filter(Boolean);

const sharedUrl: string = 'https://www.theorie24.ch/';

export const getFBShareLink = (lang: string): string =>
  `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}${lang}/`;
export const getLiShareLink = (lang: string): string =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${sharedUrl}${lang}/`;

export const formatTextValue = (value: string, emptyPlaceholder: string = '-'): string => {
  return value?.trim() ? value : emptyPlaceholder;
};

export const scrollToAnchor = (id: string, args?: ScrollIntoViewOptions): void => {
  const newId = id.startsWith('#') ? id.slice(1) : id;
  const { block = 'start', behavior = 'smooth' } = args || {};
  const element: HTMLElement | null = document.getElementById(newId);

  if (element) {
    element.scrollIntoView({ block, behavior });
  }
};

export const isPlanExpired = (expirePlaneDateTime?: string | null): boolean => {
  if (!expirePlaneDateTime) {
    return false;
  }

  const expiredMoment = moment(expirePlaneDateTime, API_DATE_FORMAT);
  if (!expiredMoment.isValid()) {
    return false;
  }

  const now = moment();
  return now.isAfter(expiredMoment);
};

export const getPlanDateCountLabel = (expirePlaneDateTime?: string, expiredLabel: string = '0'): string => {
  if (expirePlaneDateTime) {
    if (isPlanExpired(expirePlaneDateTime)) {
      return expiredLabel;
    }

    return String(moment(expirePlaneDateTime, API_DATE_FORMAT).diff(moment(), 'days'));
  }

  return expiredLabel;
};

export const getTeacherFullName = (school: School): string => {
  const teacherFirstName = school.teacherFirstName ? school.teacherFirstName.trim() : '';
  const teacherLastName = school.teacherLastName ? school.teacherLastName.trim() : '';
  return `${teacherFirstName} ${teacherLastName}`.trim();
};

export const getTextMessageFromRequestError = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && error !== null && error.message) {
    return error.message;
  }
  return '';
};

export const triggerMenuCollapse = () => {
  const toggleButton: HTMLButtonElement | null = document.querySelector('.navbar-toggler.collapsed');
  if (toggleButton) {
    toggleButton.click();
  }
};

export const checkIsAnswerIsCorrect = (question: IQuestion, selected: number[]) => {
  const correctStatus = !question.correct.some((item: number) => !(selected.indexOf(item - 1) >= 0));
  return correctStatus ? selected.length === question.correct.length : correctStatus;
};

export const addAnswerToAnswerList = (userAnswer: IUserAnswer, answerId: number) => {
  const selectedAnswers: number[] = [...userAnswer.selected];

  if (selectedAnswers.indexOf(answerId) >= 0) {
    selectedAnswers.splice(selectedAnswers.indexOf(answerId), 1);
  } else {
    selectedAnswers.push(answerId);
  }
  return selectedAnswers;
};

export const getLanguageByLocation = (pathName: string) => {
  const languageRegexp = /^\/(en|de|it|fr)/;
  const locationLangaugeRes = languageRegexp.exec(pathName);
  const locationLanguage = locationLangaugeRes && locationLangaugeRes[1];
  return locationLanguage || defaultLanguage;
};

export const SSRHelmet = (pathName: string, routes) => {
  const languageRegexp = /^\/(en|de|it|fr)/;
  const defaultPage = 'MainPage';

  let correctRout = pathName;
  const language = getLanguageByLocation(pathName);

  if (correctRout.length > 1 && correctRout[correctRout.length - 1] === '/') {
    correctRout = correctRout.substring(0, correctRout.length - 1);
  }
  correctRout = correctRout.replace(languageRegexp, ``);

  if (correctRout === '') {
    correctRout = '/';
  }

  const { resources } = i18n.options;
  const route = routes.find(item => item.path === correctRout);
  if (language && route && route.name) {
    try {
      // @ts-ignore
      return resources![language].translation!.seo[route.name] || resources![language].translation!.sharing;
    } catch (e) {
      // @ts-ignore
      return resources![defaultLanguage].translation!.seo[defaultPage] || resources![language].translation!.sharing;
    }
  }
  // @ts-ignore
  return resources![defaultLanguage].translation!.seo[defaultPage] || resources![language].translation!.sharing;
};

export const imageAbsolutePath = (image: string) => {
  return `/static/images/${image}`;
};

export const soundAbsolutePath = (sound: string) => {
  return `/static/sounds/${sound}`;
};

export const checkIsSEOPathWithoutLangPrefix = (location: string) => {
  const formattedPath = location.split('/');
  const index = ExceptionSEOPatch.findIndex(path => {
    return formattedPath.findIndex(formatted => formatted === path) !== -1;
  });
  return index !== -1;
};

export const checkAndConvertEmptyString = (text: string | undefined, returnString: string = '—') => {
  return typeof text === 'undefined' || !text || text.trim() === '' ? returnString : text;
};

export const shuffleQuestionWithQuestion = (filterCb = item => item) => {
  return shuffle(questionList)
    .filter(filterCb)
    .map((item: IQuestion) => {
      return { id: item.id, questionId: randomInteger(1, 3) };
    });
};

export const sliceQuestion = (name: gameDuration, withCategory?: string) => {
  if (withCategory) {
    return shuffleQuestionWithQuestion(item => item.category === withCategory).slice(0, GAME_DURATION_OBJ[name]);
  }
  return shuffleQuestionWithQuestion().slice(0, GAME_DURATION_OBJ[name]);
};

export const getQuestionById = (id: number) => {
  return questionList.find(item => item.id === id);
};

export const getUniqId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
};

export const getAnswerType = (
  checkValue: answerStatusType | undefined,
  correctValue: any,
  wrongValue: any,
  notAnswerValue: any,
  notSet?: any
) => {
  if (!checkValue) {
    return notSet;
  }
  if (checkValue === CORRECT_ANSWER) {
    return correctValue;
  }
  if (checkValue === WRONG_ANSWER) {
    return wrongValue;
  }
  if (!checkValue) {
    return notSet;
  }
  return notAnswerValue;
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export const checkIfExistInsideArray = (array: string[], text: string) => array.some(v => text.includes(v));

export const getTranslationList = (language, pathToElement) => {
  const { resources } = i18n.options;

  return get(resources![language].translation!, pathToElement);
};

// Ramda
export const compose = (...fns) => (...args) => fns.reduce((res, fn) => [fn.call(null, ...res)], args)[0];

export function curry(fn) {
  const arity = fn.length;

  return function $curry(...args) {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }

    return fn.call(null, ...args);
  };
}

export const map = curry((f, xs) => xs.map(f));
/* eslint-disable */
export const dissoc = curry(function dissoc(prop, obj) {
  const result = {};
  for (const p in obj) {
    result[p] = obj[p];
  }
  delete result[prop];
  return result;
});
export const setObjectValue = curry((key: string, value: any, xs: object) => {
  xs[key] = value;
  return xs;
});
/* eslint-enable */

export const objectCopy = curry(value => {
  return { ...value };
});

export const switchColorTheme = (theme: ThemeColors) => {
  const switchClassNameObject = {
    [ThemeColors.dark]: 'dark-theme',
    [ThemeColors.light]: '',
    [ThemeColors.lady]: 'lady-theme'
  };

  /** change color scheme on game pages */
  const root = document.documentElement;
  const mapSchemeProperty = ({ name, value }) => {
    root.style.setProperty(name, value);
  };

  const currentClassName = switchClassNameObject[theme];

  document.body.classList.remove(switchClassNameObject[ThemeColors.dark]);
  document.body.classList.remove(switchClassNameObject[ThemeColors.lady]);
  currentClassName && document.body.classList.add(currentClassName);

  const filterThemSchemeProperty = ({ name, darkThemeColor, defaultColor, ladyThemeColor }) => {
    const valueObject = {
      [ThemeColors.dark]: darkThemeColor,
      [ThemeColors.light]: defaultColor,
      [ThemeColors.lady]: ladyThemeColor
    };
    return {
      name,
      value: valueObject[theme]
    };
  };
  const setSchemeProperty = compose(map(filterThemSchemeProperty), map(mapSchemeProperty));
  setSchemeProperty(THEME_SCHEMA);
};

export const TCSLinkGenerator = (urlEnd: string, currentLanguage: string) => {
  const BaseURL = 'https://tcs.theorie24.ch';
  return `${BaseURL}/${currentLanguage}/${urlEnd}`;
};
