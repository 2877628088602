import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Container, Col } from 'react-bootstrap';
import Ads from '../../components/Ads/Ads';
import QuestionsByTopicContainer from '../../containers/QuestionsByTopicContainer/QuestionsByTopicContainer';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import styles from './QuestionPage.module.css';
import QuestionsBgContainer from '../../containers/QuestionsBgConainer/QuestionsBgContainer';
import { IRootState } from '../../reducer';

const QuestionPage: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  return (
    <>
      <div className={styles.questionPage}>
        <Container>
          <Row>
            <Col>
              <QuestionsBgContainer />
            </Col>
          </Row>
        </Container>
        {!isLogged && <Ads />}
        {isLogged ? (
          <div className={styles.questionTopicBg}>
            <QuestionsByTopicContainer />
          </div>
        ) : (
          <QuestionsByTopicContainer />
        )}
        <Container className={styles.mobileView}>
          <Row>
            <Col>
              <InstructorContainer />
            </Col>
          </Row>
        </Container>
        {isLogged && <Ads />}
      </div>
    </>
  );
};

export default QuestionPage;
