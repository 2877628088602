import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from '../reducer';
import { BottomDetails } from '../ui-components/BottomDetails/BottomDetails';
import { P3 } from '../ui-components/P3/P3';
import { BottomDetailsColumn } from '../ui-components/BottomDetailsColumn/BottomDetailsColumn';

const ExamDetailsContainer: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const { t } = useTranslation();

  return (
    <>
      <BottomDetails>
        <BottomDetailsColumn>
          <P3>{t(`examPage.bottomDescription.left.${isLogged ? 'L' : 'NL'}.1`)}</P3>
          <P3>{t(`examPage.bottomDescription.left.${isLogged ? 'L' : 'NL'}.2`)}</P3>
          {isLogged && <P3>{t(`examPage.bottomDescription.left.L.3`)}</P3>}
        </BottomDetailsColumn>
        <BottomDetailsColumn>
          <P3>{t(`examPage.bottomDescription.center.${isLogged ? 'L' : 'NL'}.1`)}</P3>
          <P3>{t(`examPage.bottomDescription.center.${isLogged ? 'L' : 'NL'}.2`)}</P3>
          {isLogged && <P3>{t(`examPage.bottomDescription.center.L.3`)}</P3>}
          {isLogged && <P3>{t(`examPage.bottomDescription.center.L.4`)}</P3>}
        </BottomDetailsColumn>
        <BottomDetailsColumn>
          <P3>{t(`examPage.bottomDescription.right.${isLogged ? 'L' : 'NL'}.1`)}</P3>
          {isLogged && <P3>{t(`examPage.bottomDescription.right.L.2`)}</P3>}
        </BottomDetailsColumn>
      </BottomDetails>
    </>
  );
};

export default ExamDetailsContainer;
