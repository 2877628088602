import { all, takeLatest, put, call } from '@redux-saga/core/effects';
import {
  VERIFY_VOUCHER_CODE_REQUEST,
  VERIFY_VOUCHER_CODE_FAILURE,
  VERIFY_VOUCHER_CODE_SUCCESS
} from '../actions/voucher.actions';
import { verifyVoucherCode } from './voucher.api';
import { VerifyVoucherSuccessPayloadSchema } from './voucher.schema';
import { TEACHER_ID } from '../constants/storageKeys';

function* checkVoucherCode({ payload }) {
  try {
    const response = yield call(verifyVoucherCode, payload);

    if (response.status !== 200) {
      if (response.payload && response.payload.message) {
        yield put(VERIFY_VOUCHER_CODE_FAILURE(response.payload.message));
      }
    } else if (yield VerifyVoucherSuccessPayloadSchema.isValid(response.payload)) {
      const { voucher } = response.payload;

      yield put(VERIFY_VOUCHER_CODE_SUCCESS(voucher));

      if (voucher.teacherId) {
        window.localStorage.setItem(TEACHER_ID, voucher.teacherId);
      }
    } else {
      yield put(VERIFY_VOUCHER_CODE_FAILURE(null));
      // TODO handle invalid schema
    }
  } catch (e) {
    yield put(VERIFY_VOUCHER_CODE_FAILURE(e));
  }
}

function* voucherSaga() {
  yield all([takeLatest(VERIFY_VOUCHER_CODE_REQUEST, checkVoucherCode)]);
}

export default voucherSaga;
