import { handleActions } from 'redux-actions';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import {
  ADD_EXAM_ANSWER_REQUEST,
  ADD_EXAM_ANSWER_SUCCESS,
  ADD_EXAM_ANSWER_FAILURE,
  GET_QUESTION_FOR_EXAM_FAILURE,
  GET_QUESTION_FOR_EXAM_REQUEST,
  GET_QUESTION_FOR_EXAM_SUCCESS,
  CREATE_NEW_EXAM_REQUEST,
  CREATE_NEW_EXAM_FAILURE,
  CREATE_NEW_EXAM_SUCCESS,
  SAVE_NEW_EXAM_FAILURE,
  SAVE_NEW_EXAM_SUCCESS,
  SAVE_NEW_EXAM_REQUEST,
  FINISH_EXAM_REQUEST,
  FINISH_EXAM_SUCCESS,
  FINISH_EXAM_FAILURE,
  RECOVERY_EXAM_SUCCESS,
  RECOVERY_EXAM_REQUEST,
  RECOVERY_EXAM_FAILURE,
  UPDATE_EXAM_RESULT_REQUEST,
  UPDATE_ANSWER_POINT_FAILURE,
  UPDATE_ANSWER_POINT_REQUEST,
  UPDATE_ANSWER_POINT_SUCCESS,
  UPDATE_EXAM_RESULT_FAILURE,
  UPDATE_EXAM_RESULT_SUCCESS,
  CLEAR_EXAM,
  GET_LAST_EXAM_REQUEST,
  GET_LAST_EXAM_SUCCESS,
  GET_LAST_EXAM_FAILURE,
  CLEAR_LAST_EXAM_DATA
} from '../actions/exam.action';
import { IExamState } from './exam.types';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IExamState = {
  examQuestionList: [],
  examAnswerList: {},
  examAnswerRequest: defaultRequestStatus,
  updateAnswerPointRequest: defaultRequestStatus,
  createExamRequest: defaultRequestStatus,
  finishExamRequest: defaultRequestStatus,
  saveNewExamRequest: defaultRequestStatus,
  addExamAnswerRequest: defaultRequestStatus,
  examQuestionListRequest: defaultRequestStatus,
  updateResultRequest: defaultRequestStatus,
  recoveryExamRequest: defaultRequestStatus,
  lastExamRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_QUESTION_FOR_EXAM_REQUEST]: state => ({
      ...state,
      examQuestionListRequest: { status: PENDING, error: null }
    }),
    [FINISH_EXAM_REQUEST]: state => ({
      ...state,
      finishExamRequest: { status: PENDING, error: null }
    }),
    [FINISH_EXAM_SUCCESS]: state => ({
      ...state,
      isFinished: true,
      lastExam: state.lastExam ? { ...state.lastExam, finish: true } : null,
      finishExamRequest: { status: SUCCESS, error: null }
    }),
    [FINISH_EXAM_FAILURE]: (state, action) => ({
      ...state,
      finishExamRequest: { status: FAILURE, error: action.payload }
    }),
    [CREATE_NEW_EXAM_REQUEST]: () => ({
      ...defaultState,
      createExamRequest: { status: PENDING, error: null }
    }),
    [CREATE_NEW_EXAM_SUCCESS]: state => ({
      ...state,
      createExamRequest: { status: SUCCESS, error: null }
    }),
    [CREATE_NEW_EXAM_FAILURE]: (state, action) => ({
      ...defaultState,
      createExamRequest: { status: FAILURE, error: action.payload }
    }),
    [SAVE_NEW_EXAM_REQUEST]: (state, action) => ({
      ...defaultState,
      saveNewExamRequest: { status: PENDING, error: null },
      duration: action.payload.duration,
      questionCount: action.payload.questionCount,
      pointCount: action.payload.pointCount,
      trueQuestionCount: 0,
      truePointCount: 0,
      currentExamId: action.payload.uuid,
      examStartTime: action.payload.examStartTime,
      examAnswerList: action.payload.examAnswerList,
      examQuestionList: action.payload.examQuestionList
    }),
    [SAVE_NEW_EXAM_SUCCESS]: state => ({
      ...state,
      saveNewExamRequest: { status: SUCCESS, error: null }
    }),
    [SAVE_NEW_EXAM_FAILURE]: (state, action) => ({
      ...state,
      saveNewExamRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_QUESTION_FOR_EXAM_FAILURE]: (state, action) => ({
      ...state,
      examQuestionListRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_QUESTION_FOR_EXAM_SUCCESS]: (state, action) => ({
      ...state,
      examQuestionListRequest: { status: SUCCESS, error: null },
      examQuestionList: action.payload
    }),
    [ADD_EXAM_ANSWER_REQUEST]: (state, action) => ({
      ...state,
      addExamAnswerRequest: { status: PENDING, error: null },
      examAnswerList: {
        ...state.examAnswerList,
        [action.payload.examId]: state.examAnswerList[action.payload.examId]
          ? {
              ...state.examAnswerList[action.payload.examId],
              [action.payload.questionId]: action.payload.answer
            }
          : {
              [action.payload.questionId]: action.payload.answer
            }
      }
    }),
    [ADD_EXAM_ANSWER_SUCCESS]: state => ({
      ...state,
      addExamAnswerRequest: { status: SUCCESS, error: null }
    }),
    [ADD_EXAM_ANSWER_FAILURE]: (state, action) => ({
      ...state,
      addExamAnswerRequest: { status: FAILURE, error: action.payload }
    }),
    [RECOVERY_EXAM_REQUEST]: state => ({
      ...state,
      recoveryExamRequest: { status: PENDING, error: null }
    }),
    [RECOVERY_EXAM_FAILURE]: (state, action) => ({
      ...state,
      recoveryExamRequest: { status: FAILURE, error: action.payload }
    }),
    [UPDATE_ANSWER_POINT_REQUEST]: state => ({
      ...state,
      updateAnswerPointRequest: { status: PENDING, error: null }
    }),
    [UPDATE_ANSWER_POINT_SUCCESS]: (state, action) => ({
      ...state,
      trueQuestionCount: action.payload.trueQuestionCount,
      truePointCount: action.payload.truePointCount,
      updateAnswerPointRequest: { status: SUCCESS, error: null }
    }),
    [UPDATE_ANSWER_POINT_FAILURE]: (state, action) => ({
      ...state,
      updateAnswerPointRequest: { status: FAILURE, error: action.payload }
    }),
    [RECOVERY_EXAM_SUCCESS]: (state, action) => ({
      ...state,
      duration: action.payload.duration,
      pointCount: action.payload.pointCount,
      questionCount: action.payload.questionCount,
      truePointCount: action.payload.truePointCount,
      trueQuestionCount: action.payload.trueQuestionCount,
      examQuestionList: action.payload.examQuestionList,
      examStartTime: action.payload.examStartTime,
      currentExamId: action.payload.currentExamId,
      isFinished: action.payload.isFinished,
      examAnswerList: {
        ...state.examAnswerList,
        ...action.payload.examAnswerList
      },
      recoveryExamRequest: { status: SUCCESS, error: null }
    }),
    [UPDATE_EXAM_RESULT_REQUEST]: state => ({
      ...state,
      examResult: defaultState.examResult,
      updateResultRequest: { status: PENDING, error: null }
    }),
    [UPDATE_EXAM_RESULT_FAILURE]: (state, action) => ({
      ...state,
      examResult: defaultState.examResult,
      updateResultRequest: { status: FAILURE, error: action.payload }
    }),
    [UPDATE_EXAM_RESULT_SUCCESS]: (state, action) => ({
      ...state,
      examResult: {
        ...action.payload
      },
      updateResultRequest: { status: SUCCESS, error: action.payload }
    }),
    [GET_LAST_EXAM_REQUEST]: state => ({
      ...state,
      lastExamRequest: { status: PENDING, error: null }
    }),
    [GET_LAST_EXAM_SUCCESS]: (state, action) => ({
      ...state,
      lastExam: action.payload,
      lastExamRequest: { status: SUCCESS, error: null }
    }),
    [GET_LAST_EXAM_FAILURE]: (state, action) => ({
      ...state,
      lastExamRequest: { status: FAILURE, error: action.payload }
    }),
    [CLEAR_EXAM]: state => ({
      ...defaultState,
      updateResultRequest: state.updateResultRequest
    }),
    [CLEAR_LAST_EXAM_DATA]: state => ({
      ...state,
      lastExamRequest: defaultRequestStatus,
      lastExam: defaultState.lastExam
    })
  },
  defaultState
);

export default reduces;
