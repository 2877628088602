import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { VipCodeSection, KeyBenefitsSection } from '../HomePageNew/sections/index.sections';
import { TitleSection, OffersSection, ImageBreak, ShopLinkSection } from './sections/index.sections';
import CommonContainer from '../../ui-components/CommonContainer/CommonContainer';
import styles from './NewOffersPage.module.css';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';

const NewOffersPage: React.FC = () => {
  const { history, location } = useReactRouter();
  const { isLogged }: LoginState = useSelector(selectLogin);

  // fix issue with login in offers page
  useEffect(() => {
    if (isLogged) {
      history.replace(location.pathname.replace('/offers', '/extend-offers'));
    }
  }, [isLogged, history, location]);

  return (
    <div className={styles.offersPageText}>
      <CommonContainer>
        <TitleSection />
      </CommonContainer>
      <CommonContainer className={styles.offersContainer}>
        <OffersSection />
      </CommonContainer>
      <CommonContainer className={styles.keyBenefitsContainer}>
        <KeyBenefitsSection />
      </CommonContainer>
      <CommonContainer className={styles.imageBreakContainer}>
        <ImageBreak />
      </CommonContainer>
      <CommonContainer className={styles.vipCodeContainer}>
        <VipCodeSection />
      </CommonContainer>
      <CommonContainer className={styles.shopLinkContainer}>
        <ShopLinkSection />
      </CommonContainer>
    </div>
  );
};

export default NewOffersPage;
