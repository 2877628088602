import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ICategoryConfig, ICategoryListProps } from './CategoryList.types';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { QuestionTypeSelector } from '../QuestionTypeSelector/QuestionTypeSelector';
import { P3 } from '../../ui-components/P3/P3';
import styles from './CategoryList.module.css';

const categoryConfig: ICategoryConfig[] = [
  {
    name: 'B',
    icon: 'icon-car'
  },
  {
    name: 'A,A1',
    icon: 'icon-motorcycle'
  },
  {
    name: 'F,G',
    icon: 'icon-tractor'
  },
  {
    name: 'M',
    icon: 'icon-e-bike'
  }
];

const isDemoCategory: ICategoryConfig[] = [
  {
    name: 'B',
    icon: 'icon-car'
  },
  {
    name: 'A,A1',
    icon: 'icon-motorcycle'
  }
];

export const CategoryList: React.FC<ICategoryListProps> = ({
  className,
  selectedCategory,
  onClickDriveLicense,
  withFilteredCategories,
  userAvailableCategories,
  isDemo,
  isExam
}: ICategoryListProps) => {
  const { t } = useTranslation();
  const categoryConfigList = isDemo ? isDemoCategory : categoryConfig;
  return (
    <div className={styles.categoryContainer}>
      <div className={styles.mainContainer}>
        <P3 className={styles.titleText}>{t('cockpitTopPanel.hi.subtitle2')}</P3>
        <div className={classNames(styles.rulesList, className)}>
          {categoryConfigList.map(category => {
            if (withFilteredCategories) {
              if (userAvailableCategories && userAvailableCategories.indexOf(category.name) !== -1) {
                return (
                  <ButtonIcon
                    key={category.name}
                    level={category.name}
                    selectedLevel={selectedCategory}
                    content={<i className={category.icon} />}
                    onClick={onClickDriveLicense}
                  />
                );
              }
              return null;
            }
            return (
              <ButtonIcon
                key={category.name}
                level={category.name}
                selectedLevel={selectedCategory}
                content={<i className={category.icon} />}
                onClick={onClickDriveLicense}
              />
            );
          })}
        </div>
      </div>

      {!isExam && <QuestionTypeSelector isDemo={isDemo} />}
    </div>
  );
};
