import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import image from '../../img/404.gif';
import Image from '../../ui-components/Image/Image';
import styles from './NotFoundContainer.module.css';

const NotFoundContainer: React.FC = () => {
  return (
    <div className={styles.notFoundPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.pageContainer}>
              <div className={styles.imgContainer}>
                <Image src={image} width="100%" />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.pageTextError}>404</div>
                <div className={styles.pageText}>Sorry, this page isn’t available!</div>
              </div>
              <LinkWrapper className={styles.linkBtn} to="/">
                <i className="icon-arrow" />
                back to homepage
              </LinkWrapper>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFoundContainer;
