import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.css';
import { ICheckboxProps } from './Checkbox.types';

const Checkbox: React.FC<ICheckboxProps> = ({
  isChecked,
  label,
  id,
  onCheckedChange,
  isCorrect,
  showCorrectStatus
}: ICheckboxProps) => {
  let correctCls: string = '';

  if (typeof isCorrect === 'boolean' && showCorrectStatus) {
    correctCls = isCorrect ? styles.correct : styles.incorrect;
  }

  const handleChange = useCallback(() => {
    if (!showCorrectStatus) {
      // id - 1 because answerListSelected order started from 0
      onCheckedChange(!isChecked, id - 1);
    }
  }, [id, onCheckedChange, isChecked, showCorrectStatus]);

  return (
    <p
      className={classNames(
        {
          [styles.showCorrect]: showCorrectStatus
        },
        styles.checkboxField,
        correctCls
      )}
    >
      <input
        type="checkbox"
        onChange={handleChange}
        checked={isChecked}
        className={styles.inputField}
        id={id.toString()}
      />
      <label className={styles.label} htmlFor={id.toString()}>
        <span className={classNames(styles.labelBox, correctCls, { [styles.checked]: isChecked })} />
        <span className={classNames('text-to-read')}>{label}</span>
      </label>
    </p>
  );
};

export default Checkbox;
