import { useEffect, useState, useRef } from 'react';
import { PENDING, SUCCESS, FAILURE } from '../constants/store.constants';

const successImgRequestSet = new Set<string>();

export interface IUseImgFetchStatus {
  isFetched: boolean;
  isPending: boolean;
  isError: boolean;
}

export default function useImgFetchStatus(src: string): IUseImgFetchStatus {
  const [status, setStatus] = useState<object>({ [src]: successImgRequestSet.has(src) ? SUCCESS : null });
  const isGone = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      isGone.current = true;
    };
  }, []);

  useEffect(() => {
    if (successImgRequestSet.has(src)) {
      setStatus(state => ({ ...state, [src]: SUCCESS }));
    }
  }, [src]);

  useEffect(() => {
    if (src && !status[src]) {
      const img = new window.Image();
      img.onerror = () => {
        if (!isGone.current) {
          setStatus(state => ({ ...state, [src]: FAILURE }));
        }
      };
      img.onload = () => {
        if (!isGone.current) {
          setStatus(state => ({ ...state, [src]: SUCCESS }));
        }
        successImgRequestSet.add(src);
      };
      img.src = src;
      setStatus(state => ({ ...state, [src]: PENDING }));
    }
  }, [src, status]);

  return {
    isFetched: status[src] === SUCCESS,
    isPending: status[src] === PENDING,
    isError: status[src] === FAILURE
  };
}
