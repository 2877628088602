import { put, takeEvery } from 'redux-saga/effects';
import alasql from 'alasql';
import {
  GET_TOPICS_REQUEST,
  GET_TOPICS_FAILURE,
  GET_TOPICS_SUCCESS,
  GET_PROFILE_STATISTICS
} from '../actions/topic.action';
import { SET_USER_STATISTICS } from '../actions/user.action';
import { firebaseApp } from '../config/firebase.config';
import { TopicsAttributes } from '../reducer/topic.types';
import { TTopic, langTopicSchema } from './topic.schema';
import { getFBCategoryName } from '../utils/utils';
import { getAnswerList } from './answer.api';
import topicjson from '../db/topic.json';
import topicASAjson from '../db/topic_asa.json';

function* getTopicList(action) {
  try {
    const { language = 'de', cockpitPage = false, category = 'B' } = action.payload;
    const topics: TopicsAttributes[] = [];
    const topicsASA: TopicsAttributes[] = [];
    const categoryForQuery = getFBCategoryName(category);

    let allTopicList: TTopic[] = [];
    let allTopicListASA: TTopic[] = [];
    if (categoryForQuery === 'M') {
      allTopicList = alasql('SELECT * FROM ? WHERE M > 0 AND lang = ? ORDER BY sortnr ASC', [topicjson, language]);
      allTopicListASA = alasql('SELECT * FROM ? WHERE M > 0 AND lang = ? ORDER BY sortnr ASC', [
        topicASAjson,
        language
      ]);
    } else if (categoryForQuery === 'FG') {
      allTopicList = alasql('SELECT * FROM ? WHERE FG > 0 AND lang = ? ORDER BY sortnr ASC', [topicjson, language]);
      allTopicListASA = alasql('SELECT * FROM ? WHERE FG > 0 AND lang = ? ORDER BY sortnr ASC', [
        topicASAjson,
        language
      ]);
    } else {
      allTopicList = alasql('SELECT * FROM ? WHERE BAA1 > 0 AND lang = ? ORDER BY sortnr ASC', [topicjson, language]);
      allTopicListASA = alasql('SELECT * FROM ? WHERE BAA1 > 0 AND lang = ? ORDER BY sortnr ASC', [
        topicASAjson,
        language
      ]);
    }

    allTopicList.forEach(topicItem => {
      const item = {
        title: topicItem.title,
        themeid: topicItem.themeid,
        sortnr: topicItem.sortnr,
        qCount: topicItem[categoryForQuery]
      };

      if (langTopicSchema.isValid(item)) {
        topics.push({
          questionsCount: topicItem[categoryForQuery],
          id: topicItem.themeid,
          title: topicItem.title
        });
      }
    });
    allTopicListASA.forEach(topicItem => {
      const item = {
        title: topicItem.title,
        themeid: topicItem.themeid,
        sortnr: topicItem.sortnr,
        qCount: topicItem[categoryForQuery]
      };

      if (langTopicSchema.isValid(item)) {
        topicsASA.push({
          questionsCount: topicItem[categoryForQuery],
          id: topicItem.themeid,
          title: topicItem.title
        });
      }
    });

    if (cockpitPage) {
      if (topics.length > 0) {
        yield put(GET_PROFILE_STATISTICS({ topics, categoryForQuery }));
      }
    } else {
      yield put(GET_TOPICS_SUCCESS({ topics, topicsASA }));
    }
  } catch (e) {
    yield put(GET_TOPICS_FAILURE(e.message));
  }
}

function* getStatistics({ payload }) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : '';
  const topics = payload.topics.find(item => item.id === -1);
  const questions = topics ? topics.questionsCount : 0;

  const answers = yield getAnswerList(userId, payload.categoryForQuery);

  const topciIds = Object.keys(answers);

  // count answers
  const sum = topciIds.reduce((currentSum, topicid) => currentSum + Object.keys(answers[topicid]).length, 0);

  let checkedCount: number = 0;
  let correctCount: number = 0;

  // is all!
  const topicIds = Object.keys(answers);
  topicIds.forEach(topicId => {
    const answersKeyList: string[] = Object.keys(answers[topicId]);

    answersKeyList.forEach(answerKey => {
      if (typeof answers[topicId][answerKey].correct === 'boolean') {
        checkedCount += 1;
        if (answers[topicId][answerKey].correct) {
          correctCount += 1;
        }
      }
    });
  });

  yield put(
    SET_USER_STATISTICS({
      questionsCount: questions,
      status: checkedCount ? correctCount / checkedCount : -1,
      progress: (sum * 100) / questions
    })
  );
}

function* topicSaga() {
  yield takeEvery(GET_TOPICS_REQUEST, getTopicList);
  yield takeEvery(GET_PROFILE_STATISTICS, getStatistics);
}

export default topicSaga;
