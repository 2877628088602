import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { SET_SCORE_LIVES_MODE } from '../../../actions/gameScore.action';
import { ReactComponent as LiveLongSVG } from '../../../img/game/slime.svg';
import { ReactComponent as LiveAdvanceSVG } from '../../../img/game/character-3.svg';
import { ReactComponent as LiveJesusSVG } from '../../../img/game/skeleton-armored.svg';
import { GAME_LIVE_NAME_LONG, GAME_LIVE_NAME_MEDIUM, GAME_LIVE_NAME_SHORT } from '../../../constants';
import { IRootState } from '../../../reducer';
import styles from './ScoreTypeSelector.module.css';

const ScoreTypeSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedScoreType = useSelector(({ gameScore }: IRootState) => gameScore.livesMode);

  const dropDownList = useCallback(
    (withActive?) => {
      const scaleObj = { scale: [1, 1.4, 1] };
      const transitionObj = { duration: 2.5, repeat: Infinity };

      return {
        start: (
          <div className={styles.selectDifficultyWrapper}>
            <motion.div transition={transitionObj} animate={withActive && selectedScoreType === 'start' && scaleObj}>
              <LiveLongSVG />
            </motion.div>
            {t('gameContainer.start')}
          </div>
        ),
        advanced: (
          <div className={styles.selectDifficultyWrapper}>
            <motion.div transition={transitionObj} animate={withActive && selectedScoreType === 'advanced' && scaleObj}>
              <LiveAdvanceSVG />
            </motion.div>
            {t('gameContainer.advanced')}
          </div>
        ),
        god: (
          <div className={styles.selectDifficultyWrapper}>
            <motion.div transition={transitionObj} animate={withActive && selectedScoreType === 'god' && scaleObj}>
              <LiveJesusSVG />
            </motion.div>
            {t('gameContainer.god')}
          </div>
        )
      };
    },
    [t, selectedScoreType]
  );

  const onChangeGameDifficulty = useCallback(
    item => {
      dispatch(SET_SCORE_LIVES_MODE(item));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('gameScorePage.selectGameType')}</p>
      <DropdownButton
        onSelect={onChangeGameDifficulty}
        id="dropdown-basic-button"
        variant="secondary"
        title={dropDownList(true)[selectedScoreType]}
      >
        <Dropdown.Item eventKey={GAME_LIVE_NAME_LONG}>{dropDownList().start}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey={GAME_LIVE_NAME_MEDIUM}>{dropDownList().advanced}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey={GAME_LIVE_NAME_SHORT}>{dropDownList().god}</Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default ScoreTypeSelector;
