import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IContactCardProps } from './ContactCard.types';
import styles from './ContactCard.module.css';

export const ContactCard: React.FC<IContactCardProps> = ({ title, text1, text2, link }: IContactCardProps) => {
  const { t } = useTranslation();

  return (
    <Jumbotron className={styles.contactItem}>
      <div>
        <div className={styles.contactTitle}>{t(title)}</div>
        <p className={styles.contactText}>{t(text1)}</p>
      </div>
      <a className={styles.contactFooter} rel="noopener noreferrer" target="_blank" href={link}>
        {t(text2)}
      </a>
    </Jumbotron>
  );
};
