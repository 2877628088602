import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { IPaymentMethodProps } from './PaymentMethod.types';
import styles from './PaymentMethod.module.css';

const PaymentMethod: React.FC<IPaymentMethodProps> = ({
  type,
  subtype,
  title,
  choicePaymentMethod,
  img,
  id,
  isActive
}: IPaymentMethodProps) => {
  const handleClick = () => {
    choicePaymentMethod({ id, type, subtype });
  };

  return (
    <ListGroup.Item onClick={handleClick} active={isActive}>
      <div className={styles.paymentImgBox}>
        <img src={img} alt={`${type} icon`} />
      </div>
      {title}
    </ListGroup.Item>
  );
};

export default PaymentMethod;
