import useReactRouter from 'use-react-router';
import { DEMO_PATH_LIST } from '../constants';

export interface IPageStates {
  isDemo: boolean;
}

export default function usePageStates(): IPageStates {
  const { location } = useReactRouter();
  const isDemo = DEMO_PATH_LIST.some(path => location.pathname.includes(path));

  return {
    isDemo
  };
}
