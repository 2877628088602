/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';

import styles from './AutoTheoriepruefungLernenPage.module.css';

const AutoTheoriepruefungLernenPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1>Für die Auto Theorieprüfung lernen mit »theorie24« – sicher und effizient</h1>

        <img
          className={styles.mainImage}
          src="/content/tour/fragen.png"
          alt="Auto Theorieprüfung lernen – theorie24-App - Theorieprüfung"
          width="400"
          height="250"
        />
        <p>
          Für die Auto Theorieprüfung zu lernen kann ganz schön anstrengend sein, wenn man die falschen Hilfsmittel
          einsetzt. Wohl dem, der auf eine moderne Lern-App wie »theorie24« zurückgreifen kann: Die bewährte
          Lernsoftware bietet einen hohen Bedienkomfort und ermöglicht es dir, mit den Original-Fragen des
          Strassenverkehrsamtes zu arbeiten. So hast du eine gute Chance, den Theorie-Test <b>gleich beim ersten Mal</b>{' '}
          erfolgreich hinter dich zu bringen!
        </p>

        <h2>Effizient für die Auto Theorieprüfung lernen – auf allen Endgeräten</h2>

        <p>
          Wenn du »theorie24« zur Prüfungsvorbereitung einsetzt, geniesst du volle Flexibilität – denn unsere beliebte
          Lern-App läuft auf allen Endgeräten und muss nicht einmal installiert werden. Du erhältst einen Online-Zugang,
          der es dir ermöglicht, <b>dich auf jedem beliebigen Endgerät anzumelden</b>. So kannst du jederzeit lernen und
          verschwendest keine Zeit mehr. In Leerlaufphasen holst du einfach dein Tablet oder Smartphone hervor und
          beschäftigst dich mit dem Lernstoff.
        </p>

        <h3>Volle Kostenkontrolle – jederzeit verlängerbar</h3>

        <p>
          Mit »theorie24« hast du <b>jederzeit die volle Kostenkontrolle</b>. Du zahlst einen festen Betrag und kannst
          danach für 365 Tage online für deine Auto Theorieprüfung lernen. Versteckte Kosten oder sonstige
          Zusatzgebühren gibt es bei uns nicht. Das folgende Video zeigt dir, wie du dich auf unserer Seite
          registrierst.
        </p>

        <iframe
          title="Iframe"
          className={styles.video}
          width="645"
          height="483"
          src="https://www.youtube.com/embed/-3xDpPKLJ44"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <h3>Optimale Vorbereitung mit den Original-Prüfungsfragen</h3>

        <p>
          Einer der grössten Vorteile der Lern-App »theorie24« ist, dass du auf die{' '}
          <b>Original-Prüfungsfragen des Strassenverkehrsamtes</b> zugreifen kannst. Dies gibt dir zusätzliche
          Sicherheit und ermöglicht eine punktgenaue Vorbereitung auf den obligatorischen Theorie-Test. Als offizieller
          Partner der asa werden wir sofort informiert, falls sich Änderungen am Fragenkatalog ergeben. In diesem Fall
          wird die Lern-App aktualisiert, damit du noch effizienter für deine Auto Theorieprüfung lernen kannst.
        </p>

        <h3>Jetzt einen Fahrschul-Gutschein sichern!</h3>

        <p>
          Im Zuge der Anmeldung erhältst du einen Gutschein für deine erste Fahrstunde. Klicke einfach{' '}
          <LinkWrapper to="/school">auf diesen Link</LinkWrapper> und gib einen Ortsnamen oder eine Postleitzahl ein.
          Unser Fahrschul-Finder zeigt dir eine Liste mit allen verfügbaren Fahrschulen in deiner Nähe an. Der
          Fahrschul-Gutschein hat einen Wert von <b>bis zu CHF 100</b>. Beachte, dass die Abgabe des Gutscheins auf
          freiwilliger Basis erfolgt – nicht jede Fahrschule nimmt an dieser Aktion teil.
        </p>

        <h3>In 9 Sprachen für die Auto Theorieprüfung lernen</h3>

        <p>
          Deutsch ist nicht deine Muttersprache? Kein Problem: Mit »theorie24« kannst du in insgesamt <b>9 Sprachen</b>{' '}
          für die Auto Theorieprüfung lernen. Derzeit bieten wir folgende Sprachen an:
        </p>

        <ul>
          <li>Deutsch</li>
          <li>Französisch</li>
          <li>Portugiesisch</li>
          <li>Englisch</li>
          <li>Türkisch</li>
          <li>Serbo-Kroatisch</li>
          <li>Albanisch</li>
          <li>Italienisch</li>
          <li>Spanisch</li>
        </ul>

        <p>
          Die Sprache lässt sich innerhalb der App problemlos ändern. Beachte jedoch Folgendes: Du kannst zwar in deiner
          Muttersprache für die Auto Theorieprüfung lernen – absolvieren musst du die Prüfung allerdings{' '}
          <b>in einer der drei Landessprachen</b>. Bereits seit dem 01. Januar 2008 wird die Theorieprüfung{' '}
          <a href="https://www.20min.ch/story/man-faehrt-deutsch-221561181855">
            nur noch auf Deutsch, Französisch und Italienisch (teilweise auch auf Englisch) angeboten
          </a>
          .
        </p>

        <img
          className={styles.mainImage}
          src="/content/tour/theorie.png"
          alt="Auto Theorieprüfung lernen – theorie24-App - Basistheorie"
          width="400"
          height="250"
        />
        <h3>Jetzt registrieren!</h3>

        <p>
          Verliere keine Zeit und registriere dich noch heute! Du kannst sofort damit beginnen, online für die Auto
          Theorieprüfung zu lernen. Von der Effizienz unserer beliebten Lern-App wirst du mit Sicherheit begeistert
          sein. Du kannst sogar Probeprüfungen absolvieren und dir den Lernstoff vorlesen lassen.{' '}
          <b>
            <LinkWrapper to="/offers">Jetzt starten!</LinkWrapper>
          </b>
        </p>
      </Container>
    </div>
  );
};

export default AutoTheoriepruefungLernenPage;
