import { put, select, takeEvery } from 'redux-saga/effects';
import { GET_CATEGORY_FAILURE, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS } from '../actions/category.action';
import { IRootState } from '../reducer';

const getLogged = ({ login }: IRootState): boolean => login.isLogged;

function* getCategories() {
  try {
    const categoryDemo = {
      'A,A1': {
        allowedErrorPoints: 6,
        maximumPoints: 60,
        period: 18,
        questionsCount: 20
      },
      B: {
        allowedErrorPoints: 6,
        maximumPoints: 60,
        period: 18,
        questionsCount: 20
      },
      'F,G': {
        allowedErrorPoints: 12,
        maximumPoints: 120,
        period: 45,
        questionsCount: 40
      },
      M: {
        allowedErrorPoints: 9,
        maximumPoints: 90,
        period: 45,
        questionsCount: 30
      }
    };

    const categoryForLoggedIn = {
      'A,A1': {
        allowedErrorPoints: 15,
        maximumPoints: 150,
        period: 45,
        questionsCount: 50
      },
      B: {
        allowedErrorPoints: 15,
        maximumPoints: 150,
        period: 45,
        questionsCount: 50
      },
      'F,G': {
        allowedErrorPoints: 12,
        maximumPoints: 120,
        period: 45,
        questionsCount: 40
      },
      M: {
        allowedErrorPoints: 9,
        maximumPoints: 90,
        period: 45,
        questionsCount: 30
      }
    };
    const isLogged = yield select(getLogged);

    const category = isLogged ? categoryForLoggedIn : categoryDemo;

    yield put(GET_CATEGORY_SUCCESS({ category }));
  } catch (e) {
    yield put(GET_CATEGORY_FAILURE(e.message));
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORY_REQUEST, getCategories);
}

export default categorySaga;
