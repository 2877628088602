import { IQuestion } from '../reducer/question.types';

export const getExamImageSrc = (imgKey: string): string => `/content/images/${imgKey}.jpg`;

export const getQuestionImageSrc = (question: IQuestion): string => getExamImageSrc(question.img);

export const getQuestionVideoSrc = (question: IQuestion, showUiResult?: boolean): string =>
  `/content/videos/${question.img.replace('_v', '')}${showUiResult ? 'b' : 'a'}.mp4?raw=true`;

export const isQuestionHasVideoSrc = (question: IQuestion): boolean => {
  if (question.category !== 'BAA1') {
    return false;
  }

  if (question.id === 30036) {
    return false;
  }

  if (question.id === 31087) {
    return false;
  }

  if (question.id < 30000) {
    return false;
  }

  if (question.id >= 40000) {
    return false;
  }

  return !question.img.includes('_v');
};

export const prefetchQuestionsMediaSources = (questions: IQuestion[]) => {
  questions
    .filter(Boolean)
    .filter(item => item.img)
    .forEach(question => {
      if (isQuestionHasVideoSrc(question)) {
        const media = document.createElement('video');
        media.src = getQuestionVideoSrc(question);
      } else {
        const media = new Image();
        media.src = getQuestionImageSrc(question);
      }
    });
};
