import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../../ui-components/PageTitle/PageTitle';
import { imageAbsolutePath } from '../../../../utils/utils';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import styles from './TitleSection.module.css';

const allDeviceDe: string = imageAbsolutePath('offers/offers-main-image-de.png');
const allDeviceFr: string = imageAbsolutePath('offers/offers-main-image-fr.png');
const allDeviceEn: string = imageAbsolutePath('offers/offers-main-image-en.png');
const allDeviceIt: string = imageAbsolutePath('offers/offers-main-image-it.png');

const OFFERS_ALL_DEVICE_IMAGE_LIST = {
  de: { img: allDeviceDe },
  fr: { img: allDeviceFr },
  it: { img: allDeviceIt },
  en: { img: allDeviceEn }
};

const TitleSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <PageTitle className={styles.mainTitle}>{t('offersPageNew.title')}</PageTitle>
      <LocalesImage alt={t('offersPageNew.topImageAlt')} localesPath={OFFERS_ALL_DEVICE_IMAGE_LIST} />
    </section>
  );
};

export default TitleSection;
