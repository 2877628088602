import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImHeartBroken } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Counter from '../../../ui-components/Counter/Counter';
import { IRootState } from '../../../reducer';
import { usePrevious } from '../../../hooks/usePrevious';
import { getUniqId, randomInteger } from '../../../utils/utils';
import { EXTRA_SCORE_LIST } from '../../../constants';
import QuestionGameChoose from '../QuestionGameChoose/QuestionGameChoose';
import { AnswerObj } from '../../../reducer/game.types';
import { selectLanguage } from '../../../reducer/user.selectors';
import { UPDATE_EXTRA_SCORE, UPDATE_QUESTION_INDEX } from '../../../actions/game.action';
import CountProgressBar from '../CountProgressBar/CountProgressBar';
import { ReactComponent as HeartSVG } from '../../../img/game/heart.svg';
import { ITopControllerProps } from './TopController.types';
import styles from './TopController.module.css';

const TopController: React.FC<ITopControllerProps> = ({ isGameFinished, showExtraScore }: ITopControllerProps) => {
  const { t } = useTranslation();

  const { livesCount, score, currentQuestionIndex, sortedQuestionList, extraScore } = useSelector(
    ({ game }: IRootState) => game
  );

  const previousLivesCount = usePrevious(livesCount);
  const previousQuestionIndex = usePrevious(currentQuestionIndex);
  const previousScore = usePrevious(score);

  const [showHeartAnimation, setShowHeartAnimation] = useState(false);
  const [hearAnimationRefreshKey, setHearAnimationRefreshKey] = useState('0');
  const [scoreAnimationRefreshKey, setScoreAnimationRefreshKey] = useState('');
  const [newBounceScoreValue, setNewBounceScoreValue] = useState(0);
  const [newBounceCords, setNewBounceCords] = useState({ x: 0, y: 0, scale: 1 });
  const [progressBarValue, setProgressBarValue] = useState(100);

  const userAnswersList: AnswerObj[] = useSelector(({ game }: IRootState) => game.answerList);
  const currentLanguage = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const handleQuestionChange = useCallback(
    (index: number) => () => {
      // dispatch(UPDATE_QUESTION_INDEX(index - 1));
      dispatch(UPDATE_QUESTION_INDEX(index));
      // history.push(link);
    },
    [dispatch]
  );

  const handleProgressBarTick = useCallback(() => {
    if (progressBarValue === 0 && extraScore !== 0) {
      dispatch(UPDATE_EXTRA_SCORE(0));
      return null;
    }
    if (progressBarValue > 50 && extraScore !== EXTRA_SCORE_LIST[0]) {
      dispatch(UPDATE_EXTRA_SCORE(EXTRA_SCORE_LIST[0]));
    } else if (progressBarValue > 0 && progressBarValue <= 50 && extraScore !== EXTRA_SCORE_LIST[1]) {
      dispatch(UPDATE_EXTRA_SCORE(EXTRA_SCORE_LIST[1]));
    }
    return setProgressBarValue(progressBarValue - 10);
  }, [progressBarValue, extraScore, dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (previousLivesCount && previousLivesCount > livesCount) {
      setShowHeartAnimation(true);
      setHearAnimationRefreshKey(getUniqId());
    }
  }, [previousLivesCount, livesCount]);

  useEffect(() => {
    if (progressBarValue !== 100 && previousQuestionIndex !== currentQuestionIndex) {
      setProgressBarValue(100);
    }
  }, [currentQuestionIndex, progressBarValue, previousQuestionIndex]);

  useEffect(() => {
    if (previousScore !== score && score) {
      setNewBounceScoreValue(score - previousScore!);
      setScoreAnimationRefreshKey(getUniqId());
      setNewBounceCords({ x: randomInteger(-400, 400), y: randomInteger(-400, 400), scale: randomInteger(1, 3) });
    }
  }, [previousScore, score]);

  const renderLifeCount = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return [...Array(livesCount)].map((_, index) => {
      // eslint-disable-next-line react/no-array-index-key
      return <HeartSVG key={index} className={styles.defaultHeart} />;
    });
  }, [livesCount]);

  const extraScoreRender = useMemo(() => {
    const className = classNames(styles.extraPointContainer, 'retroColorChangeClass', {
      [styles.containerHide]: extraScore === 0
    });
    return (
      <div className={className}>
        {showExtraScore && (
          <CountProgressBar
            text={`${t('gamePage.extraPointsScore')} ${extraScore} `}
            value={progressBarValue}
            tick={handleProgressBarTick}
          />
        )}
      </div>
    );
  }, [t, extraScore, progressBarValue, handleProgressBarTick, showExtraScore]);

  const heartStyle = classNames(styles.loseHeart, styles.loseHeartActive);
  if (isGameFinished) {
    return (
      <div className={styles.headerCountBox}>
        <div className={styles.doubleCounter}>
          <Counter current={currentQuestionIndex! + 1} maxSize={sortedQuestionList.length} />
        </div>
        <QuestionGameChoose
          onQuestionChange={handleQuestionChange}
          iconCls={styles.questionBtnListItem}
          answerList={userAnswersList}
          defaultRoute={`/${currentLanguage}/game`}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.wrapper} retroColorChangeClass`}>
          <div className={`${styles.topLayer} retroColorChangeClass`}>
            <div className={styles.doubleCounter}>
              <span>{t('gamePage.progress')}</span>
              <Counter current={currentQuestionIndex + 1} maxSize={sortedQuestionList.length} />
            </div>
            <div className={`${styles.lifeContainer} retroColorChangeClass`}>
              <span>{t('gamePage.lives')}</span>
              <div className={styles.livesHeartContainer}>
                {renderLifeCount}
                <ImHeartBroken
                  key={hearAnimationRefreshKey}
                  className={showHeartAnimation ? heartStyle : styles.loseHeart}
                  color="red"
                  size={25}
                />
              </div>
            </div>
          </div>
          <div className={styles.bottomLayer}>
            <div className={styles.scoreWrapper}>
              <span>
                {t('gamePage.yourScore')}
                &nbsp;
              </span>
              <span>{score}</span>
              {scoreAnimationRefreshKey && (
                <motion.div
                  key={scoreAnimationRefreshKey}
                  className={styles.bounceScore}
                  animate={{
                    opacity: [1, 1, 0],

                    x: [newBounceCords.x, 0, 0],
                    y: [newBounceCords.y, 0, 0],
                    scale: newBounceCords.scale
                  }}
                  transition={{ duration: [1], times: [0, 0.8, 1] }}
                >
                  {`+ ${newBounceScoreValue}`}
                </motion.div>
              )}
            </div>
            {extraScoreRender}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TopController);
