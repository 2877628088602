import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContentTitle } from '../../ui-components/PageContentTitle/PageContentTitle';
import Teacher3dCarousel from '../../components/Teacher3dCarousel/Teacher3dCarousel';

const VIDEO_LIST = [
  { src: '/content/3dteacher/1_1.mp4', text: 'preludeWithoutSignaling.text_1_1' },
  { src: '/content/3dteacher/1_2.mp4', text: 'preludeWithoutSignaling.text_1_2' },
  { src: '/content/3dteacher/1_3.mp4', text: 'preludeWithoutSignaling.text_1_3' },
  { src: '/content/3dteacher/1_4.mp4', text: 'preludeWithoutSignaling.text_1_4' },
  { src: '/content/3dteacher/1_5.mp4', text: 'preludeWithoutSignaling.text_1_5' },
  { src: '/content/3dteacher/1_6.mp4', text: 'preludeWithoutSignaling.text_1_6' },
  { src: '/content/3dteacher/1_7.mp4', text: 'preludeWithoutSignaling.text_1_7' }
];

const PreludeWithoutSignaling: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContentTitle>{t('preludeWithoutSignaling.title')}</PageContentTitle>
      <Teacher3dCarousel srcArray={VIDEO_LIST} />
    </>
  );
};

export default PreludeWithoutSignaling;
