import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { ITickListItemProps } from './TickListItem.types';
import { LinkWrapper } from '../LinkWrapper/LinkWrapper';

const TickListItem: React.FC<ITickListItemProps> = forwardRef(
  ({ text, description, withDote = false, appLink }: ITickListItemProps, ref?: React.Ref<HTMLLIElement>) => {
    const icon = withDote ? faCircle : faCheck;

    if (appLink) {
      return (
        <LinkWrapper to={appLink}>
          <div>
            <FontAwesomeIcon icon={icon} size="lg" />
            {text}
          </div>
          {description && <span>{description}</span>}
        </LinkWrapper>
      );
    }
    return (
      <li ref={ref}>
        <div>
          <FontAwesomeIcon icon={icon} size="lg" />
          {text}
        </div>
        {description && <span>{description}</span>}
      </li>
    );
  }
);

export const MTickListItem = motion(TickListItem);

export default TickListItem;
