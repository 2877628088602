import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { IMarketProps } from './Market.types';
import styles from './Market.module.css';

const Market: React.FC<IMarketProps> = forwardRef(({ src, alt }: IMarketProps, ref?: React.Ref<HTMLAnchorElement>) => (
  <a
    ref={ref}
    className={styles.storeBtnItem}
    rel="noopener noreferrer"
    target="_blank"
    href={
      alt === 'google'
        ? 'https://play.google.com/store/apps/details?id=com.theorie24.app'
        : 'https://apps.apple.com/ch/app/theorie24-ch-theoriepr%C3%BCfung/id464801879'
    }
  >
    <img src={src} alt={alt} />
  </a>
));

export const MMarket = motion(Market);

export default Market;
