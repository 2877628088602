import React from 'react';
import { useTranslation } from 'react-i18next';
import Market from '../../../../components/Market/Market';
import { imageAbsolutePath } from '../../../../utils/utils';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import styles from './AppSection.module.css';

const google = imageAbsolutePath('googleBtn.svg');
const apple = imageAbsolutePath('appleBtn.svg');

const phoneDe: string = imageAbsolutePath('homePage/phone-de.png');
const phoneEn: string = imageAbsolutePath('homePage/phone-en.png');
const phoneFr: string = imageAbsolutePath('homePage/phone-fr.png');
const phoneIt: string = imageAbsolutePath('homePage/phone-it.png');

const IMAGE_LIST = {
  de: { img: phoneDe },
  fr: { img: phoneFr },
  it: { img: phoneIt },
  en: { img: phoneEn }
};

const AppSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <h5>{t('homePageNew.appSection.title')}</h5>

          <p>{t('homePageNew.appSection.description')}</p>

          <div className={styles.buttonWrapper}>
            <Market src={google} alt="google" />
            <Market src={apple} alt="apple" />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <LocalesImage
            alt={t('homePageNew.appSection.phoneImageAlt')}
            className={styles.phoneImage}
            localesPath={IMAGE_LIST}
          />
        </div>
      </div>
    </section>
  );
};

export default AppSection;
