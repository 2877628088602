import React, { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import { ISaleItemProps } from './Saleitem.types';
import { LoaderWrapper } from '../../../../ui-components/LoaderWrapper/LoaderWrapper';
import styles from './SaleItem.module.css';
import MoreInfoOfferLink from '../../../../components/MoreInfoOfferLink/MoreInfoOfferLink';

export const SaleItem: FC<ISaleItemProps> = forwardRef(
  (
    { text, price, description, icons, categoryText, linkUrl, isLoading = false }: ISaleItemProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    const contentClass = classNames(styles.content, {
      [styles.loaderContent]: isLoading
    });

    return (
      <div ref={ref} className={styles.container}>
        <div className={contentClass}>
          <LoaderWrapper absoluteCenter show={isLoading}>
            <div className={styles.topWrapper}>
              <div className={styles.iconWrapper}>{icons}</div>
              <p>{`${text} ${t('homePageNew.callToActionSection.days')}`}</p>
              <p>{price}</p>
            </div>
            {description && <p className={styles.description}>{description}</p>}
          </LoaderWrapper>
          <p className={styles.categoryText}>{categoryText}</p>
        </div>

        <div className={styles.buttonWrapper}>
          <LinkWrapper className="btn btn-primary" to={linkUrl}>
            {t('homePageNew.btn.buyNow')}
          </LinkWrapper>
          <div className={styles.wave} />
        </div>

        <MoreInfoOfferLink />
      </div>
    );
  }
);

export const MSaleItem = motion(SaleItem);

export default SaleItem;
