import React from 'react';
import { Table } from 'react-bootstrap';
import { ICommonTableComponentProps, ICommonTableProps } from './CommonTable.types';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import styles from './CommonTable.module.css';

const CommonTable: React.FC<ICommonTableProps> & ICommonTableComponentProps = ({
  tableProps,
  renderEmptyText,
  emptyText,
  children
}: ICommonTableProps) => {
  if (renderEmptyText) {
    return (
      <div className={styles.noResultContainer}>
        <p>{emptyText}</p>
      </div>
    );
  }

  return (
    <Table className={styles.tableContainer} {...tableProps}>
      {children}
    </Table>
  );
};

CommonTable.Header = Header;
CommonTable.Body = Body;
export { CommonTable };
