import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Jumbotron } from 'react-bootstrap';
import AnimatedNumber from 'animated-number-react';
import classNames from 'classnames';
import { LoaderWrapper } from '../../../ui-components/LoaderWrapper/LoaderWrapper';
import { IScoreCardProps } from './ScoreCard.types';
import styles from './ScoreCard.module.css';

const ScoreCard: React.FC<IScoreCardProps> = ({
  score,
  text,
  isLoading,
  dontHaveScoreText,
  className = ''
}: IScoreCardProps) => {
  const { t } = useTranslation();

  const formatValue = useCallback(value => value.toFixed(0), []);

  const renderScore = useMemo(() => {
    if (score === undefined) {
      return <p>{dontHaveScoreText}</p>;
    }
    return (
      <>
        <div className={styles.scoreValue}>
          <AnimatedNumber value={score} formatValue={formatValue} delay={300} duration={1500} />
        </div>
        <div className={styles.scoreText}>{t('gameScorePage.points')}</div>
      </>
    );
  }, [score, formatValue, dontHaveScoreText, t]);
  const containerClass = useMemo(() => classNames(styles.scoreContainerr, className), [className]);

  return (
    <>
      <Jumbotron className={containerClass}>
        <div className={styles.scoreTitle}>{text}</div>
        <LoaderWrapper show={isLoading} classStyles={styles.loaderWrapper}>
          <div className={styles.scoreContent}>{renderScore}</div>
        </LoaderWrapper>
      </Jumbotron>
    </>
  );
};

export default React.memo(ScoreCard);
