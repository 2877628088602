import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../reducer';
import { GET_THEORY_REQUEST, CHANGE_THEORY_MATCH_PARAMS } from '../actions/theory.action';
import { TheoryBook } from '../components/TheoryBook/TheoryBook';
import { ITheoryBookProps } from './TheoryBookContainer.types';
import { Loader } from '../ui-components/Loader/Loader';
import { RequestStatusType } from '../reducer/fetch.types';
import { UNCALLED, PENDING } from '../constants/store.constants';
import { selectLanguage } from '../reducer/user.selectors';
import styles from './TheoryBookContainer.module.css';

const TheoryBookContainer: React.FC<ITheoryBookProps> = ({ page, inModal, pathName }: ITheoryBookProps) => {
  const dispatch = useDispatch();
  const pageStore = useSelector(({ theory }: IRootState) => theory.page);
  const pageChapters = useSelector(({ theory }: IRootState) => theory.theory.pageChapters);
  const groupPages = useSelector(({ theory }: IRootState) => theory.theory.groupPages);
  const language = useSelector(selectLanguage);
  const requestStatus: RequestStatusType = useSelector(({ theory }: IRootState) => theory.theoryRequest.status);

  useEffect(() => {
    dispatch(GET_THEORY_REQUEST({ language }));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(CHANGE_THEORY_MATCH_PARAMS({ page }));
  }, [dispatch, page]);

  if (requestStatus === 'failure') {
    return <div>Error</div>;
  }

  return (
    <div className={styles.glossaryContent}>
      <Loader show={requestStatus === PENDING || requestStatus === UNCALLED} />
      <TheoryBook
        pathName={pathName}
        inModal={inModal}
        groupPages={groupPages}
        page={pageStore}
        pageChapters={pageChapters}
        currentLanguage={language}
      />
    </div>
  );
};

export default TheoryBookContainer;
