import React from 'react';
import { Player, BigPlayButton } from 'video-react';
import { isMobileSafari } from 'react-device-detect';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITeacher3dCarouselProps } from './Teacher3dCarousel.types';
import poster from '../../img/poster.svg';
import styles from './Teacher3dCarousel.module.css';
import { P3 } from '../../ui-components/P3/P3';

const SLIDE_INTERVAL = 30 * 1000;

const Teacher3dCarousel: React.FC<ITeacher3dCarouselProps> = ({ srcArray }: ITeacher3dCarouselProps) => {
  const { t } = useTranslation();

  return (
    <Carousel interval={SLIDE_INTERVAL}>
      {srcArray.map(item => {
        return (
          <Carousel.Item key={item.src}>
            <P3>{t(`${item.text}`)}</P3>
            <div className={styles.videoContainer}>
              <Player
                playsInline
                muted
                poster={isMobileSafari ? poster : undefined}
                src={item.src}
                className={styles.responsiveVideo}
              >
                <BigPlayButton position="center" />
              </Player>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default React.memo(Teacher3dCarousel);
