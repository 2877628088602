import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';
import { IProgressPieChartProps } from './ProgressPieChart.types';
import styles from './ProgressPieChart.module.css';

const COLORS = ['rgb(91, 225, 44)', 'rgb(234, 66, 40)'];

const ProgressPieChart: React.FC<IProgressPieChartProps> = ({
  correctAnswerCount,
  incorrectAnswerCount
}: IProgressPieChartProps) => {
  const { t } = useTranslation();
  const data = [
    { name: t('questionsByTopicContainer.diagram.answeredCorrect'), value: correctAnswerCount },
    { name: t('questionsByTopicContainer.diagram.answerIncorrect'), value: incorrectAnswerCount }
  ];

  const successPercent = correctAnswerCount
    ? (correctAnswerCount * 100) / (correctAnswerCount + incorrectAnswerCount! || 0)
    : 0;
  return (
    <div className={styles.container}>
      <PieChart className={styles.chartContainer} width={130} height={100}>
        <Pie
          data={data}
          cx={60}
          cy={50}
          labelLine={false}
          // label={renderCustomizedLabel}
          outerRadius={45}
          innerRadius={33}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div className={styles.percentInCenter}>{`${Number(successPercent).toFixed(0)}%`}</div>
    </div>
  );
};

export default ProgressPieChart;
