import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router';
import SchoolInfoContainer from '../../containers/SchoolInfoContainer/SchoolInfoContainer';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './DrivingSchoolInfoPage.module.css';

const DrivingSchoolInfoPage: React.FC<RouteChildrenProps> = (props: RouteChildrenProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.schoolInfoPage}>
      <Container>
        <Row className={styles.navRowContainer}>
          <Col lg={11}>
            <LinkWrapper className={styles.linkBtn} to="/school">
              <i className="icon-arrow" />
              {t('drivingSchoolPage.infoPage.link')}
            </LinkWrapper>
            <SchoolInfoContainer {...props} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DrivingSchoolInfoPage;
