import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './HomeCarouselMobileTCS.module.css';

const germany = imageAbsolutePath('lang/Germany.svg');
const france = imageAbsolutePath('lang/France.svg');
const italy = imageAbsolutePath('lang/Italy.svg');
const spain = imageAbsolutePath('lang/Spain.svg');
const uk = imageAbsolutePath('lang/UK.svg');
const turkey = imageAbsolutePath('lang/Turkey.svg');
const portugal = imageAbsolutePath('lang/Portugal.svg');
const croatia = imageAbsolutePath('lang/Croatia.svg');
const albania = imageAbsolutePath('lang/Albania.svg');

const HomeCarouselMobileTCS: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileSlider}>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('TCSPage.slides.fromCHF.title')}</div>
        <p className={styles.sliderText}>{t('TCSPage.slides.fromCHF.text')}</p>
      </div>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('TCSPage.slides.everythingLogin.title')}</div>
        <p className={styles.sliderText}>{t('TCSPage.slides.everythingLogin.text')}</p>
      </div>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('TCSPage.slides.asaQuestions.title')}</div>
        <p className={styles.sliderText}>{t('TCSPage.slides.asaQuestions.text')}</p>
      </div>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('TCSPage.slides.lngRules.title')}</div>
        <p className={styles.sliderText}>{t('TCSPage.slides.lngRules.textLng')}</p>
        <ul className={styles.langList}>
          <li>
            <img src={germany} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={france} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={italy} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={uk} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={spain} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={turkey} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={portugal} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={croatia} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={albania} width={48} height={48} alt="lang" />
          </li>
        </ul>
        <p className={styles.sliderText}>{t('TCSPage.slides.lngRules.textRules')}</p>
        <ul className={styles.langList}>
          <li>
            <img src={germany} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={france} width={48} height={48} alt="lang" />
          </li>
          <li>
            <img src={italy} width={48} height={48} alt="lang" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeCarouselMobileTCS;
