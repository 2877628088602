import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GET_INVOICE_REQUEST } from '../../actions/invoice.action';
import { selectInvoice } from '../../reducer/invoice.selectors';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { IInvoiceState } from '../../reducer/invoice.types';
import { IInvoicePageProps } from './InvoicePage.types';
import { FAILURE, PENDING, SUCCESS, UNCALLED } from '../../constants/store.constants';

const InvoicePage: React.FC<IInvoicePageProps> = ({ match }: IInvoicePageProps) => {
  const dispatch = useDispatch();
  const { invoice, invoiceRequest }: IInvoiceState = useSelector(selectInvoice);

  const { t } = useTranslation();

  const { studentid, invoiceid } = match.params;

  useEffect(() => {
    if (invoiceRequest.status === UNCALLED) {
      dispatch(
        GET_INVOICE_REQUEST({
          studentid,
          invoiceid
        })
      );
    }
  }, [dispatch, invoiceRequest, studentid, invoiceid]);

  const createMarkup = htmlString => ({ __html: htmlString });

  return (
    <>
      <Container>
        <Row>
          <Col lg={5}>
            <PageTitle>{t('invoicePage.invoice')}</PageTitle>
            <div>{t('invoicePage.thanks')}</div>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            {[PENDING, FAILURE].includes(invoiceRequest.status) && invoice === null && <div>...</div>}
            {invoiceRequest.status === SUCCESS && invoice && (
              <div>
                <div>{t('invoicePage.transfer', { amount: invoice.amount })}</div>
                <div>
                  <b>{invoice.iban}</b>
                </div>
                <br />
                <div dangerouslySetInnerHTML={createMarkup(invoice.address)} />
                <br />
                <div>
                  <b>{t('invoicePage.notforget', { studentid: invoice.studentid })}</b>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvoicePage;
