import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import QuestionLinkList from '../QustionLinkList/QustionLinkList';
import { IRootState } from '../../reducer';
import { IQuestionChooseModalContainerProps } from './QuestionChooseModalContainer.types';
import { CLEAR_ANSWER_BY_TOPIC_REQUEST, CLEAR_MARKED_ANSWER_LIST_REQUEST } from '../../actions/answer.action';
import { GET_QUESTION_REQUEST } from '../../actions/question.action';
import { Request } from '../../reducer/fetch.types';
import { PENDING } from '../../constants/store.constants';
import { Loader } from '../../ui-components/Loader/Loader';
import { getUsersMarkedQuestions } from '../../selectors/user.selectors';

const QuestionChooseModalContainer: React.FC<IQuestionChooseModalContainerProps> = ({
  onHide,
  topicId,
  onQuestionChange,
  isExam,
  finishedExam,
  defaultRoute,
  questionList,
  removeTopicIdByDefault,
  topicOpen,
  show
}: IQuestionChooseModalContainerProps) => {
  const { t } = useTranslation();
  const questionListRequest: Request = useSelector(({ question }: IRootState) => question.questionListRequest);
  const dispatch = useDispatch();
  const markedQuestions = useSelector(getUsersMarkedQuestions);
  const {
    match: {
      params: { topic }
    }
  } = useReactRouter();

  const selectedTopic = topicId || topicId === 0 ? topicId : topic;

  useEffect(() => {
    if ((typeof topicId === 'number' || topicId === 'all') && show && !topicOpen) {
      dispatch(GET_QUESTION_REQUEST({ topic: topicId }));
    } else if (topicId === 'marked' && show && !topicOpen) {
      dispatch(GET_QUESTION_REQUEST({ topic: 'marked' }));
    }
  }, [dispatch, questionList, topicId, show, topicOpen]);

  const handleAnswerClear = useCallback(() => {
    if (selectedTopic === 'marked') {
      dispatch(CLEAR_MARKED_ANSWER_LIST_REQUEST({ markedQuestions }));
    } else {
      dispatch(CLEAR_ANSWER_BY_TOPIC_REQUEST({ topic: selectedTopic || removeTopicIdByDefault }));
    }
  }, [dispatch, selectedTopic, markedQuestions, removeTopicIdByDefault]);

  return (
    <>
      <Loader show={show && questionListRequest.status === PENDING} />

      <Modal
        className="modalQuestion"
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onHide}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <QuestionLinkList
            defaultRoute={defaultRoute}
            onQuestionChange={onQuestionChange}
            finishedExam={finishedExam}
            isExam={isExam}
            questionList={questionList}
          />
        </Modal.Body>
        <Modal.Footer>
          {!isExam && (
            <Button variant="secondary" onClick={handleAnswerClear}>
              {t('questionModal.btn')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionChooseModalContainer;
