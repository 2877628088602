import React from 'react';
import classNames from 'classnames';
import { ILoaderProps } from './Loader.types';
import styles from './Loader.module.css';

export const Loader: React.FC<ILoaderProps> = ({ show, inBlock }: ILoaderProps) => {
  return (
    <div className={classNames(styles.loader, { [styles.showLoader]: show, [styles.blockLoader]: inBlock })}>
      <i className="icon-driving-school" />
    </div>
  );
};
