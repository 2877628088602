import { string, object, boolean } from 'yup';

const NAME_REG_EXP = /^[a-zA-Z-\u0080-\uFFFF\s]{2,}$/;
const ADDRESS_REG_EXP = /^[a-zA-Z0-9\u0080-\uFFFF][a-zA-Z0-9\u0080-\uFFFF.\-\s/\\+|#@'°]+$/;
const ZIP_REG_EXP = /^[1-9]\d{3}$/;
const CITY_REG_EXP = /^[a-zA-Z\u0080-\uFFFF][a-zA-Z0-9\u0080-\uFFFF,.\-\s/\\+|#@]+$/;
const PHONE_REG_EXP = /^(?=.*[0-9])[ +0-9]+$/;

export const registerFormSchema = (requiredMessage, invalidMessage) =>
  object().shape({
    title: string().required(requiredMessage),
    firstname: string()
      .required(requiredMessage)
      .matches(NAME_REG_EXP, invalidMessage),
    lastname: string()
      .required(requiredMessage)
      .matches(NAME_REG_EXP, invalidMessage),
    email: string()
      .email(invalidMessage)
      .required(requiredMessage),
    // eslint-disable-next-line func-names
    confirmEmail: string().test('confirmEmail', invalidMessage, function() {
      return this.parent.email === this.parent.confirmEmail;
    }),
    address: string()
      .required(requiredMessage)
      .matches(ADDRESS_REG_EXP, invalidMessage),
    city: string()
      .required(requiredMessage)
      .matches(CITY_REG_EXP, invalidMessage),
    phone: string()
      .required(requiredMessage)
      .matches(PHONE_REG_EXP, invalidMessage),
    zip: string()
      .required(requiredMessage)
      .matches(ZIP_REG_EXP, invalidMessage),
    toc: boolean().oneOf([true], requiredMessage)
  });
