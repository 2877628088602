import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CategoryIcon from '../CategoryIcon/CategoryIcon';
import { IPromoContentTCSProps } from './PromoContentTCS.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { imageAbsolutePath, TCSLinkGenerator } from '../../utils/utils';
import PromoIconTCS from '../PromoIconTCS/PromoIconTCS';
import HomeCarouselTCS from '../HomeCarouselTCS/HomeCarouselTCS';
import styles from './PromoContentTCS.module.css';

const rulesImage = imageAbsolutePath('key-visual.jpeg');

const CategoryIconBar = (
  <div className="categoryIconBarHomeTCS">
    <CategoryIcon icon="icon-car" level="B" badge={false} />
    <CategoryIcon icon="icon-motorcycle" level="A, A1" badge={false} />
    <CategoryIcon icon="icon-tractor" level="F, G" badge={false} />
    <CategoryIcon icon="icon-e-bike" level="M" badge />
  </div>
);

const PromoContent: React.FC<IPromoContentTCSProps> = ({
  onLoginClick,
  onLogoutClick,
  isLogged,
  currentLanguage
}: IPromoContentTCSProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.promoContent}>
      <div className={styles.promoItem}>
        <div className={styles.rules}>
          <img className={styles.rulesImg} src={rulesImage} alt="banner" />
          <div className={styles.rulesList}>{CategoryIconBar}</div>
        </div>
      </div>
      <div className={styles.promoItem}>
        <PageTitle>{t('TCSPage.learnTheoryTitle')}</PageTitle>
        <p className={styles.subtitle}>{t('TCSPage.subtitle')}</p>
        <div className={styles.iconsList}>
          <PromoIconTCS src="2" currentLanguage={currentLanguage} alt="badge" />
        </div>
        <div className={styles.rulesListMobile}>{CategoryIconBar}</div>
        <HomeCarouselTCS />
        {isLogged ? (
          <div className={styles.btnList}>
            <Button className={styles.loginBtnMobile} variant="secondary" onClick={onLogoutClick}>
              {t('app.btn.logout')}
            </Button>
          </div>
        ) : (
          <div className={styles.btnList}>
            <Button className={styles.loginBtnMobile} variant="secondary" onClick={onLoginClick}>
              {t('app.btn.login')}
            </Button>
            <a className="btn btn-primary" href={TCSLinkGenerator('vipcode', currentLanguage)}>
              {t('TCSPage.btn.deals')}
            </a>
            <a
              className="btn btn-outline-primary"
              rel="noopener noreferrer"
              href={TCSLinkGenerator('mycockpit', currentLanguage)}
            >
              {t('TCSPage.btn.demo')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoContent;
