import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../reducer/user.selectors';
import AboutPage from '../../pages/AboutPage/AboutPage';

const AboutPageContainer: React.FC = () => {
  const currentLanguage = useSelector(selectLanguage);

  return <AboutPage currentLanguage={currentLanguage} />;
};

export default AboutPageContainer;
