import fetch from './fetch';
import { ISchoolApi, IGetSchoolParam, IGetSchoolCoordinatesApi } from './school.types';

export const getSchools = ({ search, page, limit }: IGetSchoolParam): ISchoolApi =>
  fetch('GET', '/driving-schools', { page, limit, search });

export const getSchool = (id: number) => fetch('GET', `/driving-school/${id}`);

export const getSchoolCoordinates = (): IGetSchoolCoordinatesApi => {
  return fetch('GET', '/driving-schools/locations');
};

export const setUserSchoolApi = (teacherId: number) => {
  return fetch('PATCH', '/customer', { teacherId });
};
