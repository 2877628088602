import React from 'react';
import { Modal } from 'react-bootstrap';
import { ITheoryModalProps } from './TheoryModal.types';
import TheoryBookContainer from '../../containers/TheoryBookContainer';
import styles from './TheoryModal.module.css';

const TheoryModal: React.FC<ITheoryModalProps> = ({ theoryId, show, onHide }: ITheoryModalProps) => (
  <Modal className={styles.theoryModal} show={show} onHide={onHide}>
    <Modal.Header closeButton />
    <Modal.Body>
      <TheoryBookContainer page={theoryId} inModal />
    </Modal.Body>
  </Modal>
);

export default TheoryModal;
