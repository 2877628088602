import React from 'react';
import { useTranslation } from 'react-i18next';
import { Jumbotron } from 'react-bootstrap';
import { IGameResultInfoProps } from './GameResultInfo.types';
import { PageTitle } from '../../../ui-components/PageTitle/PageTitle';
import styles from './GameResultInfo.module.css';

const GameResultInfo: React.FC<IGameResultInfoProps> = ({
  correctCountAnswer,
  questionCount,
  livesLeft,
  score
}: IGameResultInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.resultPageItem}>
      <PageTitle>{t('examResult.yourResult')}</PageTitle>
      <Jumbotron>
        <div className={styles.resultList}>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('gameScorePage.table.score')}</span>
            <div className={styles.resultValue}>{score}</div>
          </div>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('gameScorePage.table.livesLeft')}</span>
            <div className={styles.resultValue}>{livesLeft}</div>
          </div>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('gameScorePage.table.answers')}</span>
            <div className={styles.resultValue}>{`${correctCountAnswer}/${questionCount}`}</div>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
};

export default React.memo(GameResultInfo);
