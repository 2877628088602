import React from 'react';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './PromoIconTCS.module.css';
import { IPromoIconTCSProps } from './PromoIconTCS.types';

const vBest = imageAbsolutePath('front/best.svg');
const vTeacherDe = imageAbsolutePath('front/banner_de.png');
const vTeacherFr = imageAbsolutePath('front/banner_fr.png');
const vTeacherIt = imageAbsolutePath('front/banner_it.png');
const vTeacherEn = imageAbsolutePath('front/teacher_en.svg');
const vQuestionDe = imageAbsolutePath('front/question_de.svg');
const vQuestionFr = imageAbsolutePath('front/question_fr.svg');
const vQuestionIt = imageAbsolutePath('front/question_it.svg');
const vQuestionEn = imageAbsolutePath('front/question_en.svg');
const vMembershipEn = imageAbsolutePath('front/membership_de.png');
const vMembershipDe = imageAbsolutePath('front/membership_de.png');
const vMembershipFr = imageAbsolutePath('front/membership_fr.png');
const vMembershipIt = imageAbsolutePath('front/membership_it.png');

const SRCS = {
  de: {
    '1': vBest,
    '2': vTeacherDe,
    '3': vQuestionDe,
    '4': vMembershipDe
  },
  fr: {
    '1': vBest,
    '2': vTeacherFr,
    '3': vQuestionFr,
    '4': vMembershipFr
  },
  it: {
    '1': vBest,
    '2': vTeacherIt,
    '3': vQuestionIt,
    '4': vMembershipIt
  },
  en: {
    '1': vBest,
    '2': vTeacherEn,
    '3': vQuestionEn,
    '4': vMembershipEn
  }
};

const Icon: React.FC<IPromoIconTCSProps> = ({ src, alt, currentLanguage }: IPromoIconTCSProps) => {
  const srcPath = SRCS[currentLanguage][src] || SRCS.de;
  return (
    <div className={styles.iconsListItem}>
      <img src={srcPath} alt={alt} />
    </div>
  );
};

export default Icon;
