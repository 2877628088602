import React, { useCallback, useMemo } from 'react';
import AnimatedNumber from 'animated-number-react';
import { IScoreContainerProps } from './ScoreContainer.types';
import styles from './ScoreContainer.module.css';

const ScoreContainer: React.FC<IScoreContainerProps> = ({ score, dontHaveScoreText, delay = 300, duration = 1500 }) => {
  const formatValue = useCallback(value => value.toFixed(0), []);

  const renderScore = useMemo(() => {
    if (score === undefined) {
      return <p>{dontHaveScoreText}</p>;
    }
    return (
      <>
        <div className={styles.scoreValue}>
          <AnimatedNumber value={score} formatValue={formatValue} delay={delay} duration={duration} />
        </div>
      </>
    );
  }, [score, formatValue, dontHaveScoreText, delay, duration]);

  return <div className={styles.scoreContent}>{renderScore}</div>;
};

export default React.memo(ScoreContainer);
