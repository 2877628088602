import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './RollerExamPage.module.css';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import SeoLinks from '../../components/SeoLinks/SeoLinks';

const RollerExamPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('rollerExamPage.title1')}</PageTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>
              <div>
                <Trans i18nKey="rollerExamPage.text11">
                  desc
                  <LinkWrapper to="/">theorie24.ch</LinkWrapper>
                  desc
                </Trans>
              </div>
              <div>
                <Trans i18nKey="rollerExamPage.text12">
                  desc
                  <LinkWrapper to="/">theorie24.ch</LinkWrapper>
                  desc
                </Trans>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.subtitle}>{t('rollerExamPage.title2')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>
              <Trans i18nKey="rollerExamPage.text2">
                desc
                <LinkWrapper to="/">theorie24.ch</LinkWrapper>
                desc
              </Trans>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.subtitle}>{t('rollerExamPage.title3')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>
              <Trans i18nKey="rollerExamPage.text3">
                desc
                <LinkWrapper to="/contacts">Kontakt</LinkWrapper>
                desc
              </Trans>
            </div>
          </Col>
        </Row>

        <Row className={styles.links}>
          <SeoLinks />
        </Row>
      </Container>
    </div>
  );
};

export default RollerExamPage;
