import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { IScoreCardComponentsProps, IScoreCardProps } from './ScoreCard.types';
import Label from './components/Label/Label';
import ScoreContainer from './components/ScoreContainer/ScoreContainer';
import styles from './ScoreCard.module.css';

const ScoreCard: React.FC & IScoreCardProps & IScoreCardComponentsProps = ({ children }: IScoreCardProps) => {
  return <Jumbotron className={styles.scoreContainer}>{children}</Jumbotron>;
};

ScoreCard.Label = Label;
ScoreCard.ScoreContainer = ScoreContainer;
export { ScoreCard };
