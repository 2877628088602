import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Form, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { IRegisterFormData, IRegisterFormProps } from './RegisterForm.types';
import { InitRegistrationRequestPayload } from './RegisterForm.constants';
import styles from './RegisterForm.module.css';
import { REGISTRATION_FORM_KEY } from '../../constants/storageKeys';
import { COOKIES_EXPIRES } from '../../constants';
import { registerFormSchema } from './RegisterForm.validation';

const RegisterForm: React.FC<IRegisterFormProps> = ({
  onSubmit,
  errorMessages,
  submitButtonRef
}: IRegisterFormProps) => {
  const { t } = useTranslation();
  const [isReset, setIsReset] = useState(false);
  const initialValues = useMemo(
    () =>
      isReset
        ? InitRegistrationRequestPayload
        : (Cookies.getJSON(REGISTRATION_FORM_KEY) as IRegisterFormData) || InitRegistrationRequestPayload,
    [isReset]
  );

  const { handleSubmit, handleReset, handleChange, handleBlur, values, errors, resetForm } = useFormik({
    initialValues,
    validationSchema: registerFormSchema(t('registerForm.errors.required'), t('registerForm.errors.invalid')),
    validateOnChange: false,
    validateOnBlur: false,
    initialErrors: errorMessages,
    onSubmit: (formData: IRegisterFormData) => {
      onSubmit(formData);
    }
  });

  /** use stringify to compare 2 objects change to lodash deepClone if we add it to project in future * */
  const isResetButtonDisabled = useMemo(() => {
    return JSON.stringify(initialValues) === JSON.stringify(values);
  }, [initialValues, values]);

  const onReset = e => {
    handleReset(e);
    Cookies.remove(REGISTRATION_FORM_KEY);
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset) {
      resetForm();
      setIsReset(false);
    } else {
      Cookies.set(REGISTRATION_FORM_KEY, values, { expires: COOKIES_EXPIRES });
    }
  }, [values, isReset, resetForm]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} xs={5} md={4}>
          <Form.Control as="select" name="title" value={values.title} onChange={handleChange} required>
            <option value="">{t('registerForm.form.gender.title')}</option>
            <option value="male">{t('registerForm.form.gender.male')}</option>
            <option value="female">{t('registerForm.form.gender.female')}</option>
          </Form.Control>
          {errors.title && <Form.Label className="text-danger">{errors.title}</Form.Label>}
        </Form.Group>
        <Form.Group as={Col} xs={7} md={8}>
          <Form.Control
            isInvalid={Boolean(errors.firstname)}
            placeholder={t('registerForm.form.firstName')}
            name="firstname"
            maxLength={50}
            value={values.firstname}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />
          {errors.firstname && <Form.Label className="text-danger">{errors.firstname}</Form.Label>}
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Control
          isInvalid={Boolean(errors.lastname)}
          placeholder={t('registerForm.form.lastName')}
          value={values.lastname}
          maxLength={50}
          name="lastname"
          onBlur={handleBlur}
          onChange={handleChange}
          required
        />
        {errors.lastname && <Form.Label className="text-danger">{errors.lastname}</Form.Label>}
      </Form.Group>
      <Form.Group controlId="formGridEmail">
        <Form.Control
          isInvalid={Boolean(errors.email)}
          type="email"
          value={values.email}
          maxLength={50}
          placeholder={t('registerForm.form.email')}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          required
        />
        {errors.email && <Form.Label className="text-danger">{errors.email}</Form.Label>}
      </Form.Group>
      <Form.Group controlId="formGridEmailConfirm">
        <Form.Control
          isInvalid={Boolean(errors.confirmEmail)}
          type="email"
          value={values.confirmEmail}
          maxLength={50}
          placeholder={t('registerForm.form.confirmEmail')}
          name="confirmEmail"
          onBlur={handleBlur}
          onChange={handleChange}
          required
        />
        {errors.confirmEmail && <Form.Label className="text-danger">{errors.confirmEmail}</Form.Label>}
      </Form.Group>
      <Form.Group controlId="formGridAddress1">
        <Form.Control
          isInvalid={Boolean(errors.address)}
          value={values.address}
          placeholder={t('registerForm.form.address')}
          name="address"
          maxLength={200}
          onChange={handleChange}
          required
        />
        {errors.address && <Form.Label className="text-danger">{errors.address}</Form.Label>}
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} xs={4} controlId="formGridZip">
          <Form.Control
            isInvalid={Boolean(errors.zip)}
            placeholder={t('registerForm.form.zip')}
            name="zip"
            maxLength={4}
            value={values.zip}
            onChange={handleChange}
            required
          />
          {errors.zip && <Form.Label className="text-danger">{errors.zip}</Form.Label>}
        </Form.Group>
        <Form.Group as={Col} xs={8} controlId="formGridCity">
          <Form.Control
            isInvalid={Boolean(errors.city)}
            placeholder={t('registerForm.form.city')}
            name="city"
            maxLength={50}
            onBlur={handleBlur}
            value={values.city}
            onChange={handleChange}
            required
          />
          {errors.city && <Form.Label className="text-danger">{errors.city}</Form.Label>}
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Control
          isInvalid={Boolean(errors.phone)}
          placeholder={t('registerForm.form.phone')}
          name="phone"
          type="tel"
          minLength={10}
          maxLength={13}
          onBlur={handleBlur}
          value={values.phone}
          onChange={handleChange}
          required
        />
        {errors.phone && <Form.Label className="text-danger">{errors.phone}</Form.Label>}
      </Form.Group>
      <Form.Group className={styles.checkList}>
        <Form.Check custom>
          <Form.Check.Input type="checkbox" checked={values.toc} name="toc" id="toc" required onChange={handleChange} />
          <Form.Check.Label>
            {`${t('registerForm.form.termsFirstPart')}`}
            <LinkWrapper target="_blank" to="/term/">{`${t('registerForm.form.termsLink')} `}</LinkWrapper>
            {`${t('registerForm.form.termsSecondPart')}`}
          </Form.Check.Label>
        </Form.Check>
        {errors.toc && <Form.Label className="text-danger">{errors.toc}</Form.Label>}
        <Form.Check
          custom
          name="news"
          id="news"
          label={t('registerForm.form.news')}
          checked={values.news}
          onChange={handleChange}
        />
      </Form.Group>
      <button type="submit" id="registration-form-submit" ref={submitButtonRef} hidden>
        Submit
      </button>

      <Button variant="outline-danger" type="button" onClick={onReset} disabled={isResetButtonDisabled}>
        {t('registerForm.form.clearButton')}
      </Button>
    </Form>
  );
};

export default RegisterForm;
