import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './MopedTestPage.module.css';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import SeoLinks from '../../components/SeoLinks/SeoLinks';

const MopedTestPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('mopedTestPage.title1')}</PageTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>
              <div>
                <Trans i18nKey="mopedTestPage.text11">
                  desc
                  <LinkWrapper to="/">theorie24.ch</LinkWrapper>
                  desc
                </Trans>
              </div>
              <div>{t('mopedTestPage.text12')}</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.subtitle}>{t('mopedTestPage.title2')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>{t('mopedTestPage.text2')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.subtitle}>{t('mopedTestPage.title3')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.text}>
              <Trans i18nKey="mopedTestPage.text3">
                desc
                <LinkWrapper to="/offers">auf der Onlineplattform theorie24.ch registrieren</LinkWrapper>
                desc
              </Trans>
            </div>
          </Col>
        </Row>

        <Row className={styles.links}>
          <SeoLinks />
        </Row>
      </Container>
    </div>
  );
};

export default MopedTestPage;
