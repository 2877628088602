import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_REQUEST, LOGOUT_MODAL_HIDE } from '../actions/login.action';
import LogoutModal from '../components/LogoutModal/LogoutModal';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';

const LogoutModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { isLogoutModalOpen }: LoginState = useSelector(selectLogin);

  const logout = useCallback(() => {
    dispatch(LOGOUT_REQUEST());
  }, [dispatch]);

  const handleHideModal = useCallback(() => {
    dispatch(LOGOUT_MODAL_HIDE());
  }, [dispatch]);

  return <LogoutModal onHide={handleHideModal} show={isLogoutModalOpen} onLogout={logout} />;
};

export default LogoutModalContainer;
