import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Sound from 'react-sound';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../../reducer';
import { SOUND_NOT_AVAILABLE_FOR_THIS_DEVICE, UPDATE_MUSIC_DATA } from '../../actions/sound.action';
import { STOPPED } from '../../reducer/sound.types';
import { isTouchDevice } from '../../utils/utils';

const SoundContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { url, playStatus, volume, uniqId } = useSelector(({ sound }: IRootState) => sound);
  const [prevUniqId, setPrevUniqId] = useState(null);

  useEffect(() => {
    const setSoundNotAvailable = () => {
      if (!isTouchDevice()) {
        toast(t('gameContainer.soundProblem'), {
          duration: 6000,
          style: {
            border: '1px solid rgba(0, 128, 201, 0.8)',
            padding: '20px'
          },
          icon: '🤔'
        });
      }
      dispatch(SOUND_NOT_AVAILABLE_FOR_THIS_DEVICE());
    };
    window.addEventListener('unhandledrejection', setSoundNotAvailable);
    return () => {
      window.removeEventListener('unhandledrejection', setSoundNotAvailable);
    };
  }, [dispatch, t]);

  useEffect(() => {
    setPrevUniqId(uniqId);
  }, [uniqId]);

  const onFinishedPlaying = useCallback(() => {
    dispatch(UPDATE_MUSIC_DATA({ playStatus: STOPPED }));
  }, [dispatch]);

  if (prevUniqId === uniqId && prevUniqId) {
    return <Sound url={url} playStatus={playStatus} volume={volume} onFinishedPlaying={onFinishedPlaying} />;
  }
  return null;
};

export default React.memo(SoundContainer);
