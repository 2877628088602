import React, { memo } from 'react';
import { ISchoolLogo } from './SchoolLogo.types';
import Avatar from '../../ui-components/Avatar/Avatar';
import { LOGO_PATH } from '../../constants';
import useImgFetchStatus from '../../hooks/useImgFetchStatus';
import styles from './SchoolLogo.module.css';

const SchoolLogo: React.FC<ISchoolLogo> = ({ alt, logoSrc, withPadding = true }) => {
  const logoPath = `${LOGO_PATH}${logoSrc}`;
  const { isFetched, isPending, isError } = useImgFetchStatus(logoPath || '');

  if (isPending) {
    return null;
  }

  if (isError) {
    return <Avatar alt={alt!} />;
  }

  if (isFetched && logoSrc) {
    const containerClass = `schoolLogo ${withPadding ? styles.imageContainer : ''}`;
    return (
      <div className={containerClass}>
        <img alt={alt} src={`${LOGO_PATH}${logoSrc}`} width="100%" />
      </div>
    );
  }
  return <Avatar alt={alt!} />;
};

export default memo(SchoolLogo);
