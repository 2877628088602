import React, { useCallback } from 'react';
import cn from 'classnames';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ICollapseItemProps } from './CollapseItem.types';
import styles from './CollapseItem.module.css';

const CollapseItem: React.FC<ICollapseItemProps> = ({
  question,
  answer,
  important,
  eventKey,
  onSetEventKey,
  show,
  link,
  content
}: ICollapseItemProps) => {
  const { t } = useTranslation();
  const className = cn(styles.root, 'icon-arrow-fill', {
    [styles.open]: show
  });

  const handleChange = useCallback(() => {
    onSetEventKey(eventKey);
  }, [onSetEventKey, eventKey]);

  return (
    <div className={styles.faqItem} aria-controls={eventKey} aria-expanded={show}>
      <button type="button" className={className} onClick={handleChange}>
        <div>
          <div className={styles.faqHeader}>
            <span className={styles.faqArrowBtn}>{show ? '' : ''}</span>
            <span className={styles.faqHeaderText}>{t(question)}</span>
          </div>
        </div>
      </button>
      <Collapse in={show}>
        <div id={eventKey}>
          {important && <span className={styles.faqText}>{t(important)}</span>}
          <span className={styles.faqText}>
            {answer ? t(answer) : content}
            {link}
          </span>
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseItem;
