import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../../reducer';
import Image from '../../ui-components/Image/Image';
import { IInstructorContainerProps } from './InstructorContainer.types';
import InstructorCard from '../../components/InstructorCard/InstructorCard';
import useDeviceSizes from '../../hooks/useDeviceSizes';
import styles from './InstructorContainer.module.css';

const InstructorContainer: React.FC<IInstructorContainerProps> = ({
  src,
  hideForMobile
}: IInstructorContainerProps) => {
  const { t } = useTranslation();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const instructor = useSelector(({ user }: IRootState) => user.profile.drivingInstructor);
  const { width } = useDeviceSizes();
  const isHide = hideForMobile && width <= 991;

  if (!isLogged || !instructor) {
    return src ? (
      <div className={styles.imgContainer}>
        <a href={t('partnerLogos.cupra.link')} rel="noopener noreferrer" target="_blank">
          <Image src={src} width="100%" />
        </a>
      </div>
    ) : null;
  }

  return <InstructorCard instructor={instructor} hidden={isHide} />;
};

export default InstructorContainer;
