import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IOfferCardProps } from '../OfferCard/OfferCard.types';
import styles from './OfferAccordion.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const OfferAccordion: React.FC<IOfferCardProps> = ({ offer, linkTo, currentLanguage }: IOfferCardProps) => {
  const { t } = useTranslation();

  let offerDays = 'offersPage.days';
  if (offer.days === 1) {
    offerDays = 'offersPage.day';
  }

  let activeClass = '';
  if (offer.id === 4) {
    activeClass = styles.suggested;
  }

  return (
    <Accordion className={styles.accordionCardItem}>
      <Card className={activeClass}>
        <Accordion.Toggle as={Card.Header} eventKey={offer.id.toString()}>
          <span className={styles.cardHeaderText}>{offer.title}</span>
          <div className={styles.cardHeaderInfo}>
            <span className={styles.cardHeaderDays}>
              {offer.days}
              &nbsp;
              {t(offerDays)}
            </span>
            <span className={styles.cardHeaderText}>{offer.cost}</span>
            <div className={styles.accordionBtn}>
              <i className="icon-arrow" />
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={offer.id.toString()}>
          <Card.Body>
            <Card.Text>{offer.langs[currentLanguage].description}</Card.Text>
            <Button className={styles.accordionFooterBtn} variant="primary" as={LinkWrapper} to={linkTo}>
              {t('offersPage.more')}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default OfferAccordion;
