import { createAction } from 'redux-actions';

export const SET_GAME_DIFFICULTY = createAction('SET_GAME_DIFFICULTY');

export const SET_GAME_TYPE = createAction('SET_GAME_TYPE');

export const SET_GAME_DURATION = createAction('SET_GAME_DURATION');
export const SET_GAME_LIVES_MODE = createAction('SET_GAME_LIVES_MODE');
export const SET_GAME_CATEGORY = createAction('SET_GAME_CATEGORY');

export const INCREMENT_SCORE = createAction('INCREMENT_SCORE');
export const DECREMENT_LIVE = createAction('DECREMENT_LIVE');
export const SET_LIVES_COUNT = createAction('SET_LIVES_COUNT');

export const START_GAME = createAction('START_GAME');

export const START_PRACTICE = createAction('START_PRACTICE');
export const END_GAME = createAction('END_GAME');
export const RESTART_GAME = createAction('RESTART_GAME');

export const SELECT_DIFFICULTY = createAction('SELECT_DIFFICULTY');
export const SET_START_TIME = createAction('SET_START_TIME');

export const UPDATE_QUESTION_INDEX = createAction('UPDATE_QUESTION_INDEX');

// ANSWER

export const UPDATE_GAME_ANSWER_LIST = createAction('UPDATE_GAME_ANSWER_LIST');
export const ADD_NEW_GAME_ANSWER_REQUEST = createAction('ADD_NEW_GAME_ANSWER_REQUEST');
// setAnswerListFromAllQuestion
export const CREAT_GAME_ANSWER_LIST_FROM_ALL_QUESTION = createAction('CREAT_GAME_ANSWER_LIST_FROM_ALL_QUESTION');

export const UPDATE_EXTRA_SCORE = createAction('UPDATE_EXTRA_SCORE');

export const SHOW_GAME_FROM_HISTORY = createAction('SHOW_GAME_FROM_HISTORY');
