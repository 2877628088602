import React from 'react';
import classNames from 'classnames';
import { PageTitle } from '../PageTitle/PageTitle';
import { IPageDescriptionProps } from './PageDescription.types';
import { PageText } from '../PageText/PageText';
import styles from './PageDescription.module.css';

const PageDescription: React.FC<IPageDescriptionProps> = ({ title, description, className }: IPageDescriptionProps) => {
  return (
    <div className={classNames(styles.pageDescription, className)}>
      <PageTitle>{title}</PageTitle>
      <PageText variant="p3">{description}</PageText>
    </div>
  );
};

export default PageDescription;
