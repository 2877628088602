import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../../../utils/utils';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import { PageTitle } from '../../../../ui-components/PageTitle/PageTitle';
import styles from './TopSection.module.css';

const topImageDe: string = imageAbsolutePath('homePage/top-main-de.png');
const topImageEn: string = imageAbsolutePath('homePage/top-main-en.png');
const topImageFr: string = imageAbsolutePath('homePage/top-main-fr.png');
const topImageIt: string = imageAbsolutePath('homePage/top-main-it.png');

const examQuestionBannerDe: string = imageAbsolutePath('banners/years-logo_de.png');
const examQuestionBannerEn: string = imageAbsolutePath('banners/years-logo_en.png');
const examQuestionBannerFr: string = imageAbsolutePath('banners/years-logo_fr.png');
const examQuestionBannerIt: string = imageAbsolutePath('banners/years-logo_it.png');

const TOP_IMAGE_LIST = {
  de: { img: topImageDe },
  fr: { img: topImageFr },
  it: { img: topImageIt },
  en: { img: topImageEn }
};

const EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST = {
  de: { img: examQuestionBannerDe },
  fr: { img: examQuestionBannerFr },
  it: { img: examQuestionBannerIt },
  en: { img: examQuestionBannerEn }
};

const categoriesAB = imageAbsolutePath('B-A1-logo.png');
const categoriesMFG = imageAbsolutePath('MFG-logo.png');

const TopSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <PageTitle>{t('homePageNew.titleSection.title')}</PageTitle>

      <LocalesImage alt={t('homePageNew.titleSection.images.logoAlt')} localesPath={TOP_IMAGE_LIST} />
      <div className={styles.imagesWrapper}>
        <LocalesImage
          alt={t('homePageNew.titleSection.images.logoAlt')}
          localesPath={EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST}
        />
        <div className={styles.logoWrapper}>
          <img src={categoriesAB} alt={t('homePageNew.titleSection.images.categoriesAlt')} />
          <img src={categoriesMFG} alt={t('homePageNew.titleSection.images.categoriesAlt')} />
        </div>
      </div>
    </section>
  );
};

export default TopSection;
