import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../../reducer';
import { IGameResultContainerProps } from './GameScoreContainer.types';
import ScoreCard from '../../components/Game/ScoreCard/ScoreCard';
import ScoreTypeSelector from '../../components/Game/ScoreTypeSelector/ScoreTypeSelector';
import GameScoreTable from '../../components/Game/GameScoreTable/GameScoreTable';
import { PENDING, UNCALLED } from '../../constants/store.constants';
import { SET_SCORE_LIVES_MODE } from '../../actions/gameScore.action';
import useAfterUserDataFetched from '../../hooks/useAfterUserDataFetched';
import styles from './GameScoreContainer.module.css';

const GameScoreContainer: React.FC<IGameResultContainerProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedScoreType = useSelector(({ gameScore }: IRootState) => gameScore.livesMode);
  const {
    bestScoresObj,
    listOfScores,
    scoreRequest: { status }
  } = useSelector(({ gameScore }: IRootState) => gameScore);

  const bestScoreByType = `${t('gameScorePage.bestScore')} ${t(`gameContainer.${selectedScoreType}`)} ${t(
    'gameScorePage.mode'
  )}`;

  const isScoreLoading = useMemo(() => {
    return status === PENDING || status === UNCALLED;
  }, [status]);

  const isBestScoreLoading = useMemo(() => {
    return status === UNCALLED || (status === PENDING && bestScoresObj?.bestScore === undefined);
  }, [status, bestScoresObj]);

  const initDataLoading = useCallback(() => dispatch(SET_SCORE_LIVES_MODE(selectedScoreType)), [
    dispatch,
    selectedScoreType
  ]);

  useAfterUserDataFetched(initDataLoading);

  return (
    <Container className={styles.container}>
      <Row>
        <Col lg={4}>
          <ScoreTypeSelector />
        </Col>
        <Col className={styles.scoreContainer} md={6} lg={4}>
          <ScoreCard
            dontHaveScoreText={t('gameScorePage.doNotHaveBestMode')}
            isLoading={isScoreLoading}
            text={bestScoreByType}
            score={bestScoresObj?.[`${selectedScoreType}BestScore`]}
          />
        </Col>
        <Col className={styles.scoreContainer} md={6} lg={4}>
          <ScoreCard
            dontHaveScoreText={t('gameScorePage.doNotHaveBestScore')}
            isLoading={isBestScoreLoading}
            text={t('gameScorePage.bestScore')}
            score={bestScoresObj?.bestScore}
            className={bestScoresObj?.bestScore && 'retroColorChangeClass'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <GameScoreTable tableData={listOfScores} isLoading={isScoreLoading} />
        </Col>
      </Row>
    </Container>
  );
};

export default GameScoreContainer;
