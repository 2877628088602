import React from 'react';
import cx from 'classnames';
import { Container, Col, Row } from 'react-bootstrap';
import PlaceholderBox from '../PlaceholderBox/PlaceholderBox';
import styles from './CockpitLoggedOffline.module.css';

const CockpitLoggedOffline: React.FC = () => {
  return (
    <>
      <Container className={styles.placeholderContainer}>
        <Row>
          <Col xs={12} lg={6}>
            <PlaceholderBox show className={styles.box} />
          </Col>
          <Col xs={12} lg={3}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
          <Col xs={12} lg={3}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
        </Row>
      </Container>
      <Container className={cx(styles.placeholderContainer, styles.invert)}>
        <Row>
          <Col xs={12} lg={6}>
            <PlaceholderBox show className={styles.box} />
          </Col>
          <Col xs={12} lg={6}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
          <Col xs={12} lg={4}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
          <Col xs={12} lg={4}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
          <Col xs={12} lg={4}>
            <PlaceholderBox show className={cx(styles.box, styles.mbRaw)} />
          </Col>
        </Row>
      </Container>
      <Container className={styles.placeholderContainer}>
        <Row>
          <Col xs={12} lg={6}>
            <PlaceholderBox show className={cx(styles.box, styles.vertical)} />
          </Col>
          <Col xs={12} lg={3}>
            <PlaceholderBox show className={cx(styles.box, styles.vertical)} />
          </Col>
          <Col xs={12} lg={3}>
            <PlaceholderBox show className={cx(styles.box, styles.vertical)} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CockpitLoggedOffline;
