import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-google-maps';
import { CTM_ADLER_LOCATION, WHATS_APP_LINK } from '../../constants';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { ContactCard } from '../../components/ContactCard/ContactCard';
import ContainerMap, { mapStyles } from '../../components/Map/Map';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBundary';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import styles from './ContactPage.module.css';

const MAP_OPTIONS = {
  scrollwheel: false,
  mapTypeId: 'roadmap',
  streetViewControl: false,
  mapTypeControl: false,
  scaleControl: false,
  styles: mapStyles
};

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.contactPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle className={styles.contactPageTitle}>{t('contact.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.contactList}>
              <ContactCard
                title="contact.content1.title"
                text1="contact.content1.text"
                text2="info.tellabel"
                link={t('info.telhref')}
              />
              <ContactCard
                title="contact.content2.title"
                text1="contact.content2.text"
                text2="contact.content2.phone"
                link={WHATS_APP_LINK}
              />
              <ContactCard
                title="contact.content3.title"
                text1="contact.content3.text"
                text2="contact.content3.email"
                link={t('info.emailhref')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.mapContainer}>
              <Jumbotron className={styles.contactItemFly}>
                <div className={styles.contactItemContent}>
                  <div className={styles.contactTitle}>{t('contact.content4.title1')}</div>
                  <p className={styles.contactText}>{t('contact.content4.text1')}</p>
                  <p className={styles.contactText}>{t('contact.content4.text2')}</p>
                </div>
                <div className={styles.contactItemContent}>
                  <div className={styles.contactSubtitle}>{t('contact.content4.title2')}</div>
                  <p className={styles.contactText}>{t('contact.content4.text3')}</p>
                  <p className={styles.contactText}>{t('contact.content4.text4')}</p>
                </div>
              </Jumbotron>
              <ErrorBoundary fallback={<PlaceholderBox show className={styles.mapElementContainer} />}>
                <ContainerMap
                  defaultZoom={15}
                  options={MAP_OPTIONS}
                  defaultCenter={CTM_ADLER_LOCATION}
                  containerElement={<div className={styles.mapElementContainer} />}
                >
                  <Marker position={CTM_ADLER_LOCATION} />
                </ContainerMap>
              </ErrorBoundary>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactPage;
