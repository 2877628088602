import React from 'react';
import { Trans } from 'react-i18next';
import { BottomDetails } from '../ui-components/BottomDetails/BottomDetails';
import { P3 } from '../ui-components/P3/P3';
import { BottomDetailsColumn } from '../ui-components/BottomDetailsColumn/BottomDetailsColumn';
import styles from './GameDetailsContainer.module.css';

const GameDetailsContainer: React.FC = () => {
  return (
    <>
      <BottomDetails className={styles.bottomDetailsContainer}>
        <BottomDetailsColumn>
          <P3>
            <Trans i18nKey="gameContainer.bottomDescription.text1">
              desc
              <b />
              desc
            </Trans>
          </P3>
          <P3>
            <Trans i18nKey="gameContainer.bottomDescription.text2">
              desc
              <b />
              desc
            </Trans>
          </P3>
          <P3>
            <Trans i18nKey="gameContainer.bottomDescription.text3">
              desc
              <b />
              desc
            </Trans>
          </P3>
        </BottomDetailsColumn>
        <BottomDetailsColumn>
          <P3>
            <Trans i18nKey="gameContainer.bottomDescription.text4">
              desc
              <b />
              desc
            </Trans>
          </P3>
          <P3>
            <Trans i18nKey="gameContainer.bottomDescription.text5">
              desc
              <b />
              desc
              <span className={styles.bold} />
              desc
            </Trans>
          </P3>
        </BottomDetailsColumn>
      </BottomDetails>
    </>
  );
};

export default GameDetailsContainer;
