import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { IQuestionChooseModalGameContainerProps } from './QuestionChooseModalGameContainer.types';
import { QuestionLink } from '../../../ui-components/QuestionLink/QuestionLink';
import { AnswerObj } from '../../../reducer/game.types';
import { getAnswerType } from '../../../utils/utils';
import styles from './QuestionChooseModalGameContainer.module.css';

const getStatusCls = (answer: AnswerObj): string => {
  return getAnswerType(answer?.isCorrect, styles.examListItemCorrect, styles.examListItemIncorrect, '', '');
};

const QuestionChooseModalGameContainer: React.FC<IQuestionChooseModalGameContainerProps> = ({
  onHide,
  onQuestionChange,
  defaultRoute,
  answerList,
  show
}: IQuestionChooseModalGameContainerProps) => {
  const answers: AnswerObj[] = answerList;
  const modalContainerClassName = classNames('modalQuestion', styles.gameModalContainer);
  return (
    <>
      <Modal
        className={modalContainerClassName}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onHide}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={styles.examList}>
            {answers.map((answer, index) => (
              <QuestionLink
                key={answer.id}
                onQuestionChange={onQuestionChange(index)}
                link={`${defaultRoute}/${index + 1}`}
                className={classNames(styles.examListItem, getStatusCls(answer))}
              >
                <span className={styles.questionsNumbers}>{index + 1}</span>
              </QuestionLink>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuestionChooseModalGameContainer;
