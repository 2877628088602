import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import { HomeCard } from '../../components';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import { getTranslationList } from '../../../../utils/utils';
import { selectLanguage } from '../../../../reducer/user.selectors';
import MoreInfoOfferLink from '../../../../components/MoreInfoOfferLink/MoreInfoOfferLink';
import styles from './KeyBenefitsSection.module.css';

const KeyBenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  const { location } = useReactRouter();

  const currentLanguage = useSelector(selectLanguage);

  const getHomeCardList = useMemo(() => {
    const translationObj = getTranslationList(currentLanguage, `homePageNew.keyBenefitsSection.cards`);
    const translationList: string[] = Object.values(translationObj);
    return Object.values(translationList).map(text => text);
  }, [currentLanguage]);

  const renderHomeCardList = useMemo(
    () =>
      getHomeCardList.map((item: any, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <HomeCard key={index} {...item} />
      )),
    [getHomeCardList]
  );

  const infoLinkUrl = location.pathname.includes('offers') ? '/about' : '/offers';

  return (
    <section className={styles.container}>
      <TitleH3 center text={t('homePageNew.keyBenefitsSection.title')} />

      <div className={styles.cardsWrapper}>{renderHomeCardList}</div>

      <div className={styles.buttonWrapper}>
        <LinkWrapper className="btn btn-primary" to="/offers" toanchor="plans">
          {t('homePageNew.btn.buyNow')}
        </LinkWrapper>
      </div>
      <MoreInfoOfferLink to={infoLinkUrl} />
    </section>
  );
};

export default KeyBenefitsSection;
