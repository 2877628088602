export function getErrorMessagesFromResponse(response: any): { [key: string]: string } {
  const { status, payload = {}, error = {} } = response || {};
  if (status >= 400 && typeof payload.validationError === 'object') {
    return payload.validationError;
  }
  if (typeof error.message === 'string') {
    return { message: error.message };
  }
  return {};
}
