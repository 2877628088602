import React from 'react';
import cx from 'classnames';
import { ImageProps } from './Image.types';
import styles from './Image.module.css';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import useImgFetchStatus from '../../hooks/useImgFetchStatus';

const Image: React.FC<ImageProps> = ({ placeholderClassName, className, alt = 'theory24', ...rest }: ImageProps) => {
  const { isFetched } = useImgFetchStatus(rest.src || '');

  return (
    <div className={styles.imgContainer}>
      <PlaceholderBox show={!isFetched} className={placeholderClassName}>
        <img className={cx(styles.imgItem, className)} alt={alt} {...rest} />
      </PlaceholderBox>
    </div>
  );
};

export default Image;
