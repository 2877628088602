import { string, object, number, InferType } from 'yup';

export const langTopicSchema = object().shape({
  title: string().required(),
  themeid: string().required(),
  sortnr: number(),
  qCount: number()
});

export type TTopic = InferType<typeof langTopicSchema>;
