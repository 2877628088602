import React from 'react';
import {
  FaMapSigns,
  FaRoad,
  FaParking,
  FaCarCrash,
  FaBalanceScaleRight,
  FaBusAlt,
  FaCircleNotch,
  FaTrailer
} from 'react-icons/fa';
import { CgSmartHomeLight, CgShapeRhombus } from 'react-icons/cg';

import { RiTrafficLightLine } from 'react-icons/ri';
import { GiMountainCave, GiSpeedometer, GiCycling, GiBeltBuckles } from 'react-icons/gi';
import { MdDriveEta } from 'react-icons/md';
import { AiOutlineShrink, AiOutlineWarning, AiOutlinePullRequest } from 'react-icons/ai';

export const TopicItemIconsObj = {
  1: props => <FaMapSigns {...props} />,
  2: props => <GiCycling {...props} />,
  3: props => <CgSmartHomeLight {...props} />,
  4: props => <FaRoad {...props} />,
  5: props => <RiTrafficLightLine {...props} />,
  6: props => <FaParking {...props} />,
  7: props => <AiOutlineShrink {...props} />,
  8: props => <GiMountainCave {...props} />,
  9: props => <FaBalanceScaleRight {...props} />,
  10: props => <GiSpeedometer {...props} />,
  11: props => <GiBeltBuckles {...props} />,
  12: props => <FaCarCrash {...props} />,
  13: props => <AiOutlineWarning {...props} />,
  14: props => <FaBusAlt {...props} />,
  15: props => <FaCircleNotch {...props} />,
  16: props => <AiOutlinePullRequest {...props} />,
  17: props => <CgShapeRhombus {...props} />,
  18: props => <MdDriveEta {...props} />,
  19: props => <FaTrailer {...props} />
};
