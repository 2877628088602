import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CommonContainer from '../../../../ui-components/CommonContainer/CommonContainer';
import { imageAbsolutePath } from '../../../../utils/utils';
import { useGetSelectedColorTheme } from '../../../../hooks/useGetSelectedColorTheme';
import styles from './PartnerSection.module.css';

const partnerLogo = imageAbsolutePath('partner-logo-white.png');
const partnerLogoDarkThem = imageAbsolutePath('partner-logo-black.png');

const PartnerSection: React.FC = () => {
  const { t } = useTranslation();

  const { isDarkTheme } = useGetSelectedColorTheme();
  const logoSrc = useMemo(() => (isDarkTheme ? partnerLogoDarkThem : partnerLogo), [isDarkTheme]);
  return (
    <section className={styles.container}>
      <CommonContainer>
        <div className={styles.wrapper}>
          <h5>{t('homePageNew.partnerSection.title')}</h5>
          <a href={t('partnerLogos.cupra.link')} rel="noopener noreferrer" target="_blank" className={styles.wrapper}>
            <img src={logoSrc} alt={t('homePage.partnerSection.imageAlt1')} />
          </a>
        </div>
      </CommonContainer>
    </section>
  );
};

export default PartnerSection;
