import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IEbikeNavMenuProps } from './EbikeNavMenu.types';

const ebikeMainPage = (match, location) => {
  return location.pathname === '/ebike' || location.pathname === '/ebike/right';
};

const EbikeNavMenu: React.FC<IEbikeNavMenuProps> = ({ currentLanguage }: IEbikeNavMenuProps) => {
  const { t } = useTranslation();

  return (
    <Nav>
      <Nav.Item>
        <Nav.Link as={NavLink} isActive={ebikeMainPage} to={`/${currentLanguage}/ebike/right`}>
          {t('ebikeNavMenu.right')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={`/${currentLanguage}/ebike/safe-travel`}>
          {t('ebikeNavMenu.safe_travel')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={`/${currentLanguage}/ebike/trailer`}>
          {t('ebikeNavMenu.trailer')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={`/${currentLanguage}/ebike/legal-aspects`}>
          {t('ebikeNavMenu.legal_aspects')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default EbikeNavMenu;
