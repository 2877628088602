import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { P3 } from '../../ui-components/P3/P3';
import styles from './DrivingInstructor.module.css';

const DrivingInstructor: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.instructorPage}>
      <Container>
        <Row>
          <Col lg={9}>
            <PageTitle>{t('drivingInstructor.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <p className={styles.instructorSubtitle}>{t('drivingInstructor.content1.text1')}</p>
            <div className={styles.instructorList}>
              <P3>{t('drivingInstructor.content1.text2')}</P3>
              <P3>{t('drivingInstructor.content1.text3')}</P3>
              <P3>{t('drivingInstructor.content1.text4')}</P3>
              <P3>{t('drivingInstructor.content1.text5')}</P3>
              <P3>{t('drivingInstructor.content1.text6')}</P3>
            </div>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <P3 className={styles.jumbotronTitle}>{t('drivingInstructor.content2.text1')}</P3>
            <Jumbotron>
              <p className={styles.instructorSubtitle}>{t('drivingInstructor.content2.text2')}</p>
              <p className={styles.instructorSubtitle}>
                {`${t('drivingInstructor.content2.text3')} `}
                <LinkWrapper to="/contacts">{t('drivingInstructor.content2.contact')}</LinkWrapper>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DrivingInstructor;
