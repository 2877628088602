export const isSSR = () => typeof window === 'undefined';

export const webViewList = ['webview=ios', 'webview=android'];

export const CTM_ADLER_LOCATION = { lat: 47.2023249, lng: 7.5277035 };
export const DEFAULT_MAP_CENTER = { lat: 46.82, lng: 8 };
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
export const DEFAULT_MAP_ZOOM = 8;
export const DEMO_PATH_LIST = [
  '/mycockpitdemo',
  '/questions',
  '/theory',
  '/exam',
  '/3dteacher',
  '/ebike',
  '/questions/:category/:topic/:id?'
];
export const HOST = process.env.REACT_APP_API_GATE_HOST || isSSR() ? undefined : window.location.hostname;
export const PORT = process.env.REACT_APP_API_GATE_PORT ? `:${process.env.REACT_APP_API_GATE_PORT}` : '';
export const GATE = `${isSSR() ? undefined : window.location.protocol}//${HOST}${PORT}/api/v1`;
export const COOKIES_EXPIRES = 7; // 7 days
export const WHATS_APP_LINK = 'https://wa.me/41793162890';
export const LOGO_PATH = '/uploads/logos/';
export const IS_WEBVIEW = isSSR() ? false : webViewList.some(item => window.location.href.indexOf(item) !== -1);
export const IS_WEBVIEW_IOS = isSSR() ? undefined : window.location.href.indexOf(webViewList[0]) !== -1;
export const IS_CONTAIN_WEBVIEW_IN_NAVIGATOR = isSSR() ? undefined : navigator.userAgent.indexOf('WebView') !== -1;
export const HAS_RATE = isSSR() ? false : window.location.href.indexOf('rate=1') !== -1;
/* eslint-disable-next-line */
export const IOS_VERSION = isSSR()
  ? undefined
  : navigator.userAgent.split('iOS')[1]
  ? navigator.userAgent
      .split('iOS')[1]
      .split('.')[0]
      .trim()
  : false;
export const FIREBASE_LOGIN_TRY_COUNT = 3;
export const EMPTY_MARKED_QUESTIONS = {
  B: [],
  'A,A1': [],
  'F,G': [],
  M: [],
  BAA1: []
};

// Game
export const GAME_LIVE_NAME_SHORT = 'god';
export const GAME_LIVE_NAME_MEDIUM = 'advanced';
export const GAME_LIVE_NAME_LONG = 'start';

export const GAME_TYPE_TIME = 'time';
export const GAME_TYPE_LIVES = 'lives';
export const GAME_TYPE_SIMPLE = 'simple';

export const BEGINNER_LIVES = 5;
export const ADVANCED_LIVES = 3;
export const PRO_LIVES = 1;

export const GAME_LIVES_OBJ = {
  [GAME_LIVE_NAME_SHORT]: PRO_LIVES,
  [GAME_LIVE_NAME_MEDIUM]: ADVANCED_LIVES,
  [GAME_LIVE_NAME_LONG]: BEGINNER_LIVES
};

export const GAME_DURATION_NAME_SHORT = 'short';
export const GAME_DURATION_NAME_MEDIUM = 'medium';
export const GAME_DURATION_NAME_LONG = 'long';

export const GAME_DURATION_SHORT = 25;
export const GAME_DURATION_MEDIUM = 50;
export const GAME_DURATION_LONG = 99;

export const GAME_DURATION_OBJ = {
  [GAME_DURATION_NAME_SHORT]: GAME_DURATION_SHORT,
  [GAME_DURATION_NAME_MEDIUM]: GAME_DURATION_MEDIUM,
  [GAME_DURATION_NAME_LONG]: GAME_DURATION_LONG
};

export const GAME_PLAY_STATUS = 'play';
export const GAME_END_STATUS = 'finished';
export const GAME_HISTORY_STATUS = 'history';

export const BEGINNER_DIFFICULTY = 'beginner';
export const ADVANCED_DIFFICULTY = 'advanced';
export const PRO_DIFFICULTY = 'pro';

export const GAME_CATEGORY_CAR = 'car';
export const GAME_CATEGORY_MOTORCYCLE = 'motorcycle';
export const GAME_CATEGORY_ALL = 'All';

export const CORRECT_ANSWER = 'correct';
export const WRONG_ANSWER = 'wrong';
export const NOT_ANSWER = 'not answer';
export const UNSET = 'unset';

export const BEGINNER_SCORE_COUNT = 5;
export const ADVANCED_SCORE_COUNT = 5;
export const PRO_SCORE_COUNT = 5;

export const DIFFICULTY_SCORE_OBJ = {
  [BEGINNER_DIFFICULTY]: BEGINNER_SCORE_COUNT,
  [ADVANCED_DIFFICULTY]: ADVANCED_SCORE_COUNT,
  [PRO_DIFFICULTY]: PRO_SCORE_COUNT
};

export const GAME_RESULT_STATUS_VICTORY = 'victory';
export const GAME_RESULT_STATUS_DEFEAT = 'defeat';

export const EXTRA_SCORE_LIST = [5, 3, 0];

export const THEME_SCHEMA = [
  { name: '--whiteColor', defaultColor: '#fff', darkThemeColor: '#000', ladyThemeColor: '#fff' },
  {
    name: '--additional-gray-blue-20',
    defaultColor: 'rgba(226, 234, 246, 0.2)',
    darkThemeColor: '#000',
    ladyThemeColor: 'rgba(226, 234, 246, 0.2)'
  },
  {
    name: '--lavender-grey',
    defaultColor: '#f7f9fc',
    darkThemeColor: 'rgba(226, 234, 246, 0.2)',
    ladyThemeColor: '#FFEEF2'
  },
  {
    name: '--neutral-light-gray',
    defaultColor: '#f7f9fc',
    darkThemeColor: 'rgba(226, 234, 246, 0.2)',
    ladyThemeColor: '#FFEEF2'
  },
  {
    name: '--functional-success-10',
    defaultColor: 'rgba(21, 207, 116, 0.1)',
    darkThemeColor: 'rgba(21, 207, 116, 0.8)',
    ladyThemeColor: 'rgba(21, 207, 116, 0.1)'
  },
  {
    name: '--neutral-light-gray-no-opacity',
    defaultColor: '#f7f9fc',
    darkThemeColor: '#2d2f31',
    ladyThemeColor: '#f7f9fc'
  },
  {
    name: '--additional-gray-blue',
    defaultColor: '#e2eaf6',
    darkThemeColor: 'rgba(226, 234, 246, 0.2)',
    ladyThemeColor: '#ffbec8'
  },
  { name: '--light-white', defaultColor: '#fafafa', darkThemeColor: '#000', ladyThemeColor: '#ffbec8' },
  { name: '--body-color', defaultColor: '#e9f6fe', darkThemeColor: '#000', ladyThemeColor: '#e9f6fe' },
  {
    name: '--footer-text',
    defaultColor: 'rgba(75, 101, 132, 0.7)',
    darkThemeColor: '#fff',
    ladyThemeColor: 'rgba(75, 101, 132, 0.7)'
  },
  {
    name: '--box-shadow',
    defaultColor: 'rgba(0, 0, 0, 0.11)',
    darkThemeColor: 'rgba(255,255,255,0.85)',
    ladyThemeColor: 'rgba(0, 0, 0, 0.11)'
  },
  { name: '--blackColor', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--filter-bright-color-PNG', defaultColor: '1', darkThemeColor: '0', ladyThemeColor: '1' },
  { name: '--neutral-dark-blue', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--neutral-gray-blue', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--neutral-gray-blue-50', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--neutral-dark-blue-50', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--neutral-dark-blue-70', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  { name: '--text-color', defaultColor: '#000', darkThemeColor: '#fff', ladyThemeColor: '#000' },
  {
    name: '--theme-primary-80',
    defaultColor: 'rgba(0, 128, 201, 0.8)',
    darkThemeColor: 'rgba(0, 128, 201, 0.8)',
    ladyThemeColor: 'rgba(255, 107, 245, 0.91)'
  },
  { name: '--button-icon-enabled', defaultColor: '#e4efff', darkThemeColor: '#2f3335', ladyThemeColor: '#ffc8fb' },
  {
    name: '--theme-accent',
    defaultColor: '#fee801',
    darkThemeColor: '#fee801',
    ladyThemeColor: 'rgba(246,91,220,0.7)'
  },
  { name: '--theme-primary', defaultColor: '#0080c9', darkThemeColor: '#0080c9', ladyThemeColor: '#ff6bf5' },
  { name: '--button-default-focused', defaultColor: '#0078bd', darkThemeColor: '#0078bd', ladyThemeColor: '#ff37f5' },
  {
    name: '--button-primary-focused',
    defaultColor: '#f4df01',
    darkThemeColor: '#f4df01',
    ladyThemeColor: 'rgba(253,46,209,0.7)'
  }
];

export const DEFAULT_COLOR_THEME = 'default';
export const BLACK_COLOR_THEME = 'black-themeSwithcer';
