import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { IExamProgressBarProps } from './ExamProgressBar.types';
import styles from './ExamProgressBar.module.css';

const ExamProgressBar: React.FC<IExamProgressBarProps> = ({
  examResult,
  successValue = 90,
  minWidth = '200px',
  withContainer = false
}: IExamProgressBarProps) => {
  const progressBarValue = (examResult.truePointCount / examResult.pointCount) * 100;
  const isSuccess = progressBarValue >= successValue;
  const progressBarClass = classNames(styles.progressBar, { [styles.isExamPassed]: isSuccess });

  if (withContainer) {
    return <ProgressBar className={progressBarClass} now={progressBarValue} />;
  }

  return (
    <div className={styles.progressBarContainer} style={{ minWidth }}>
      <ProgressBar className={progressBarClass} now={progressBarValue} />
    </div>
  );
};

export default ExamProgressBar;
