import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { IRootState } from '../../reducer';
import { IGameResultContainerProps } from './GameResultContainer.types';
import { IExamResult } from '../../reducer/exam.types';
import ConfettiContainer from '../ConfettiContainer/ConfettiContainer';
import { BtnList } from '../../components/BtnList/BtnList';
import { FAILURE, SUCCESS } from '../../constants/store.constants';
import { Request } from '../../reducer/fetch.types';
import { selectLanguage } from '../../reducer/user.selectors';
import { CORRECT_ANSWER } from '../../constants';
import QuestionChooseModalGameContainer from '../../components/Game/QuestionChooseModalGameContainer/QuestionChooseModalGameContainer';
import GameCongratulations from '../../components/GameCongratulation/GameCongratulation';
import RainContainer from '../RainContainer/RainContainer';
import GameResultInfo from '../../components/Game/GameResultInfo/GameResultInfo';
import { AnswerObj } from '../../reducer/game.types';
import { UPDATE_QUESTION_INDEX } from '../../actions/game.action';
import styles from './GameResultContainer.module.css';

export const GameResultContainer: React.FC<IGameResultContainerProps> = () => {
  const [showQuestionWin, setShowQuestionWin] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const examResult: IExamResult = useSelector(({ exam }: IRootState) => exam.examResult);
  const { history } = useReactRouter();
  const updateResultRequest: Request = useSelector(({ exam }: IRootState) => exam.updateResultRequest);
  const isLogged: boolean = useSelector(({ login }: IRootState) => login.isLogged);
  const currentLanguage = useSelector(selectLanguage);
  const offlineMode: boolean = useSelector(({ auth }: IRootState) => auth.offlineMode);
  const gameStatus = useSelector(({ game }: IRootState) => game.status);
  const livesCount = useSelector(({ game }: IRootState) => game.livesCount);
  const isSuccess = livesCount > 0;

  const userAnswersList: AnswerObj[] = useSelector(({ game }: IRootState) => game.answerList);
  const score: number = useSelector(({ game }: IRootState) => game.score);

  const onShowQuestionWin = useCallback(() => {
    setShowQuestionWin(true);
  }, [setShowQuestionWin]);

  const onHideQuestionWin = useCallback(() => {
    setShowQuestionWin(false);
  }, [setShowQuestionWin]);

  const onStartAgainClick = useCallback(() => {
    history.push(`game-mode`);
  }, [history]);

  useEffect(() => {
    if ((updateResultRequest.status === SUCCESS && !examResult) || updateResultRequest.status === FAILURE) {
      history.push(`/${currentLanguage}/`);
    }
  }, [updateResultRequest, history, examResult, currentLanguage]);

  const handleQuestionChange = useCallback(
    (index: number) => () => {
      dispatch(UPDATE_QUESTION_INDEX(index));
      // dispatch(UPDATE_QUESTION_INDEX(index - 1));
      // history.push(link);
      history.push('game');
    },
    [history, dispatch]
  );

  const correctCountAnswer = useMemo(() => {
    return userAnswersList.reduce((accum: number, current: AnswerObj) => {
      // eslint-disable-next-line no-return-assign,no-param-reassign
      return current.isCorrect && current.isCorrect === CORRECT_ANSWER ? (accum += 1) : accum;
    }, 0);
  }, [userAnswersList]);

  const questionCount = userAnswersList.length;

  useEffect(() => {
    if (gameStatus === '') {
      history.push('game-mode');
    }
  }, [gameStatus, history]);

  return (
    <>
      <ConfettiContainer showConfettiByDefault={isSuccess} />
      <RainContainer showRainByDefault={!isSuccess} />
      <div className={styles.resultPage}>
        <Container>
          <div className={styles.resultPageContent}>
            <GameCongratulations isSuccess={isSuccess} />
            <div className={styles.resultPageContentItem}>
              <GameResultInfo
                correctCountAnswer={correctCountAnswer}
                questionCount={questionCount}
                score={score}
                livesLeft={livesCount}
                currentLanguage={currentLanguage}
              />
              <BtnList className={styles.examButtonList}>
                {isLogged ? (
                  <>
                    <Button variant="secondary" onClick={onStartAgainClick}>
                      {t('gameContainer.startAgain')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button as={Link} to={`/${currentLanguage}/offers`} variant="primary">
                      {t('examResult.btn.deals')}
                    </Button>
                  </>
                )}
                <Button
                  className="btn-outline-secondary"
                  disabled={offlineMode}
                  onClick={onShowQuestionWin}
                  variant="outline-secondary"
                >
                  {t('examResult.btn.mark')}
                </Button>
              </BtnList>
            </div>
          </div>
          <QuestionChooseModalGameContainer
            defaultRoute={`/${currentLanguage}/game`}
            onQuestionChange={handleQuestionChange}
            answerList={userAnswersList}
            show={showQuestionWin}
            onHide={onHideQuestionWin}
          />
        </Container>
      </div>
    </>
  );
};
