// @ts-nocheck
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';
import { IRootState } from '../../reducer';
import { UPDATE_MUSIC_DATA } from '../../actions/sound.action';
import styles from './VolumeSettings.module.css';

const VolumeSettings: React.FC = () => {
  const dispatch = useDispatch();
  const storedVolume = useSelector(({ sound }: IRootState) => sound.volume);

  const setVolume = useCallback(() => {
    const updateVolume = storedVolume ? 0 : 100;
    dispatch(UPDATE_MUSIC_DATA({ volume: updateVolume }));
  }, [dispatch, storedVolume]);

  return (
    <>
      {storedVolume ? (
        <MdVolumeUp onClick={setVolume} className={styles.volumeIcon} variant="success" color="#0080c9" size={20} />
      ) : (
        <MdVolumeOff onClick={setVolume} className={styles.volumeIcon} variant="success" color="#0080c9" size={20} />
      )}
    </>
  );
};

export default React.memo(VolumeSettings);
