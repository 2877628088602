import { put, takeEvery, select, call } from 'redux-saga/effects';
import { GET_THEORY_FAILURE, GET_THEORY_SUCCESS } from '../actions/theory.action';
import { getTheory } from './theory.api';
import { IRootState } from '../reducer';

const getTheoryStore = ({ theory }: IRootState) => theory.theory;
const getLanguage = ({ theory }: IRootState) => theory.language;

function* getTheoryList(action) {
  const theoryState = yield select(getTheoryStore);
  const language = yield select(getLanguage);
  try {
    if (theoryState.pages.length === 0 || language !== action.payload.language) {
      const response = yield call(getTheory, action.payload);
      yield put(GET_THEORY_SUCCESS(response));
    } else {
      yield put(GET_THEORY_SUCCESS({ theory: theoryState, language }));
    }
  } catch (e) {
    yield put(GET_THEORY_FAILURE(e.message));
  }
}

function* theorySaga() {
  yield takeEvery('GET_THEORY_REQUEST', getTheoryList);
}

export default theorySaga;
