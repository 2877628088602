import { put, takeEvery } from 'redux-saga/effects';
import { GET_INVOICE_REQUEST, GET_INVOICE_FAILURE, GET_INVOICE_SUCCESS } from '../actions/invoice.action';
import { getInvoice } from './invoice.api';

function* getInvoiceItem(payload) {
  try {
    const response = yield getInvoice(payload.payload.studentid, payload.payload.invoiceid);
    yield put(GET_INVOICE_SUCCESS(response.payload.invoice));
  } catch (e) {
    yield put(GET_INVOICE_FAILURE(e.message));
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICE_REQUEST, getInvoiceItem);
}

export default invoiceSaga;
