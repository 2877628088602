import React from 'react';
import { IHeaderProps } from './Header.types';

const Header: React.FC<IHeaderProps> = ({ tableHeader }) => {
  return (
    <thead>
      <tr>
        {tableHeader.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={index}>{item}</th>
        ))}
      </tr>
    </thead>
  );
};

export default React.memo(Header);
