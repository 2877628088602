import fetch from './fetch';
import { IRecoveryPasswordRequestPayload, LoginApi, LoginWithTokenApi, RecoveryPasswordApi } from './login.types';

export const logout = () => {
  return fetch('GET', '/logout');
};

export const login = (email: string, password: string): LoginApi => {
  return fetch('POST', '/login', { email, password });
};

export const loginWithToken = (registrationToken: string): LoginWithTokenApi => {
  return fetch('POST', '/user/registration-token', { registrationToken });
};

export const recoveryPasswordByEmail = (payload: IRecoveryPasswordRequestPayload): RecoveryPasswordApi => {
  return fetch('POST', '/password-recovery', payload);
};
