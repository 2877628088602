import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import SuccessSignUpPopup from '../../components/SuccessSignUpPopup/SuccessSignUpPopup';
import { SUCCESS } from '../../constants/store.constants';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';
import { ISignUpFinishContainerQueryParams } from './SignUpFinishContainer.types';
import { selectLanguage } from '../../reducer/user.selectors';

const SignUpFinishContainer: React.FC = () => {
  const currentLanguage = useSelector(selectLanguage);
  const { history, location } = useReactRouter();
  const { password }: LoginState = useSelector(selectLogin);
  const [successPopupShow, setSuccessPopupShow] = useState<boolean>(false);
  const { signUpStatus }: ISignUpFinishContainerQueryParams = queryString.parse(location.search);

  useEffect(() => {
    if (signUpStatus === SUCCESS) {
      setSuccessPopupShow(true);
      history.replace(`/${currentLanguage}/mycockpit`);
    }
  }, [signUpStatus, history, currentLanguage]);

  const handlerOnSuccessHide = useCallback(() => setSuccessPopupShow(false), [setSuccessPopupShow]);

  return (
    <>
      <SuccessSignUpPopup show={successPopupShow} onHide={handlerOnSuccessHide} password={password} />
    </>
  );
};

export default SignUpFinishContainer;
