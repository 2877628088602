import { handleActions } from 'redux-actions';
import Cookies from 'js-cookie';
import { IThemeState, ThemeColors } from './theme.types';
import { SET_THEME_COLOR } from '../actions/theme.action';
import { PayloadAction } from '../types';
import { THEME_COLOR } from '../constants/storageKeys';

const defaultState: IThemeState = {
  colorTheme: (Cookies.get(THEME_COLOR) as ThemeColors) || ThemeColors.light
};

const reduces = handleActions(
  {
    [SET_THEME_COLOR]: (state, action: PayloadAction<ThemeColors>) => ({
      ...state,
      colorTheme: action.payload
    })
  },
  defaultState
);

export default reduces;
