import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../reducer/user.selectors';
import PromoContentTCS from '../components/PromoContentTCS/PromoContentTCS';

const PromoContentContainerTCS: React.FC = () => {
  const currentLanguage = useSelector(selectLanguage);

  return (
    <PromoContentTCS
      onLoginClick={() => {}}
      onLogoutClick={() => {}}
      isLogged={false}
      currentLanguage={currentLanguage}
    />
  );
};

export default PromoContentContainerTCS;
