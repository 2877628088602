import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
// import { IoMdClose, IoMdCheckmark } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ISingleCardProps } from './SingleCard.types';
import { getAnswerType, getQuestionById } from '../../../utils/utils';
import { getExamImageSrc } from '../../../utils/prefetchMediaSources';
import { selectLanguage } from '../../../reducer/user.selectors';
import { UPDATE_QUESTION_INDEX } from '../../../actions/game.action';
import { CORRECT_ANSWER } from '../../../constants';
import { ReactComponent as ArrowIconLeft } from '../../../img/game/arrow-left.svg';
import { AnswerObj } from '../../../reducer/game.types';
import checkIcon from '../../../img/game/pixel-check-icon.png';
import styles from './SingleCard.module.css';

const SingleCard: React.FC<ISingleCardProps> = ({
  id,
  questionOrderTextId,
  oAgreeClick,
  onDeclineClick,
  isResultCard,
  disabledPrevBtn,
  disabledNextBtn,
  setQuestionIndex,
  currentQuestionIndex,
  currentAnswer
}: ISingleCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);

  const getQuestionData = useMemo(() => {
    // TODO english problem, 'don't have tranlsation'
    const question = getQuestionById(id);
    const covertLanguage = question!.langs![language] ? language : 'de';
    const questionText = question!.langs[covertLanguage].q;

    const imageSrc = getExamImageSrc(question!.img);
    const answerText = question!.langs[covertLanguage].a.find(item => item.id === questionOrderTextId).text;

    return { questionText, answerText, imageSrc };
  }, [id, questionOrderTextId, language]);

  const onPevClick = useCallback(() => {
    if (!disabledPrevBtn) {
      setQuestionIndex(currentQuestionIndex - 1);
      dispatch(UPDATE_QUESTION_INDEX(currentQuestionIndex - 1));
    }
  }, [currentQuestionIndex, setQuestionIndex, disabledPrevBtn, dispatch]);

  const onNextClick = useCallback(() => {
    if (!disabledNextBtn) {
      setQuestionIndex(currentQuestionIndex + 1);
      dispatch(UPDATE_QUESTION_INDEX(currentQuestionIndex + 1));
    }
  }, [currentQuestionIndex, setQuestionIndex, disabledNextBtn, dispatch]);

  const renderPrevButton = useMemo(() => {
    if (isResultCard) {
      return (
        <Button className={styles.btnPrev} variant="secondary" disabled={disabledPrevBtn} onClick={onPevClick}>
          <ArrowIconLeft />
        </Button>
      );
    }
    return null;
  }, [isResultCard, disabledPrevBtn, onPevClick]);

  const renderNextButton = useMemo(() => {
    if (isResultCard) {
      return (
        <Button className={styles.btnNext} variant="secondary" disabled={disabledNextBtn} onClick={onNextClick}>
          <ArrowIconLeft />
        </Button>
      );
    }
    return null;
  }, [isResultCard, disabledNextBtn, onNextClick]);

  const { questionText, answerText, imageSrc } = getQuestionData;

  const wrongButtonClasses = useMemo(
    () => classNames(styles.wrongButton, { [styles.isButtonDisabled]: isResultCard }),
    [isResultCard]
  );
  const correctButtonClasses = useMemo(
    () => classNames(styles.correctButton, { [styles.isButtonDisabled]: isResultCard }),
    [isResultCard]
  );

  const borderColor = useMemo(() => {
    if (isResultCard) {
      return getAnswerType(currentAnswer?.isCorrect, 'success', 'danger', '', 'warning');
    }
    return 'light';
  }, [currentAnswer, isResultCard]);

  const answerStatusText = useMemo(() => {
    if (isResultCard) {
      return getAnswerType(
        currentAnswer?.isCorrect,
        t('gamePage.correct'),
        t('gamePage.wrong'),
        '',
        t('gamePage.notAnswer')
      );
    }
    return '';
  }, [currentAnswer, isResultCard, t]);

  const getCorrectAnswerFunc = useCallback((selectedItemCheck: AnswerObj) => {
    return selectedItemCheck.isCorrect === CORRECT_ANSWER ? selectedItemCheck.answer : !selectedItemCheck.answer;
  }, []);

  const renderTopAnswerStatus = useMemo(() => {
    if (isResultCard && answerStatusText) {
      const textClasses = classNames(
        styles.answerText,
        getAnswerType(
          currentAnswer?.isCorrect,
          styles.answerTextCorrect,
          styles.answerTextWrong,
          '',
          styles.answerTextNotAnswer
        )
      );
      return (
        <div className={styles.answerTextContainer}>
          <p className={textClasses}>{answerStatusText}</p>
        </div>
      );
    }
    return null;
  }, [isResultCard, answerStatusText, currentAnswer]);

  const wrongAnswerCircleStyle = useMemo(() => {
    if (isResultCard && currentAnswer?.isCorrect) {
      const getCorrectAnswer = getCorrectAnswerFunc(currentAnswer);
      return !getCorrectAnswer ? styles.correctCircle : '';
    }

    return '';
  }, [currentAnswer, isResultCard, getCorrectAnswerFunc]);

  const correctAnswerCircleStyle = useMemo(() => {
    if (isResultCard && currentAnswer?.isCorrect) {
      const getCorrectAnswer = getCorrectAnswerFunc(currentAnswer);
      return getCorrectAnswer ? styles.correctCircle : '';
    }

    return '';
  }, [currentAnswer, isResultCard, getCorrectAnswerFunc]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (isResultCard) {
        if (e.keyCode === 37) {
          // left
          onPevClick();
        }
        if (e.keyCode === 39) {
          // right
          onNextClick();
        }
      }
    },
    [onPevClick, onNextClick, isResultCard]
  );

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [onPevClick, onNextClick, escapeListener]);

  return (
    <div className={styles.singleCardWrapper}>
      <Card border={borderColor}>
        {renderTopAnswerStatus}
        <Card.Img draggable="false" variant="top" src={imageSrc} />
        <Card.Body>
          <Card.Text className={styles.titleText}>{questionText}</Card.Text>

          <Card.Text>{answerText}</Card.Text>

          <div className={styles.buttonsWrapper}>
            {renderPrevButton}
            <div className={wrongAnswerCircleStyle}>
              <button disabled={isResultCard} type="button" onClick={onDeclineClick} className={wrongButtonClasses}>
                x
              </button>
            </div>
            <div className={correctAnswerCircleStyle}>
              <button disabled={isResultCard} type="button" onClick={oAgreeClick} className={correctButtonClasses}>
                <img src={checkIcon} alt="checkIcon" />
              </button>
            </div>
            {renderNextButton}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(SingleCard);
