import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContentTitle } from '../../ui-components/PageContentTitle/PageContentTitle';
import Teacher3dCarousel from '../../components/Teacher3dCarousel/Teacher3dCarousel';

const VIDEO_LIST = [
  { src: '/content/3dteacher/2_1.mp4', text: 'presenceWithSignaling.text_2_1' },
  { src: '/content/3dteacher/2_2.mp4', text: 'presenceWithSignaling.text_2_2' },
  { src: '/content/3dteacher/2_3.mp4', text: 'presenceWithSignaling.text_2_3' },
  { src: '/content/3dteacher/2_4.mp4', text: 'presenceWithSignaling.text_2_4' },
  { src: '/content/3dteacher/2_5.mp4', text: 'presenceWithSignaling.text_2_5' },
  { src: '/content/3dteacher/2_6.mp4', text: 'presenceWithSignaling.text_2_6' },
  { src: '/content/3dteacher/2_7.mp4', text: 'presenceWithSignaling.text_2_7' }
];

const PresenceWithSignaling: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContentTitle>{t('presenceWithSignaling.title')}</PageContentTitle>
      <Teacher3dCarousel srcArray={VIDEO_LIST} />
    </>
  );
};

export default PresenceWithSignaling;
