import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import AnimatedNumber from 'animated-number-react';
import GaugeChart from 'react-gauge-chart';
import { FaExclamation } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import { Badge } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CardWrapper from '../../../../ui-components/CardWrapper/CardWrapper';
import ProgressPieChart from '../../components/ProgressPieChart/ProgressPieChart';
import { CLEAR_ANSWER_BY_TOPIC_REQUEST } from '../../../../actions/answer.action';
import QuestionChooseModalContainer from '../../../QuestionChooseModalContainer/QuestionChooseModalContainer';
import { GET_QUESTION_REQUEST } from '../../../../actions/question.action';
import { IYourProgressProps } from './YourProgressSection.types';
import styles from './YourProgressSection.module.css';

const CardWrapperTitle = ({ title }: { title: string }) => (
  <div className={styles.cardTitleContainer}>
    <h6>{title}</h6>
  </div>
);

const YourProgressSection: React.FC<IYourProgressProps> = ({
  allProgressPercent,
  incorrectCount,
  correctAnswerCount,
  markedCount,
  currentCategory,
  currentLanguage
}: IYourProgressProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = useReactRouter();

  const [showWin, setShowWin] = useState(false);

  const changeModalView = useCallback(() => {
    if (!showWin) {
      dispatch(GET_QUESTION_REQUEST({ topic: 'all' }));
    }
    setShowWin(!showWin);
  }, [showWin, setShowWin, dispatch]);

  const formatValue = useCallback(value => value.toFixed(0), []);

  const handleAnswerClear = useCallback(() => {
    dispatch(CLEAR_ANSWER_BY_TOPIC_REQUEST({ topic: 'all' }));
  }, [dispatch]);

  const onMarkedClick = useCallback(() => {
    history.push(`/${currentLanguage}/questions/${currentCategory}/marked`);
  }, [currentCategory, currentLanguage, history]);

  const onIncorrectClick = useCallback(() => {
    history.push(`/${currentLanguage}/questions/${currentCategory}/incorrect`);
  }, [currentCategory, currentLanguage, history]);

  const onOverallStatisticClick = useCallback(() => {
    history.push(`/${currentLanguage}/questions/${currentCategory}/all`);
  }, [currentCategory, currentLanguage, history]);

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  const starIcon = classNames(styles.cardMarkedIcon, {
    'icon-star-line': !markedCount,
    'icon-star-fill': !!markedCount
  });

  const incorrectIconColor = incorrectCount ? '#e03d24' : '#e2eaf6';
  return (
    <section className={styles.container}>
      <QuestionChooseModalContainer
        onQuestionChange={handleQuestionChange}
        defaultRoute={`/${currentLanguage}/questions/${currentCategory}/all`}
        show={showWin}
        removeTopicIdByDefault="all"
        onHide={changeModalView}
      />
      <CardWrapper onClick={onOverallStatisticClick} className={styles.cardContainer}>
        <>
          <CardWrapperTitle title={t('questionsByTopicContainer.overallProgress')} />
          <GaugeChart
            id="gauge-chart"
            textColor="#333"
            nrOfLevels={3}
            arcsLength={[0.33, 0.33, 0.33]}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            percent={allProgressPercent}
            arcPadding={0.02}
            text
          />
        </>
      </CardWrapper>

      <CardWrapper onClick={changeModalView} className={styles.cardContainer}>
        <>
          <CardWrapperTitle title={t('questionsByTopicContainer.correctAnswerStatistic')} />
          <ProgressPieChart correctAnswerCount={correctAnswerCount} incorrectAnswerCount={incorrectCount} />
        </>
      </CardWrapper>
      <CardWrapper onClick={onMarkedClick} className={styles.cardContainer}>
        <>
          {!!markedCount && (
            <Badge pill variant="warning" className={`${styles.badge} ${styles.badgeWarning}`}>
              <AnimatedNumber value={markedCount} formatValue={formatValue} delay={1000} duration={1500} />
            </Badge>
          )}
          <CardWrapperTitle title={t('questionsByTopicContainer.marked')} />
          <i className={starIcon} />
        </>
      </CardWrapper>
      <CardWrapper onClick={onIncorrectClick} className={styles.cardContainer}>
        <>
          {!!incorrectCount && (
            <Badge pill variant="danger" className={`${styles.badge} ${styles.badgeError}`}>
              <AnimatedNumber value={incorrectCount} formatValue={formatValue} delay={1000} duration={1500} />
            </Badge>
          )}
          <CardWrapperTitle title={t('questionsByTopicContainer.incorrect')} />
          <FaExclamation className={styles.cardCenterIcon} color={incorrectIconColor} size={85} />
        </>
      </CardWrapper>
      <CardWrapper onClick={handleAnswerClear} className={styles.cardContainer}>
        <>
          <CardWrapperTitle title={t('questionsByTopicContainer.reset')} />
          <GrPowerReset className={styles.resetIcon} size={90} />
        </>
      </CardWrapper>
    </section>
  );
};

export default React.memo(YourProgressSection);
