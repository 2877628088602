import React, { useCallback, useMemo } from 'react';
import { Col, Row, Container, Navbar, Nav, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { LinkWrapper as Link } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath, checkIsSEOPathWithoutLangPrefix } from '../../utils/utils';
import { lngList } from '../../locales/locale';
import Translate from '../Translate/Translate';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import { IHeaderProps } from './Header.types';
import usePageStates from '../../hooks/usePageStates';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import { IRootState } from '../../reducer';
import { GAME_PLAY_STATUS } from '../../constants';
import styles from './Header.module.css';
import { LOGIN_MODAL_OPEN } from '../../actions/login.action';

const logo = imageAbsolutePath('logo.svg');
const logoBlackTheme = imageAbsolutePath('logo-black-them.svg');

const headerVariants = {
  show: { opacity: 1, y: 0, height: 'auto' },
  hide: {
    y: -100,
    opacity: 0,
    height: 0,
    transitionEnd: {
      display: 'none'
    }
  }
};

const Header: React.FC<IHeaderProps> = ({ isLogged, routeProps }: IHeaderProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isDemo } = usePageStates();

  const isSeoPath = useMemo(() => checkIsSEOPathWithoutLangPrefix(routeProps.location.pathname), [
    routeProps.location.pathname
  ]);
  const isGameStatusPlay = useSelector(({ game }: IRootState) => game.status) === GAME_PLAY_STATUS ? 'hide' : 'show';

  const { isDarkTheme } = useGetSelectedColorTheme();
  const logoSrc = useMemo(() => (isDarkTheme ? logoBlackTheme : logo), [isDarkTheme]);

  const handleLoginClick = useCallback(() => {
    dispatch(LOGIN_MODAL_OPEN());
  }, [dispatch]);

  const renderLanguages = useMemo(() => {
    const lng = 'de';
    if (isSeoPath) {
      return (
        <Translate key={lng} lng={lngList[lng].shortName} routeProps={routeProps}>
          {lngList[lng].fullName}
        </Translate>
      );
    }
    return (
      <>
        {Object.keys(lngList).map((lngItem: string) => {
          return (
            <Translate key={lngItem} lng={lngList[lngItem].shortName} routeProps={routeProps}>
              {lngList[lngItem].fullName}
            </Translate>
          );
        })}
      </>
    );
  }, [isSeoPath, routeProps]);

  const navbarClass = useMemo(() => (isDarkTheme ? 'dark' : 'light'), [isDarkTheme]);

  return (
    <motion.div
      animate={isGameStatusPlay}
      transition={{ duration: 1 }}
      variants={headerVariants}
      className={styles.header}
    >
      <div className={styles.headerTop}>
        <Container>
          <Row>
            <Col>
              <div className={styles.headerTopContent}>
                <ul className={styles.headerTopLinkList}>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/theorie24.ch/"
                    >
                      <i className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/theorie24.ch/"
                    >
                      <i className="icon-instagram" />
                    </a>
                  </li>
                </ul>
                <div className={styles.langBtnGroup}>{renderLanguages}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar className={navbarClass} expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand>
            <Nav>
              <Nav.Link as={Link} to={isLogged ? '/mycockpit' : '/'} href={isLogged ? '/mycockpit' : '#mycockpit'}>
                <img src={logoSrc} width={100} alt="logo" />
              </Nav.Link>
            </Nav>
          </Navbar.Brand>
          <div className={styles.mobileButtons}>
            {!isLogged && isDemo && (
              <Nav.Link as={Link} to="/mycockpitdemo" href="#mycockpitdemo" className={styles.demoVersionMobile}>
                <span className={styles.demoText}>{t('demoFlag.demoVersion')}</span>
              </Nav.Link>
            )}
            {!isLogged && (
              <Button className={styles.loginBtnMobile} variant="secondary" onClick={handleLoginClick}>
                {t('app.btn.login')}
              </Button>
            )}
            <Nav className={styles.accountBtn} hidden={!isLogged}>
              <Nav.Link as={Link} to="/mycockpit" href="#mycockpit">
                <i className="icon-profile" />
              </Nav.Link>
            </Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>
          <>
            <Navbar.Collapse id="responsive-navbar-nav">
              <HeaderMenuContainer />
            </Navbar.Collapse>
          </>
        </Container>
      </Navbar>
    </motion.div>
  );
};

export default Header;
