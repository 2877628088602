import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavMenu from '../../components/Teacher3dNavMenu/Teacher3dNavMenu';
import Teacher3dDisplay from '../../components/Teacher3dDisplay/Teacher3dDisplay';
import Teacher3dRoutes from './Teacher3d.routes';
import { ITeacher3dPageProps } from './Teacher3d.types';
import styles from './Teacher3dPage.module.css';

const Teacher3dPage: React.FC<ITeacher3dPageProps> = ({ match, currentLanguage }: ITeacher3dPageProps) => {
  return (
    <div className={styles.teacherPage}>
      <Teacher3dDisplay />
      <div className={styles.pageBottomContent}>
        <Container>
          <Row>
            <Col lg={4}>
              <NavMenu currentLanguage={currentLanguage} />
            </Col>
            <Col lg={{ span: 7, offset: 1 }}>
              <div className={styles.navListContent}>
                <Teacher3dRoutes match={match} currentLanguage={currentLanguage} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Teacher3dPage;
