import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import LocalesImage from '../../components/LocalesImage/LocalesImage';
import { IAboutPageProps } from './AboutPage.types';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './AboutPage.module.css';
import TitleH3 from '../../ui-components/TitleH3/TitleH3';

const allDeviceDe: string = imageAbsolutePath('offers/offers_all_devices_de.png');
const allDeviceFr: string = imageAbsolutePath('offers/offers_all_devices_fr.png');
const allDeviceEn: string = imageAbsolutePath('offers/offers_all_devices_en.png');
const allDeviceIt: string = imageAbsolutePath('offers/offers_all_devices_it.png');

const gameDe: string = imageAbsolutePath('/offers/game_mode_all_devices_de.png');
const gameFr: string = imageAbsolutePath('/offers/game_mode_all_devices_fr.png');
const gameEn: string = imageAbsolutePath('/offers/game_mode_all_devices_en.png');
const gameIt: string = imageAbsolutePath('/offers/game_mode_all_devices_it.png');

const OFFERS_ALL_DEVICE_IMAGE_LIST = {
  de: { img: allDeviceDe },
  fr: { img: allDeviceFr },
  it: { img: allDeviceIt },
  en: { img: allDeviceEn }
};

const GAME_MODE_ALL_DEVICE_IMAGE_LIST = {
  de: { img: gameDe },
  fr: { img: gameFr },
  it: { img: gameIt },
  en: { img: gameEn }
};

const AboutPage: React.FC<IAboutPageProps> = ({ currentLanguage }: IAboutPageProps) => {
  const { t } = useTranslation();
  const showVideos = currentLanguage === 'de' || currentLanguage === 'fr';

  return (
    <div className={styles.aboutPage}>
      <Container className={styles.topContainer}>
        <Row>
          <Col>
            <PageTitle>{t('aboutPage.title')}</PageTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className={styles.aboutPageText}>{t('aboutPage.underTitleText1')}</p>
            <p className={styles.aboutPageText}>{t('aboutPage.underTitleText2')}</p>
          </Col>
        </Row>

        <Row>
          <Col className={styles.aboutBtnWrapper}>
            <Button className={styles.aboutBtn} variant="primary" as={Link} to={`/${currentLanguage}/offers`}>
              {t('aboutPage.offersBtn')}
            </Button>
          </Col>
        </Row>
      </Container>
      <div className={styles.listContainer}>
        <Container>
          <Row>
            <Col>
              <TitleH3 className={styles.aboutPageSubtitle} text={t('aboutPage.subtitle1')} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle1')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText1')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle2')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText2')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle3')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText3')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle4')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText4')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle5')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText5')}</p>
            </Col>
          </Row>
          <Row>
            <Col className={styles.aboutBtnWrapper}>
              <Button className={styles.aboutBtn} variant="primary" as={Link} to={`/${currentLanguage}/offers`}>
                {t('aboutPage.offersBtn')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.imageContainer}>
        <Container>
          <Row>
            <Col className={styles.imageWrapper}>
              <LocalesImage alt={t('aboutPage.imageAlt')} localesPath={OFFERS_ALL_DEVICE_IMAGE_LIST} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.listContainer}>
        <Container>
          <Row>
            <Col>
              <TitleH3 className={styles.aboutPageSubtitle} text={t('aboutPage.subtitle2')} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle6')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText6')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle7')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText7')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle8')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText8')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle9')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText9')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle10')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText10')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle11')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText11')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle12')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText12')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle13')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText13')}</p>
            </Col>
            <Col lg={12}>
              <h6 className={styles.listTitle}>{t('aboutPage.listSubtitle14')}</h6>
              <p className={styles.listText}>{t('aboutPage.listText14')}</p>
            </Col>
          </Row>
          <Row>
            <Col className={styles.aboutBtnWrapper}>
              <Button className={styles.aboutBtn} variant="primary" as={Link} to={`/${currentLanguage}/offers`}>
                {t('aboutPage.offersBtn')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.gameContainer}>
        <Container>
          <Row>
            <Col className={styles.imageWrapper}>
              <LocalesImage alt={t('aboutPage.gameModeAlt')} localesPath={GAME_MODE_ALL_DEVICE_IMAGE_LIST} />
            </Col>
          </Row>
          <Row>
            <Col>
              <TitleH3 className={styles.aboutPageGameSubtitle} text={t('aboutPage.subtitle3')} />
              <h6 className={styles.whiteTitle}>{t('aboutPage.listSubtitle15')}</h6>
              <p className={styles.gameText}>{t('aboutPage.listText15')}</p>
            </Col>
          </Row>
          <Row>
            <Col className={styles.aboutBtnWrapper}>
              <Button className={styles.aboutBtn} variant="primary" as={Link} to={`/${currentLanguage}/offers`}>
                {t('aboutPage.offersBtn')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.videoContainer}>
        {showVideos && (
          <Row>
            <Col md={12}>
              <h6 className={`${styles.listTitle} ${styles.videoTitle}`}>{t('aboutPage.theorie24Explanation')}</h6>
              <div className={styles.videoWrapper}>
                <iframe
                  src={t('aboutPage.theorie24ExplanationLink')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title={t('aboutPage.theorie24Explanation')}
                />
              </div>
            </Col>
            <Col md={12}>
              <h6 className={`${styles.listTitle} ${styles.videoTitle}`}>{t('aboutPage.howToGetAccess')}</h6>
              <div className={styles.videoWrapper}>
                <iframe
                  src={t('aboutPage.howToGetAccessLink')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title={t('aboutPage.howToGetAccess')}
                />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default AboutPage;
