import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router';
import Cookies from 'js-cookie';
import { LOGIN_WITH_TOKEN_REQUEST } from '../actions/login.action';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import { FAILURE, SUCCESS, UNCALLED } from '../constants/store.constants';
import { IPaymentRedirectQueryParams } from '../pages/PaymentRedirectPage/PaymentRedirectPage.types';
import { SELECTED_OFFER_KEY, REGISTRATION_FORM_KEY } from '../constants/storageKeys';
import { isSSR } from '../utils/utils';

const LoginWithTokenContainer: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { tokenRequest, isLogged }: LoginState = useSelector(selectLogin);
  const { registrationToken }: IPaymentRedirectQueryParams = queryString.parse(location.search);

  useEffect(() => {
    if (!isLogged && registrationToken && tokenRequest.status === UNCALLED) {
      dispatch(LOGIN_WITH_TOKEN_REQUEST({ registrationToken }));
      if (!isSSR()) {
        localStorage.removeItem(SELECTED_OFFER_KEY);
      }
      Cookies.remove(REGISTRATION_FORM_KEY);
    }
  }, [dispatch, tokenRequest, isLogged, registrationToken]);

  if (!registrationToken) {
    return null;
  }

  if (isLogged) {
    return <Redirect to={`/?signUpStatus=${SUCCESS}`} />;
  }

  if (tokenRequest.status === FAILURE) {
    return <Redirect to={`/?signUpStatus=${FAILURE}`} />;
  }

  return null;
};

export default LoginWithTokenContainer;
