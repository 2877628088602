import * as Yup from 'yup';

export const SchoolCoordinatesSuccessPayloadSchema = Yup.object().shape({
  schools: Yup.array(
    Yup.object().shape({
      teacherId: Yup.number().required(),
      teacherLatitude: Yup.string().required(),
      teacherLongitude: Yup.string().required()
    })
  )
});
